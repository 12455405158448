/*Routes*/
export const ROUTE_ROOT = '/'
export const ROUTE_DISCOUNT_CODE_FORM = '/discountcode/:discountId'
export const ROUTE_DISCOUNT_CODE = '/discountcode'
export const ROUTE_USER_MANAGEMENT = '/user'
export const ROUTE_LESSON_PAGE = '/course/:classId/lesson/:lessonId'
export const ROUTE_LESSONS = '/course/:classId'
export const ROUTE_COURSES = '/course'
export const ROUTE_TUTOR_PAYMENTS = '/tutor-payments'
export const ROUTE_ORDERS = '/orders'
export const ROUTE_FEEDBACK = '/feedback'
export const ROUTE_REPORT = '/report'
export const ROUTE_LESSON_MANAGEMENT = '/lessons'
export const ROUTE_LESSON_DETAIL_ZOOM = '/lessons/:lessonId/detail/zoom'
export const ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM = '/lessons/:lessonId/detail/tenopy-classroom'
export const ROUTE_TLS = '/tls'
export const ROUTE_NUMBER_DASHBOARD = '/dashboard'
export const ROUTE_TICKETS_STAT = '/tickets/stat'
export const ROUTE_TICKETS_TRIAL = '/tickets/trial'
export const ROUTE_TICKETS_RENEWAL = '/tickets/renewal'
export const ROUTE_OPS_DASHBOARD = '/ops'
export const ROUTE_PROFILE = '/profile'
export const ROUTE_QUESTION_BANK = '/question-bank'
