import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const initialState = {
  loadingList: false,
  list: { data: [], count: 0, filter: null },
  creating: false,
  createResp: null,
  tagging: false,
  tagResp: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_QUESTION_LIST:
      return {
        ...state,
        loadingList: true
      }

    case Type.GET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        list: {
          data: action.payload.data,
          count: action.payload.count,
          filter: action.filter
        }
      }

    case Type.GET_QUESTION_LIST_FAILED:
      return {
        ...state,
        loadingList: false
      }

    case Type.CREATE_QUESTION:
      return {
        ...state,
        creating: true,
        createResp: null
      }

    case Type.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        creating: false,
        createResp: {
          status: 'success'
        }
      }

    case Type.CREATE_QUESTION_FAILED:
      return {
        ...state,
        creating: false,
        createResp: {
          status: 'failed',
          msg: action.payload
        }
      }

    case Type.TAG_QUESTION:
      return {
        ...state,
        tagging: true
      }

    case Type.TAG_QUESTION_SUCCESS: {
      let updatedListData = state.list
      const existingIndex = _.findIndex(state.list.data, o => {
        return o._id === action.payload.data._id
      })
      if (existingIndex > -1) {
        updatedListData.data[existingIndex] = action.payload.data
      }

      return {
        ...state,
        tagging: false,
        list: updatedListData
      }
    }

    case Type.TAG_QUESTION_FAILED:
      return {
        ...state,
        tagging: false
      }

    default:
      return state
  }
}
