import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { promiseMiddleware, localStorageMiddleware } from 'middleware'
import createRootReducer from 'reducer'

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

// Build the middleware for intercepting and dispatching navigation actions

const configureStore = initialState =>
  createStore(
    createRootReducer(history), // root reducer with router state,
    initialState,
    process.env.NODE_ENV === 'production'
      ? compose(applyMiddleware(routerMiddleware(history), promiseMiddleware, localStorageMiddleware))
      : composeWithDevTools(
          applyMiddleware(routerMiddleware(history), promiseMiddleware, localStorageMiddleware, createLogger())
        )
  )

export const store = configureStore()
