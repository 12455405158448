import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from 'reducers/auth'
import common from 'reducers/common'
import question from 'reducers/question'
import orders from 'reducers/orders'
import discountCode from 'reducers/discountCode'
import user from 'reducers/user'
import classroom from 'reducers/classroom'
import lessons from 'reducers/lessons'
import announcements from 'reducers/announcements'
import feedback from 'reducers/feedback'
import event from 'reducers/event'
import homework from 'reducers/homework'
import submission from 'reducers/submission'
import report from 'reducers/report'
import ticket from 'reducers/ticket'

export default history => {
  return combineReducers({
    router: connectRouter(history),
    auth,
    common,
    question,
    orders,
    discountCode,
    user,
    classroom,
    lessons,
    announcements,
    feedback,
    event,
    homework,
    submission,
    report,
    ticket
  })
}
