import React from 'react'
import { Button, Dialog, DialogContent, DialogActions, Paper, Popover, TextField } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { EditorState, Modifier } from 'draft-js'
import Latex from './latex-renderer'

class MathInput extends React.Component {
  state = {
    isMathDialogOpen: false,
    isPopoverOpen: false,
    popoverAnchor: null,
    popoverType: '',
    numerator: '',
    denominator: '',
    innerContent: '',
    mathEquation: '',
    symbol: '',
    cursorPosition: 0
  }

  constructor() {
    super()
    this.textFieldRef = React.createRef()
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggleMathDialog = () => {
    if (!this.state.isMathDialogOpen) {
      this.setState({ mathEquation: '' })
    }
    this.setState({ isMathDialogOpen: !this.state.isMathDialogOpen })
  }

  openPopover = type => event => {
    this.setState({
      isPopoverOpen: true,
      popoverAnchor: event.currentTarget,
      popoverType: type
    })
  }

  closePopover = () => {
    this.setState({
      isPopoverOpen: false,
      popoverAnchor: null
    })
  }

  insertSymbol = (event, mathSymbol) => {
    const cursorPosition = this.textFieldRef.current.selectionStart
    const { mathEquation } = this.state
    const updatedMathEquation =
      mathEquation.substring(0, cursorPosition) + mathSymbol + mathEquation.substring(cursorPosition)
    this.setState({ mathEquation: updatedMathEquation })
  }

  addContentWithMathSymbol = () => {
    const { popoverType, innerContent, n, numerator, denominator, mathEquation } = this.state
    const cursorPosition = this.textFieldRef.current.selectionStart
    let mathContent = ''
    if (popoverType === 'frac') {
      mathContent = `\\frac{${numerator}}{${denominator}}`
    } else if (popoverType === 'sqroot') {
      mathContent = `\\sqrt{${innerContent}}`
    } else if (popoverType === 'rootOfN') {
      mathContent = `\\sqrt[${n}]{${innerContent}}`
    } else if (popoverType === 'superscript') {
      mathContent = `^{${innerContent}}`
    }

    if (mathContent) {
      const updatedMathEquation =
        mathEquation.substring(0, cursorPosition) + mathContent + mathEquation.substring(cursorPosition)
      this.setState({ mathEquation: updatedMathEquation })
    }
    this.closePopover()
    this.setState({ numerator: '', denominator: '', innerContent: '' })
  }

  addMathEquation = async () => {
    const { mathEquation } = this.state
    const { editorState, onChange } = this.props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `\\(${mathEquation}\\)`,
      editorState.getCurrentInlineStyle()
    )
    await onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    this.toggleMathDialog()
  }

  render() {
    const {
      isMathDialogOpen,
      isPopoverOpen,
      popoverAnchor,
      popoverType,
      numerator,
      denominator,
      n,
      innerContent,
      mathEquation,
      symbol
    } = this.state
    return (
      <>
        <div className="d-flex flex-row ml-1">
          <div className="clickable" onClick={this.toggleMathDialog}>
            Math
          </div>
        </div>

        <Dialog open={isMathDialogOpen} maxWidth="lg" fullWidth>
          <DialogContent>
            <div>
              <Paper elevation={2} className="manage-margin p-2">
                <ToggleButtonGroup value={symbol} exclusive onChange={this.insertSymbol} className="mb-0">
                  <ToggleButton value="+" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/plus.png"
                      alt="plus"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="-" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/minus.png"
                      alt="minus"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\times" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/multiply.png"
                      alt="multiply"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\div" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/divide.png"
                      alt="divide"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="=" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/equal.png"
                      alt="equal"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\neq" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/not-equal.png"
                      alt="not equal"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="<" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/less-than.png"
                      alt="less than"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\leq" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/less-than-equal-to.png"
                      alt="less than equal to"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value=">" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/greater-than.png"
                      alt="greater than"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\geq" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/greater-than-equal-to.png"
                      alt="greater than equal to"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup value={symbol} className="mb-0">
                  <ToggleButton className="p-2" onClick={this.openPopover('frac')} value="frac">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/fraction.png"
                      alt="fraction"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton className="p-2" onClick={this.openPopover('sqroot')} value="sqroot">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/square-root.png"
                      alt="square root"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton className="p-2" onClick={this.openPopover('rootOfN')} value="rootOfN">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/root-of-n.png"
                      alt="root of n"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton className="p-2" onClick={this.openPopover('superscript')} value="superscript">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/superscript.png"
                      alt="superscript"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup value={symbol} exclusive onChange={this.insertSymbol} className="mb-0">
                  <ToggleButton value="%" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/percent.png"
                      alt="percent"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\pi" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/pi.png"
                      alt="pi"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\alpha" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/alpha.png"
                      alt="alpha"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\beta" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/beta.png"
                      alt="beta"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\delta" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/delta.png"
                      alt="delta"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\gamma" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/gamma.png"
                      alt="gamma"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\lambda" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/lambda.png"
                      alt="lambda"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\sigma" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/sigma.png"
                      alt="sigma"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                  <ToggleButton value="\theta" className="p-2">
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/icon/math/theta.png"
                      alt="theta"
                      className="toolbar-icon"
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Paper>

              <Popover
                open={isPopoverOpen}
                anchorEl={popoverAnchor}
                onClose={this.closePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                <div className="p-3">
                  {popoverType === 'frac' ? (
                    <div className="d-flex flex-row mb-2">
                      <TextField
                        variant="outlined"
                        label="numerator"
                        name="numerator"
                        value={numerator}
                        onChange={this.handleChange}
                      />
                      <span className="mx-2" style={{ fontSize: '2rem' }}>
                        /
                      </span>
                      <TextField
                        variant="outlined"
                        label="denominator"
                        name="denominator"
                        value={denominator}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : (
                    <div className="d-flex flex-row mb-2">
                      {popoverType === 'rootOfN' && (
                        <TextField
                          variant="outlined"
                          label="nth"
                          name="n"
                          value={n}
                          onChange={this.handleChange}
                          className="mr-2"
                        />
                      )}
                      <TextField
                        variant="outlined"
                        label="Content"
                        name="innerContent"
                        value={innerContent}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                  <Button variant="outlined" color="primary" onClick={this.addContentWithMathSymbol}>
                    Add
                  </Button>
                </div>
              </Popover>

              <TextField
                variant="outlined"
                fullWidth
                className="mt-3"
                name="mathEquation"
                inputRef={this.textFieldRef}
                onChange={this.handleChange}
                value={mathEquation}
              />

              <div
                className="my-3"
                style={{
                  backgroundColor: '#F0F0F0',
                  border: '1px solid #F1F1F1',
                  borderRadius: '5px',
                  color: '#333',
                  padding: '1rem'
                }}>
                <Latex>{`\\(${mathEquation}\\)`}</Latex>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.addMathEquation}>
              Confirm
            </Button>
            <Button variant="text" color="secondary" onClick={this.toggleMathDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default MathInput
