import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const initialState = {
  loadingSubmissionStat: [],
  submissionStat: {},
  loadingSubmissionList: false,
  submissionList: { data: [], count: 0, homeworkId: '', status: '' }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_SUBMISSION_STAT: {
      let updatedLoading = state.loadingSubmissionStat
      updatedLoading.push(action.homeworkId)

      return {
        ...state,
        loadingSubmissionStat: updatedLoading
      }
    }

    case Type.GET_SUBMISSION_STAT_SUCCESS: {
      let updatedLoading = state.loadingSubmissionStat
      updatedLoading = _.remove(updatedLoading, o => {
        return o !== action.homeworkId
      })

      let updatedSubmissionStat = state.submissionStat
      updatedSubmissionStat[action.homeworkId] = action.payload.data

      return {
        ...state,
        loadingSubmissionStat: updatedLoading,
        submissionStat: updatedSubmissionStat
      }
    }

    case Type.GET_SUBMISSION_STAT_FAILED: {
      let updatedLoading = state.loadingSubmissionStat
      updatedLoading = _.remove(updatedLoading, o => {
        return o !== action.homeworkId
      })

      return {
        ...state,
        loadingSubmissionStat: updatedLoading
      }
    }

    case Type.GET_SUBMISSION_LIST:
      return {
        ...state,
        loadingSubmissionList: true,
        submissionList: {
          data: [],
          count: 0,
          homeworkId: '',
          status: ''
        }
      }

    case Type.GET_SUBMISSION_LIST_SUCCESS:
      return {
        ...state,
        loadingSubmissionList: false,
        submissionList: {
          data: action.payload.data,
          count: action.payload.count,
          homeworkId: action.homeworkId,
          status: action.status
        }
      }

    case Type.GET_SUBMISSION_LIST_FAILED:
      return {
        ...state,
        loadingSubmissionList: false
      }

    default:
      return {
        ...state
      }
  }
}
