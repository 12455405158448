export const AVAILABLE_SUBJECT = ['English', 'Mathematics', 'Science']
export const AVAILABLE_LEVEL = ['primary-6', 'primary-5', 'primary-4', 'primary-3']
export const QUESTION_TYPE = ['mcq', 'open_ended']
export const MCQ_ALPHABET_LABEL = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]
export const MATH_TOPICS = [
  'Whole Number',
  'Fractions',
  'Decimals',
  'Money and Mensuration',
  'Time',
  'Line of Symmetry',
  'Rate',
  'Percentage',
  'Patterns',
  'Volume',
  'Area and Perimeter',
  'Geometry - Lines',
  'Geometry - Nets and Three-dimensional Figures',
  'Geometry - Angles',
  'Algebra',
  'Statistics',
  'Speed',
  'Data Representation',
  'Data Analysis'
]
export const MATH_SKILL_SET = {
  'Whole Number': [
    'Rounding off',
    'Factors and Multiples',
    'Place Values',
    'Number in words',
    'Four Operations',
    'Grouping',
    'Interval',
    'Word Problems Revision'
  ],
  Fractions: [
    'Equivalent Fractions',
    'Fraction of a whole',
    'Four Operations',
    'Conversion of Fractions',
    'Comparing and Ordering of Fractions',
    'Fractions of a set',
    'Word Problems Revision'
  ],
  Decimals: ['Place Values', 'Rounding off', 'Conversion of Decimals', 'Four Operations'],
  'Money and Mensuration': ['Conversion of Measurement'],
  Time: ['Reading of Time', 'Conversion of Time', 'Duration of an Event', 'Find Starting Time / Ending Time'],
  'Line of Symmetry': ['Identifying Symmetric Figures', 'Line of Symmetry', 'Completing Symmetric Figure'],
  Rate: [
    'Finding Rate',
    'Rate',
    'Equivalent Ratios',
    'Ratio of Two or Three Given Quantities',
    'Word Problems Revision'
  ],
  Percentage: [
    'Conversion of Percentage',
    'Percentage of Quantity',
    'Percentage Discount, GST and Interest',
    'Percentage Change',
    'Word Problems Revision'
  ],
  Patterns: ['Repeating Patterns', 'Patterns - Combined'],
  Volume: [
    'Building Solids with Unit Cube',
    'Volume of Cube (Using formula)',
    'Volume of Liquids',
    'Comparing Volumes',
    'Finding One Other Dimension',
    'Finding Area of a Face of Cube / Cuboid',
    'Volume - Equal height',
    'Volume - Equal Base Area',
    'Volume - Equal Volume',
    'Volume - Rate'
  ],
  'Area and Perimeter': [
    'Area of squares and rectangles',
    'Perimeter of squares and rectangles',
    'Area of Triangles (Basic)',
    'Area of circle, semi-circle and quadrant',
    'Perimeter of circle, semi-circle and quadrant',
    'Area of combined shapes',
    'Area and Perimeter of composite figures (P3 & P4)',
    'Spatial Visualisation - Unchanged Base / Height',
    'Spatial Visualisation - Cut and Paste'
  ],
  'Geometry - Lines': ['Parallel Lines / Perpendicular Lines'],
  'Geometry - Nets and Three-dimensional Figures': [
    'Viewing of Cubes and Cuboid',
    'Identiffying Nets of Solids',
    'Drawing Cubes and Cuboid (Isometric Grids)'
  ],
  'Geometry - Angles': [
    '8-point Compass',
    'Angles in Squares and Rectangles',
    'Angles in Rhombuses and Parallogram',
    'Angles in Trapezium and Triangles',
    'Angles of Geometric Figures'
  ],
  Algebra: ['Intepret and Simplify Simple Algebraic Expressions', 'Evaluating Algebraic Expressions'],
  Statistics: ['Reading of bar graphs, line graphs', 'Reading of Pie charts'],
  Speed: [
    'Finding Speed, Distance and TIme (By formula)',
    'Finding Average Speed',
    'Speed (One Object Moving)',
    'Speed (Two Objects Moving - Opening Gap)',
    'Speed (Two Objects Moving - Closing Gap)'
  ],
  'Data Representation': ['Tables, Line Graphs and Bar Graphs', 'Pie Charts'],
  'Data Analysis': ['Average']
}
export const HEURISTICS = [
  'Single Unchanged',
  'Total Unchanged (Internal Transfer)',
  'Total Unchanged (Overlapping)',
  'Total Unchanged (Unchanged Total Value)',
  'Difference Unchanged (Age)',
  'Difference Unchanged (Equal Change)',
  'Difference Unchanged (Fixed Part)',
  'All Changing (Units and Parts)',
  'Equal Scenarios',
  'Equal Fractions',
  'Repeated Identity',
  'Remainder Scenarios (Total Given)',
  'Remainder Scenarios (Total Not Given)',
  'Remainder Scenarios (Remaining with an additional)',
  'Spatial Visualisation'
]
