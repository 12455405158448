import React from 'react'
import { connect } from 'react-redux'
import { Button, Chip, CircularProgress, FormLabel, TextField } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import { GET_TICKET_LIST, UPDATE_TICKET, GET_USERS } from 'constants/actionTypes'
import { ROUTE_ORDERS } from 'routes/paths'
import ViewDetailDialog from './dialog-view-renewal-detail'
import QuickUpdateDialog from './dialog-quick-update'
import TicketDataTable from './table-renewal-tickets'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  agentList: state.user.agentList,
  loadingTicketList: state.ticket.loadingList,
  ticketList: state.ticket.list,
  updatingTicket: state.ticket.updatingTicket
})

const mapDispatchToProps = dispatch => ({
  getTickets: (type, filter) => dispatch({ type: GET_TICKET_LIST, payload: agent.Ticket.getList(type, filter) }),
  getUsers: (filter, offset, userType) => dispatch({ type: GET_USERS, payload: agent.User.getUsers(filter), userType }),
  updateTicket: (type, ticketId, reqPayload) =>
    dispatch({ type: UPDATE_TICKET, payload: agent.Ticket.updateById(type, ticketId, reqPayload), ticketId })
})

const LEAD_SOURCE = ['FB', 'Google', 'SGHM', 'Ex', 'Call', 'Call TLS', 'Referral', 'LLP', 'LX', 'Organic']

class RenewalTickets extends React.Component {
  state = {
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().endOf('month').format('YYYY-MM-DD'),
    emptyResult: false,
    tableData: [],
    selectedTicket: null,
    selectedField: null,
    isQuickUpdateDialogOpen: false,
    isViewDetailDialogOpen: false
  }

  componentDidMount() {
    if (this.props.agentList.length === 0) {
      this.props.getUsers({ _type: 'Agent' }, 0, 'Agent')
    }
    this.search()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingTicketList && !this.props.loadingTicketList) {
      if (this.props.ticketList.data.length === 0) {
        this.setState({ emptyResult: true })
      } else {
        this.updateTableData()
      }
    }

    if (prevProps.updatingTicket && !this.props.updatingTicket) {
      this.updateTableData()
    }
  }

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDateRangeSelect = option => () => {
    let fromDate, toDate
    switch (option) {
      case 0:
        fromDate = moment().startOf('day').format('YYYY-MM-DD')
        toDate = moment().endOf('day').format('YYYY-MM-DD')
        break
      case 1:
        fromDate = moment().startOf('week').format('YYYY-MM-DD')
        toDate = moment().endOf('week').format('YYYY-MM-DD')
        break
      case 2:
        fromDate = moment().startOf('week').format('YYYY-MM-DD')
        toDate = moment().add(1, 'week').endOf('week').format('YYYY-MM-DD')
        break
      case 3:
        fromDate = moment().startOf('month').format('YYYY-MM-DD')
        toDate = moment().endOf('month').format('YYYY-MM-DD')
        break

      default:
        break
    }

    this.setState({ fromDate, toDate })
  }

  search = () => {
    const { fromDate, toDate } = this.state
    const filterOpt = {
      subscription_end_date: {
        $gte: moment(fromDate).startOf('day').toISOString(),
        $lte: moment(toDate).endOf('day').toISOString()
      }
    }
    this.props.getTickets('renewal', filterOpt)
    this.setState({ emptyResult: false })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'

    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  updateTableData = () => {
    const { ticketList } = this.props

    for (let i = 0; i < ticketList.data.length; i++) {
      if (!ticketList.data[i].student.name) {
        ticketList.data[i].student['name'] = 'N/A'
      }
      if (!ticketList.data[i].student.phone) {
        ticketList.data[i].student['phone'] = 'N/A'
      } else {
        ticketList.data[
          i
        ].student.phone = `${ticketList.data[i].student.phone_country_code}-${ticketList.data[i].student.phone}`
      }
      if (!ticketList.data[i].handled_by) {
        ticketList.data[i]['handled_by'] = {
          name: 'N/A'
        }
      }
      if (!ticketList.data[i].student.first_language) {
        ticketList.data[i].student['first_language'] = 'N/A'
      }
      if (!ticketList.data[i].next_action_date) {
        ticketList.data[i]['next_action_date'] = 'N/A'
      }
    }
    let tableData = _.orderBy(ticketList.data, ['trial_date'], ['desc'])
    this.setState({ tableData })
  }

  toggleViewDetailDialog = ticketId => () => {
    const { tableData } = this.state
    const selectedTicket = _.find(tableData, o => {
      return o._id === ticketId
    })

    this.setState({
      selectedTicket,
      isViewDetailDialogOpen: !this.state.isViewDetailDialogOpen
    })
  }

  toggleQuickUpdateDialog = (ticketId, selectedField) => () => {
    const { tableData } = this.state
    const selectedTicket = _.find(tableData, o => {
      return o._id === ticketId
    })

    this.setState({
      selectedTicket,
      selectedField,
      isQuickUpdateDialogOpen: !this.state.isQuickUpdateDialogOpen
    })
  }

  openOrderPage = studentEmail => () => {
    window.open(`${process.env.REACT_APP_HOSTNAME}${ROUTE_ORDERS}?email=${studentEmail}`, '_blank')
  }

  render() {
    const { loadingTicketList, currentUser } = this.props
    const {
      fromDate,
      toDate,
      emptyResult,
      tableData,
      selectedTicket,
      selectedField,
      isViewDetailDialogOpen,
      isQuickUpdateDialogOpen
    } = this.state

    return (
      <div className="app-wrapper">
        <div className="row align-items-center">
          <div className="col-12 mb-3">
            <FormLabel className="mb-3" component="legend">
              Subscription End Date
            </FormLabel>
            <div className="d-flex align-items-center manage-margin mb-1">
              <TextField
                variant="outlined"
                type="date"
                label="From"
                name="fromDate"
                value={fromDate}
                onChange={this.handleChange}
              />

              <TextField
                variant="outlined"
                type="date"
                label="To"
                name="toDate"
                value={toDate}
                onChange={this.handleChange}
              />

              <Button variant="contained" color="primary" onClick={this.search}>
                Search
              </Button>
            </div>
            <div className="d-flex">
              <Chip
                size="small"
                label="today"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(0)}
              />

              <Chip
                size="small"
                label="this week"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(1)}
              />

              <Chip
                size="small"
                label="next 2 weeks"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(2)}
              />

              <Chip
                size="small"
                label="this month"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(3)}
              />
            </div>
          </div>
        </div>

        {loadingTicketList && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {emptyResult && <div className="font-weight-semibold my-5 text-center">No ticket found.</div>}

        <TicketDataTable
          data={tableData}
          openViewDetailDialog={this.toggleViewDetailDialog}
          openQuickUpdateDialog={this.toggleQuickUpdateDialog}
          openOrderPage={this.openOrderPage}
          currentUser={currentUser}
        />

        <ViewDetailDialog
          isOpen={isViewDetailDialogOpen}
          close={this.toggleViewDetailDialog()}
          ticketData={selectedTicket}
        />

        <QuickUpdateDialog
          isOpen={isQuickUpdateDialogOpen}
          close={this.toggleQuickUpdateDialog()}
          ticketData={selectedTicket}
          updateField={selectedField}
          LEAD_SOURCE={LEAD_SOURCE}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalTickets)
