import React from 'react'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import moment from 'moment'

import agent from 'agent'
import { GET_SUBMISSION_LIST, GET_PENDING_SUBMISSION_LIST } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingSubmissionList: state.submission.loadingSubmissionList,
  submissionList: state.submission.submissionList,
  loadingPendingSubmission: state.homework.loadingPendingSubmission,
  pendingSubmissionStudentList: state.homework.pendingSubmissionStudentList
})

const mapDispatchToProps = dispatch => ({
  getSubmissionList: (filter, homeworkId, status) =>
    dispatch({
      type: GET_SUBMISSION_LIST,
      payload: agent.Submission.getList(filter),
      homeworkId,
      status
    }),
  getPendingSubmissionList: homeworkId =>
    dispatch({
      type: GET_PENDING_SUBMISSION_LIST,
      payload: agent.Homework.getPendingSubmissionList(homeworkId),
      homeworkId
    })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogDetailSubmissionStat extends React.Component {
  state = {
    data: []
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let { homeworkId, status } = this.props

      if (status === 'PENDING_SUBMISSION') {
        this.props.getPendingSubmissionList(homeworkId)
      } else {
        let filter = {
          homework: homeworkId,
          status: status
        }
        this.props.getSubmissionList(filter, homeworkId, status)
      }
    }

    if (prevProps.loadingSubmissionList && !this.props.loadingSubmissionList) {
      this.setState({ data: this.props.submissionList.data })
    }

    if (prevProps.loadingPendingSubmission && !this.props.loadingPendingSubmission) {
      this.setState({ data: this.props.pendingSubmissionStudentList.data })
    }
  }

  closeDialog = () => {
    this.setState({ data: [] })
    this.props.toggle()()
  }

  render() {
    let { isOpen, loadingSubmissionList, loadingPendingSubmission, status } = this.props
    let { data } = this.state

    return (
      <Dialog open={isOpen} onClose={this.closeDialog} TransitionComponent={Transition} maxWidth="lg">
        <DialogTitle>
          <span style={{ fontSize: '24px' }}>Student List</span>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Submission Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'PENDING_SUBMISSION'
                ? data.map(student => {
                    return (
                      <TableRow key={student._id}>
                        <TableCell>{student.name}</TableCell>
                        <TableCell>{student.email}</TableCell>
                        <TableCell>{student.phone || 'N/A'}</TableCell>
                        <TableCell>N/A</TableCell>
                      </TableRow>
                    )
                  })
                : data.map(submission => {
                    let isLateSubmission = moment(submission.created) > moment(submission.homework.due_on)
                    return (
                      <TableRow key={submission._id}>
                        <TableCell>{submission.created_by.name}</TableCell>
                        <TableCell>{submission.created_by.email}</TableCell>
                        <TableCell>{submission.created_by.phone || 'N/A'}</TableCell>
                        <TableCell>
                          <span className={isLateSubmission ? 'text-danger' : ''}>
                            {moment(submission.created).format('lll')}
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>

          {(loadingSubmissionList || loadingPendingSubmission) && (
            <div className="w-100 text-center my-5">
              <CircularProgress color={'primary'} size={30} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogDetailSubmissionStat.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggle: Proptypes.func.isRequired,
  data: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogDetailSubmissionStat)
