import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import {
  AppBar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DropzoneArea } from 'material-ui-dropzone'
import CKEditor from 'react-ckeditor-component'
import agent from 'agent'
import * as Type from 'constants/actionTypes'

const toolbarGroups = [
  { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
  { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
]

const CkConfig = {
  toolbarGroups
}

const mapStateToProps = state => ({
  creatingEvent: state.event.creatingEvent,
  editingEvent: state.event.editingEvent,
  createError: state.event.error,
  editError: state.event.error,
  tutors: state.user.tutorList
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset, userType) =>
    dispatch({ type: Type.GET_USERS, payload: agent.User.getUsers({ ...filter, _type: userType }, offset), userType }),
  createEvent: eventData => dispatch({ type: Type.CREATE_EVENT, payload: agent.Event.createEvent(eventData) }),
  editEvent: eventData => dispatch({ type: Type.EDIT_EVENT, payload: agent.Event.editEvent(eventData) })
})

const initialState = {
  name: '',
  desc: '',
  level: '',
  subject: '',
  keyword: '',
  keywords: [],
  scheduled_on: moment().format('YYYY-MM-DDTHH:mm'),
  event_duration: 20,
  tutorSelectModalOpen: false,
  tutor: null,
  targetIndex: null,
  errors: {},
  is_listed: true,
  is_public_viewable: true,
  searchTerm: '',
  searchField: 'name',
  image: null,
  reloadedImageUrl: null,
  s3_recoding_path: '',
  series_name: '',
  series_order: '',
  target_audience: 'student'
}

class DialogCreateModifyEvent extends React.Component {
  constructor() {
    super()
    this.state = initialState
    this.onDropzoneChange = this.onDropzoneChange.bind(this)
  }

  componentDidMount() {
    this.props.getUsers({}, 0, 'Tutor')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventData !== this.props.eventData && this.props.eventData) {
      let { eventData } = this.props
      this.setState({
        name: eventData.name,
        desc: eventData.desc,
        level: eventData.meta.level,
        subject: eventData.meta.subject,
        keywords: eventData.meta.keywords,
        scheduled_on: moment(eventData.scheduled_on).format('YYYY-MM-DDTHH:mm'),
        tutor: eventData.host_by,
        is_listed: eventData.is_listed,
        is_public_viewable: eventData.is_public_viewable,
        reloadedImageUrl: eventData.cover_photo,
        s3_recoding_path: eventData.playback_url && eventData.playback_url.length > 0 ? eventData.playback_url[0] : '',
        series_name: eventData.meta.series ? eventData.meta.series.name : '',
        series_order: eventData.meta.series ? eventData.meta.series.order : '',
        target_audience: eventData.target_audience
      })
    }

    if (prevProps.creatingEvent && !this.props.creatingEvent && !this.props.createError) {
      this.props.toggle(null)
      this.props.postSave()
    }
    if (prevProps.editingEvent && !this.props.editingEvent && !this.props.editError) {
      this.props.toggle(null)
      this.props.postSave()
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(initialState)
    }
  }

  onChangeDiscountCode = e => {
    this.setState({ discount_code: e.target.value.toUpperCase() })
  }

  onInputChange = e => {
    let { keywords } = this.state
    let target = e.target.name
    let value = e.target.value

    this.setState({ [target]: value })

    if (target === 'level') {
      this.setState({
        keywords: keywords.concat(value.replace('-', ' '))
      })
    } else if (target === 'subject') {
      this.setState({
        keywords: keywords.concat(value)
      })
    }
  }

  toggleTutorSelectModal = () => {
    this.setState({
      tutorSelectModalOpen: !this.state.tutorSelectModalOpen
    })
  }

  selectTutor = selectedTutor => () => {
    this.setState({
      tutorSelectModalOpen: false,
      tutor: selectedTutor
    })
  }

  onFormSubmit = () => {
    const {
      errors,
      name,
      desc,
      level,
      subject,
      keywords,
      scheduled_on,
      event_duration,
      tutor,
      image,
      is_listed,
      is_public_viewable,
      s3_recoding_path,
      series_name,
      series_order,
      target_audience
    } = this.state

    if (!_.isEmpty(errors)) {
      return false
    }

    let newData = new FormData()

    newData.append('name', name)
    newData.append('desc', desc)
    newData.append('level', level)
    newData.append('subject', subject)
    newData.append('keywords', keywords)
    newData.append('scheduled_on', scheduled_on)
    newData.append('event_duration', event_duration)
    newData.append('host_by', tutor._id)
    newData.append('is_listed', !!is_listed)
    newData.append('is_public_viewable', !!is_public_viewable)
    newData.append('hosting_platform', 'Zoom')
    newData.append('hosting_type', 'webinar')
    newData.append('s3_recoding_path', s3_recoding_path)
    newData.append('target_audience', target_audience)

    if (image) {
      const imageExt = image.name.split('.').pop()
      newData.append('attachment', image, `tnb-${name}.${imageExt}`)
    }

    if (series_name) {
      newData.append('series_name', series_name)
      newData.append('series_order', series_order)
    }

    if (!this.props.eventData) {
      this.props.createEvent(newData)
    } else {
      newData._id = this.props.eventData._id
      this.props.editEvent(newData)
    }
  }

  onChangeSearch = e => {
    this.setState({ searchTerm: e.target.value })
  }

  onDropzoneChange(files) {
    this.setState({
      image: files[0]
    })
  }

  searchUser = e => {
    e.preventDefault()
    const { searchTerm, searchField } = this.state
    let filter = { [searchField]: { $regex: `${searchTerm}`, $options: 'i' } }
    this.props.getUsers(filter, 0, e.target.user_type.value)
  }

  clearSearch = () => {
    this.setState({ searchTerm: '' })
    this.props.getUsers({}, 0, 'Tutor')
  }

  onCheckboxClick = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  onCKEditorChange = evt => {
    let desc = evt.editor.getData()
    this.setState({ desc })
  }

  handleKeyPress = event => {
    let { keywords } = this.state
    if (event.key === 'Enter') {
      this.setState({
        keyword: '',
        keywords: keywords.concat(event.target.value.trim())
      })
    }
  }

  handleRequestDelete = item => () => {
    let { keywords } = this.state
    _.remove(keywords, o => {
      return o === item
    })
    this.setState({ keywords })
  }

  render() {
    const { isOpen, toggle, tutors, eventData, creatingEvent, editingEvent } = this.props
    const {
      name,
      desc,
      level,
      subject,
      keyword,
      keywords,
      is_listed,
      is_public_viewable,
      tutorSelectModalOpen,
      searchField,
      searchTerm,
      tutor,
      scheduled_on,
      event_duration,
      image,
      reloadedImageUrl,
      s3_recoding_path,
      series_name,
      series_order,
      target_audience
    } = this.state

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className={'text-white'}>
              <CloseIcon />
            </IconButton>
            <Typography
              type="title"
              color="inherit"
              style={{
                flex: 1
              }}>
              {!eventData ? 'Create Live Sharing Session' : 'Update Live Sharing Session'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col">
              <Paper elevation={2} className={'app-wrapper'}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Event Name"
                    name={'name'}
                    onChange={this.onInputChange}
                    defaultValue={name}
                    margin="none"
                  />
                </FormControl>

                <div className="row mt-2">
                  <div className="col-4">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="level">Level</InputLabel>
                      <Select name="level" value={level} onChange={this.onInputChange}>
                        <MenuItem value="primary-3">Primary 3</MenuItem>
                        <MenuItem value="primary-4">Primary 4</MenuItem>
                        <MenuItem value="primary-5">Primary 5</MenuItem>
                        <MenuItem value="primary-6">Primary 6</MenuItem>
                        <MenuItem value="secondary-1">Lower Sec</MenuItem>
                        <MenuItem value="secondary-4">'O' Level</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="subject">Subject</InputLabel>
                      <Select name="subject" value={subject} onChange={this.onInputChange}>
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Mathematics">Mathematics</MenuItem>
                        <MenuItem value="Science">Science</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="target_audience">Target Audience</InputLabel>
                      <Select name="target_audience" value={target_audience} onChange={this.onInputChange}>
                        <MenuItem value="student">Student</MenuItem>
                        <MenuItem value="parent">Parent</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="mt-3 mb-1" style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>
                  Keywords:
                </div>
                <div className="manage-margin d-flex flex-wrap mb-3">
                  {keywords.map(keyword => {
                    return <Chip label={keyword} key={keyword} onDelete={this.handleRequestDelete(keyword)} />
                  })}
                  <TextField
                    type="text"
                    placeholder="Add new keyword"
                    name="keyword"
                    value={keyword}
                    onChange={this.onInputChange}
                    onKeyPress={this.handleKeyPress}
                    className="ml-1"
                  />
                </div>

                <div className="row">
                  <div className="col-6 mb-2">
                    <TextField
                      label="Scheduled On"
                      type="datetime-local"
                      name="scheduled_on"
                      fullWidth
                      value={scheduled_on}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <TextField
                      label="Duration (minutes)"
                      type="number"
                      name="event_duration"
                      fullWidth
                      value={event_duration}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <TextField
                      label="Series Name"
                      type="text"
                      name="series_name"
                      fullWidth
                      value={series_name}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <TextField
                      label="Series Episode"
                      type="number"
                      name="series_order"
                      fullWidth
                      value={series_order}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="col-12">
                    <FormControl className="w-100">
                      <TextField
                        label="Recording URL (S3 path)"
                        name={'s3_recoding_path'}
                        onChange={this.onInputChange}
                        defaultValue={s3_recoding_path}
                        margin="none"
                      />
                    </FormControl>
                  </div>
                </div>
              </Paper>
              <Paper elevation={2} className={'app-wrapper mt-4'}>
                <h3>Event Overview:</h3>
                <FormControl className={'w-100'}>
                  <CKEditor
                    name={'desc'}
                    activeClass="p10"
                    content={desc}
                    config={CkConfig}
                    events={{
                      change: this.onCKEditorChange
                    }}
                  />
                </FormControl>
              </Paper>
            </div>
            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h3>Event Image</h3>
                <div className="row">
                  {!!reloadedImageUrl ? (
                    <div className="col">
                      <h4 className="mt-4 mb-2">Current Image</h4>
                      <img className="img-fluid" src={reloadedImageUrl} alt="Current event" />
                    </div>
                  ) : null}
                  <div className="col">
                    {!!reloadedImageUrl && <h4 className="mt-4 mb-2">New Image (optional)</h4>}
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                      onChange={this.onDropzoneChange}
                    />
                  </div>
                </div>
              </Paper>

              <Paper elevation={2} className={'app-wrapper my-3'}>
                <h3>Hosted By</h3>
                <h3 className="my-2">{!!tutor ? tutor.name : null}</h3>
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTutorSelectModal}>
                  Select Teacher
                </Button>
                {/*Select Tutor Dialog*/}
                <Dialog onClose={this.toggleTutorSelectModal} open={tutorSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a teacher</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="Tutor" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            value={searchTerm}
                            onChange={this.onChangeSearch}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.onInputChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!tutors ? (
                      tutors.length > 0 ? (
                        <List disablePadding={true}>
                          {tutors.map(user => (
                            <ListItem button onClick={this.selectTutor(user)} key={user._id}>
                              <ListItemText primary={user.name} secondary={user.email} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <div className="app-wrapper">
                          <h4 className={'font-weight-semibold'}>No tutors found</h4>
                        </div>
                      )
                    ) : (
                      <div className="my-5 text-center">
                        <CircularProgress color={'primary'} size={20} />
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>

              <Paper elevation={2} className={'app-wrapper'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={'is_public_viewable'}
                      checked={is_public_viewable}
                      onChange={this.onCheckboxClick}
                    />
                  }
                  label="Viewable By Public"
                />
                <FormControlLabel
                  control={
                    <Checkbox color="primary" name={'is_listed'} checked={is_listed} onChange={this.onCheckboxClick} />
                  }
                  label="Is Listed"
                />
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !name ||
              !desc ||
              !level ||
              !subject ||
              !tutor ||
              !scheduled_on ||
              (!eventData && !image) ||
              !!creatingEvent ||
              !!editingEvent
            }
            variant="contained"
            color="primary"
            onClick={this.onFormSubmit}>
            {!eventData ? 'Create' : 'Update'} Session
            {(creatingEvent || editingEvent) && <CircularProgress className="ml-2" color="primary" size={15} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogCreateModifyEvent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  postSave: PropTypes.func.isRequired,
  eventData: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogCreateModifyEvent)
