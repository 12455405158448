import React from 'react'

const SMSPreview = props => {
  const { content } = props

  let formatted = content
    .replace(/\r?\n/g, '<br />')
    .replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, url => {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })

  return (
    <div className="smartphone">
      <div className="content">
        <div className="p-3 mx-auto">
          {!!formatted && (
            <div
              className="bg-green text-white p-3 rounded"
              style={{ marginLeft: '20%' }}
              dangerouslySetInnerHTML={{ __html: formatted }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SMSPreview
