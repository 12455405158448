import React from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import moment from 'moment'

import agent from 'agent'
import { DELETE_LESSON_POLL, REFRESH_LESSON } from 'constants/actionTypes'
import { ROUTE_LESSON_MANAGEMENT, ROUTE_LESSON_DETAIL_ZOOM } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'
import CreateEditPollDialog from 'pages/LessonManagement/CreateEditPoll'

const mapStateToProps = state => ({
  activeLesson: state.lessons.activeLesson,
  isLoading: state.lessons.isLoading,
  deletingPoll: state.lessons.deletingPoll,
  pollDeleted: state.lessons.pollDeleted,
  creatingPoll: state.lessons.creatingPoll,
  pollCreated: state.lessons.pollCreated,
  editingPoll: state.lessons.editingPoll,
  pollEdited: state.lessons.pollEdited,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  deletePollQuestion: (lessonId, questionIndex) =>
    dispatch({ type: DELETE_LESSON_POLL, payload: agent.Lesson.deletePollQuestion(lessonId, questionIndex) }),
  refreshLesson: lessonId => dispatch({ type: REFRESH_LESSON, payload: agent.Lesson.getLesson(lessonId) })
})

class Detail extends React.Component {
  state = {
    selectedQuestionIndex: -1,
    isCreateEditPollDialogOpen: false,
    isConfirmDeleteDialogOpen: false,
    copied: false,
    canManagePoll: true
  }

  componentDidMount() {
    if (!this.props.activeLesson) {
      this.props.history.push(ROUTE_LESSON_MANAGEMENT)
    }

    this.setState({
      canManagePoll: checkActionPermitted(permissions, this.props.userRole, ROUTE_LESSON_DETAIL_ZOOM, 'canManagePoll')
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deletingPoll && !this.props.deletingPoll) {
      this.setState({ isConfirmDeleteDialogOpen: false, selectedQuestionIndex: -1 })
    }

    if (prevProps.creatingPoll && !this.props.creatingPoll && this.props.pollCreated) {
      this.props.refreshLesson(this.props.activeLesson._id)
      this.setState({ isCreateEditPollDialogOpen: false })
    }

    if (prevProps.editingPoll && !this.props.editingPoll && this.props.pollEdited) {
      this.props.refreshLesson(this.props.activeLesson._id)
      this.setState({ isCreateEditPollDialogOpen: false })
    }

    if (prevProps.deletingPoll && !this.props.deletingPoll && this.props.pollDeleted) {
      this.props.refreshLesson(this.props.activeLesson._id)
    }
  }

  toggleCreateEditPollDialog = index => () => {
    this.setState({
      isCreateEditPollDialogOpen: !this.state.isCreateEditPollDialogOpen,
      selectedQuestionIndex: index
    })
  }

  toggleConfirmDeleteDialog = index => () => {
    this.setState({
      isConfirmDeleteDialogOpen: !this.state.isConfirmDeleteDialogOpen,
      selectedQuestionIndex: index
    })
  }

  deleteQuestion = () => {
    this.props.deletePollQuestion(this.props.activeLesson._id, this.state.selectedQuestionIndex)
  }

  render() {
    const { activeLesson, deletingPoll } = this.props
    const { selectedQuestionIndex, isCreateEditPollDialogOpen, isConfirmDeleteDialogOpen, copied, canManagePoll } =
      this.state

    if (!activeLesson || !activeLesson.platform_integration) {
      return (
        <div className="app-wrapper">
          <div className="row">
            <div className="col-12">
              <div
                className="text-primary mb-2"
                onClick={() => {
                  this.props.history.push(ROUTE_LESSON_MANAGEMENT)
                }}>
                <i className="fa fa-angle-left mr-1"></i>
                <span className="clickable">Back</span>
              </div>
              <h2>No zoom room created</h2>
            </div>
          </div>
        </div>
      )
    }

    const hostAccount = activeLesson.platform_integration.host_email
    const altAccount = activeLesson.platform_integration.settings.alternative_hosts

    return (
      <div className="app-wrapper">
        {this.props.isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <div
              className="text-primary mb-2"
              onClick={() => {
                this.props.history.push(ROUTE_LESSON_MANAGEMENT)
              }}>
              <i className="fa fa-angle-left mr-1"></i>
              <span className="clickable">Back</span>
            </div>

            <h2>Detail</h2>

            <table>
              <tbody>
                <tr>
                  <td>Class</td>
                  <td>:</td>
                  <td>{activeLesson.class.name}</td>
                </tr>
                <tr>
                  <td>Lesson</td>
                  <td>:</td>
                  <td>{activeLesson.name}</td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>:</td>
                  <td>
                    {moment(activeLesson.scheduled_on).format('lll')} - {moment(activeLesson.ended_on).format('h:mm a')}
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className="mt-3">Zoom</h2>

            <table>
              <tbody>
                <tr>
                  <td>Meeting ID</td>
                  <td>:</td>
                  <td>{activeLesson.platform_integration.id}</td>
                </tr>
                <tr>
                  <td>Password</td>
                  <td>:</td>
                  <td>{activeLesson.platform_integration.password}</td>
                </tr>
                <tr>
                  <td>Registration URL</td>
                  <td>:</td>
                  <td>
                    {activeLesson.platform_integration.registration_url}
                    <CopyToClipboard
                      text={activeLesson.platform_integration.registration_url}
                      onCopy={() => this.setState({ copied: true })}>
                      <Button color="secondary" variant="text" size="small">
                        {copied ? 'Copied!' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                  </td>
                </tr>
                <tr>
                  <td>Host: </td>
                  <td>:</td>
                  <td>{hostAccount}</td>
                </tr>
                <tr>
                  <td>Alternate Host: </td>
                  <td>:</td>
                  <td>{altAccount}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <div className="d-flex flex-row align-items-center my-3">
              <h2 className="mb-0">Poll</h2>
              {canManagePoll && (
                <Button
                  onClick={this.toggleCreateEditPollDialog(
                    activeLesson.poll_setup.length > 0 ? activeLesson.poll_setup.length + 1 : 0
                  )}
                  color="primary"
                  variant="outlined"
                  className="ml-3">
                  Add Question
                </Button>
              )}
            </div>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Question</th>
                  <th>Type</th>
                  <th>Answer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {activeLesson.poll_setup.map((p, index) => (
                  <tr key={index}>
                    <td>{p.question_index}</td>
                    <td>{p.question}</td>
                    <td>{p.choice_type}</td>
                    <td>
                      {p.option.map((o, index) => (
                        <div key={index} className="d-flex align-items-center">
                          {o.label}. {o.content}
                          {p.answer_index.includes(index) && (
                            <Done className="ml-1 text-success" style={{ width: '0.8em', height: '0.8em' }} />
                          )}
                        </div>
                      ))}
                    </td>
                    <td>
                      {canManagePoll && (
                        <>
                          <Button onClick={this.toggleCreateEditPollDialog(index)} color="primary">
                            Edit
                          </Button>
                          <Button onClick={this.toggleConfirmDeleteDialog(p.question_index)} color="secondary">
                            Delete
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <CreateEditPollDialog
          isOpen={isCreateEditPollDialogOpen}
          toggleDialog={this.toggleCreateEditPollDialog(-1)}
          lessonId={activeLesson._id}
          pollCount={activeLesson.poll_setup.length}
          poll={
            selectedQuestionIndex > -1 && activeLesson.poll_setup[selectedQuestionIndex]
              ? activeLesson.poll_setup[selectedQuestionIndex]
              : ''
          }
        />

        <Dialog open={isConfirmDeleteDialogOpen} onClose={this.toggleConfirmDeleteDialog(-1)}>
          <DialogTitle>Confirm delete this question?</DialogTitle>
          <DialogContent>
            {selectedQuestionIndex > -1 && activeLesson.poll_setup[selectedQuestionIndex] && (
              <p>{activeLesson.poll_setup[selectedQuestionIndex].question}</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmDeleteDialog(-1)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteQuestion} color="secondary" variant="contained" disabled={deletingPoll}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
