import React from 'react'
import Proptypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogStudentList = props => {
  const { isOpen, toggle, listData, showUserPurchaseStatus } = props
  let showJoinLeaveTime = listData.length > 0 && listData[0].join_time && listData[0].leave_time
  let sortedListData = listData
  let purchasedUserCount = 0,
    newUserCount = 0
  if (showUserPurchaseStatus) {
    sortedListData = _.orderBy(listData, ['first_purchased_on', 'name'], ['desc', 'asc'])
    purchasedUserCount = _.filter(listData, o => {
      return o.first_purchased_on
    }).length
    newUserCount = listData.length - purchasedUserCount
  }

  return (
    <Dialog open={isOpen} onClose={toggle([])} TransitionComponent={Transition} maxWidth="lg">
      <DialogTitle>
        <span style={{ fontSize: '24px' }}>Student List</span>
      </DialogTitle>
      <DialogContent>
        {showUserPurchaseStatus && (
          <div className="text-right mb-1">
            *<span className="font-weight-semibold">{purchasedUserCount}</span> purchased users,{' '}
            <span className="font-weight-semibold">{newUserCount}</span> new users.
          </div>
        )}
        <Table>
          <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              {showJoinLeaveTime && (
                <>
                  <TableCell>Join Time</TableCell>
                  <TableCell>Leave Time</TableCell>
                </>
              )}
              {showUserPurchaseStatus && <TableCell>Purchased User</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedListData.map(student => (
              <TableRow key={student._id}>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.email}</TableCell>
                <TableCell>{student.phone || 'N/A'}</TableCell>
                {showJoinLeaveTime && (
                  <>
                    <TableCell>{moment(student.join_time).format('lll')}</TableCell>
                    <TableCell>{moment(student.leave_time).format('lll')}</TableCell>
                  </>
                )}
                {showUserPurchaseStatus && <TableCell>{student.first_purchased_on ? 'Yes' : 'No'}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle([])} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogStudentList.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggle: Proptypes.func.isRequired,
  data: Proptypes.object,
  showUserPurchaseStatus: Proptypes.bool
}

export default DialogStudentList
