import agent from 'agent'
import React from 'react'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'
import { store } from 'store'
import { push } from 'connected-react-router'
import Routes from 'routes/routes'

import Header from 'components/shared/Header'
import SideNav from 'components/shared/SideNav'
import Footer from 'components/shared/Footer'

import 'styles/custom.css'

const mapStateToProps = state => ({
  loadingApp: state.common.loadingApp,
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo,
  token: state.common.token
})

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) => dispatch({ type: Type.LOAD_APP, payload, token }),
  redirectUnauthenticatedUser: () => dispatch({ type: Type.REDIRECT_UNAUTHENTICATED_USER }),
  onRedirect: () => dispatch({ type: Type.REDIRECT })
})

class App extends React.Component {
  componentDidMount() {
    const token = window.localStorage.getItem('jwt')
    if (token) {
      agent.setToken(token)
      if (!this.props.currentUser) {
        this.props.onLoad(Promise.all([agent.Auth.current()]), token)
      }
    } else {
      this.props.redirectUnauthenticatedUser()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.redirectTo !== this.props.redirectTo) {
      store.dispatch(push(this.props.redirectTo))
      this.props.onRedirect()
    }

    if (prevProps.loadingApp && !this.props.loadingApp) {
      agent.setToken(this.props.token)
    }
  }

  render() {
    let { currentUser } = this.props

    return (
      <div className="app-container fixed-drawer">
        <SideNav />
        <div className="app-main-container">
          <div className="app-header">
            <Header />
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">{currentUser && <Routes />}</div>
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
