import * as Type from 'constants/actionTypes'
import _ from 'lodash'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_DISCOUNT_CODES:
    case Type.LOAD_BY_PAGE:
      return {
        ...state,
        isLoading: true,
        discountCodes: null
      }

    case Type.GET_DISCOUNT_CODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        discountCodes: action.payload,
        currentPage: 0
      }

    case Type.LOAD_BY_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        discountCodes: action.payload,
        currentPage: action.page
      }

    case Type.GET_DISCOUNT_CODES_FAILED:
    case Type.LOAD_BY_PAGE_FAILED:
    case Type.UNAUTHORIZED_ACCESS:
      return {
        ...state,
        isLoading: false
      }

    case Type.DISABLE_DISCOUNT_CODE:
    case Type.ENABLE_DISCOUNT_CODE:
      return {
        ...state,
        isUpdateInProgress: true
      }

    case Type.DISABLE_DISCOUNT_CODE_SUCCESS: {
      let updatedDiscountCodes = _.clone(state.discountCodes)
      for (let i = 0; i < updatedDiscountCodes.data.length; i++) {
        if (updatedDiscountCodes.data[i]._id === action.code_id) {
          updatedDiscountCodes.data[i].is_active = false
        }
      }
      return {
        ...state,
        isUpdateInProgress: false,
        discountCodes: updatedDiscountCodes
      }
    }

    case Type.ENABLE_DISCOUNT_CODE_SUCCESS: {
      let updatedDiscountCodes = _.clone(state.discountCodes)
      for (let i = 0; i < updatedDiscountCodes.data.length; i++) {
        if (updatedDiscountCodes.data[i]._id === action.code_id) {
          updatedDiscountCodes.data[i].is_active = true
        }
      }
      return {
        ...state,
        isUpdateInProgress: false,
        discountCodes: updatedDiscountCodes
      }
    }

    case Type.CREATE_DISCOUNT_CODE:
      return {
        ...state,
        isLoading: true,
        codeCreated: false,
        error: false
      }
    case Type.CREATE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        codeCreated: true,
        error: false
      }
    case Type.CREATE_DISCOUNT_CODE_FAILED:
      return {
        ...state,
        isLoading: false,
        codeCreated: false,
        error: true
      }
    default:
      return state
  }
}
