import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_CLASS_ENROLMENT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingClassEnrolment: state.report.loadingClassEnrolment,
  classEnrolment: state.report.classEnrolment
})

const mapDispatchToProps = dispatch => ({
  getEnrolmentData: reqPayload =>
    dispatch({ type: GET_CLASS_ENROLMENT, payload: agent.Report.getClassEnrolment(reqPayload) })
})

const headCells = [
  { id: 'class.name', numeric: false, label: 'Class' },
  { id: 'class.tutor[0]', numeric: false, label: 'Tutor' },
  { id: 'trial_student_count', numeric: true, label: 'Next Lesson Trial' },
  { id: 'aggregate_student_count', numeric: true, label: 'Total Subscriptions' },
  { id: 'active_student_count', numeric: true, label: 'Active Subscriptions' },
  { id: 'recent_order_summary.order_in_1_day.total', numeric: true, label: 'Yesterday' },
  { id: 'recent_order_summary.order_in_7_day.total', numeric: true, label: 'Last 7 days' },
  { id: 'recent_order_summary.order_in_30_day.total', numeric: true, label: 'Last 30 days' }
]

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const SalesStat = props => {
  const totalSales = props.data.total
  const agentStat = Object.entries(props.data.agent)
  const [showBreakdown, setShowBreakdown] = useState(false)

  return (
    <>
      <div
        className="clickable"
        onClick={() => {
          setShowBreakdown(!showBreakdown)
        }}>
        {totalSales}
      </div>
      {showBreakdown && (
        <div
          onClick={() => {
            setShowBreakdown(false)
          }}>
          {agentStat.map((agent, index) => (
            <div key={index}>
              <Chip
                avatar={<Avatar>{agent[1]}</Avatar>}
                label={agent[0]}
                color="primary"
                variant="outlined"
                className="mb-1"
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

class ClassEnrolmentTab extends React.Component {
  state = {
    classType: 'Regular Classes',
    subject: 'English',
    startDate: moment().format('YYYY-MM-DD'),
    order: 'asc',
    orderBy: 'class.name',
    sortedData: [],
    totalRow: {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadingClassEnrolment && !this.props.loadingClassEnrolment) {
      const { classEnrolment } = this.props
      if (classEnrolment === 'No record found') return
      this.sortEnrolmentData()

      // NOTE: lazy way to write, improve later
      let total1Day = {},
        total7Day = {},
        total30Day = {}
      classEnrolment.forEach(data => {
        for (let [key, value] of Object.entries(data.recent_order_summary.order_in_1_day.agent)) {
          if (total1Day[key]) {
            total1Day[key] += value
          } else {
            total1Day[key] = value
          }
        }

        for (let [key, value] of Object.entries(data.recent_order_summary.order_in_7_day.agent)) {
          if (total7Day[key]) {
            total7Day[key] += value
          } else {
            total7Day[key] = value
          }
        }

        for (let [key, value] of Object.entries(data.recent_order_summary.order_in_30_day.agent)) {
          if (total30Day[key]) {
            total30Day[key] += value
          } else {
            total30Day[key] = value
          }
        }
      })

      this.setState({
        totalRow: {
          nextLessonTrials: _.sumBy(classEnrolment, o => {
            return o.class.next_lesson.trial_student.length
          }),
          alllSubscriptions: _.sumBy(classEnrolment, 'aggregate_student_count'),
          activeSubscriptions: _.sumBy(classEnrolment, 'active_student_count'),
          yesterday: _.sumBy(classEnrolment, 'recent_order_summary.order_in_1_day.total'),
          last7Day: _.sumBy(classEnrolment, 'recent_order_summary.order_in_7_day.total'),
          last30Day: _.sumBy(classEnrolment, 'recent_order_summary.order_in_30_day.total'),
          agentSummaryData: {
            total1Day,
            total7Day,
            total30Day
          }
        }
      })
    }

    if (prevState.orderBy !== this.state.orderBy || prevState.order !== this.state.order) {
      this.sortEnrolmentData()
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  generate = () => {
    const { classType, subject } = this.state
    this.props.getEnrolmentData({
      class_type: classType,
      //start_date: {
      //  $gte: moment(startDate).toISOString()
      //},
      end_date: {
        $gte: moment().toISOString()
      },
      subject,
      is_active: true,
      is_listed: true
    })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  sortEnrolmentData = () => {
    const { classEnrolment } = this.props
    const { order, orderBy } = this.state
    let sortedData = _.orderBy(classEnrolment, [orderBy], [order])
    this.setState({ sortedData })
  }

  render() {
    const { activeTab, loadingClassEnrolment, classEnrolment } = this.props
    const { classType, subject, order, orderBy, sortedData, totalRow } = this.state

    return (
      <div className="app-wrapper" hidden={activeTab !== 'class-enrolment'}>
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            <div className="text-muted">Class type: </div>
            <RadioGroup name="classType" value={classType} onChange={this.handleChange} row>
              <FormControlLabel value="Regular Classes" control={<Radio />} label="Regular Classes" />
              <FormControlLabel value="Workshop" control={<Radio />} label="Workshops" />
            </RadioGroup>
          </div>
          <div className="col-auto">
            <div className="text-muted">Subject: </div>
            <RadioGroup name="subject" value={subject} onChange={this.handleChange} row>
              <FormControlLabel value="English" control={<Radio />} label="English" />
              <FormControlLabel value="Mathematics" control={<Radio />} label="Math" />
              <FormControlLabel value="Science" control={<Radio />} label="Science" />
            </RadioGroup>
          </div>
          <div className="col-auto">
            <div className="d-flex align-items-center manage-margin">
              {/*<TextField
                variant="outlined"
                type="date"
                label="Class start date"
                name="startDate"
                value={startDate}
                onChange={this.handleChange}
              />*/}
              <Button variant="contained" color="primary" onClick={this.generate}>
                Generate
              </Button>
            </div>
          </div>
        </div>

        {loadingClassEnrolment && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {!_.isArray(classEnrolment) ? (
          <h2>{classEnrolment}</h2>
        ) : (
          <Table>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
            <TableBody>
              {sortedData.map((enrolment, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{enrolment.class.name}</TableCell>
                    <TableCell>{enrolment.class.tutor.join(', ')}</TableCell>
                    <TableCell align="right">{enrolment.class.next_lesson.trial_student.length}</TableCell>
                    <TableCell align="right">{enrolment.aggregate_student_count}</TableCell>
                    <TableCell align="right">{enrolment.active_student_count}</TableCell>
                    <TableCell align="right">
                      <SalesStat data={enrolment.recent_order_summary.order_in_1_day} />
                    </TableCell>
                    <TableCell align="right">
                      <SalesStat data={enrolment.recent_order_summary.order_in_7_day} />
                    </TableCell>
                    <TableCell align="right">
                      <SalesStat data={enrolment.recent_order_summary.order_in_30_day} />
                    </TableCell>
                  </TableRow>
                )
              })}
              {!_.isEmpty(totalRow) && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">{totalRow.nextLessonTrials}</TableCell>
                  <TableCell align="right">{totalRow.alllSubscriptions}</TableCell>
                  <TableCell align="right">{totalRow.activeSubscriptions}</TableCell>
                  <TableCell align="right">
                    <SalesStat
                      data={{
                        total: totalRow.yesterday,
                        agent: totalRow.agentSummaryData.total1Day
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <SalesStat
                      data={{
                        total: totalRow.last7Day,
                        agent: totalRow.agentSummaryData.total7Day
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <SalesStat
                      data={{
                        total: totalRow.last30Day,
                        agent: totalRow.agentSummaryData.total30Day
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassEnrolmentTab)
