import * as Type from 'constants/actionTypes'

const initialState = {
  loadingClassEnrolment: false,
  classEnrolment: null,
  loadingTrialStat: false,
  trialStat: null,
  loadingHomeworkAssignmentStat: false,
  homeworkAssignmentStat: null,
  loadingBirthdayStudent: false,
  birthdayStudentList: [],
  loadingSalesStat: false,
  salesStat: null,
  loadingTicketsStat: false,
  ticketsStat: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_CLASS_ENROLMENT:
      return {
        ...state,
        loadingClassEnrolment: true,
        classEnrolment: null
      }

    case Type.GET_CLASS_ENROLMENT_SUCCESS:
      return {
        ...state,
        loadingClassEnrolment: false,
        classEnrolment: action.payload.data
      }

    case Type.GET_CLASS_ENROLMENT_FAILED:
      return {
        ...state,
        loadingClassEnrolment: false,
        classEnrolment: 'No record found'
      }

    case Type.GET_TRIAL_STAT:
      return {
        ...state,
        loadingTrialStat: true,
        trialStat: null
      }

    case Type.GET_TRIAL_STAT_SUCCESS:
      return {
        ...state,
        loadingTrialStat: false,
        trialStat: action.payload.data
      }

    case Type.GET_TRIAL_STAT_FAILED:
      return {
        ...state,
        loadingTrialStat: false,
        trialStat: 'No record found'
      }

    case Type.GET_HOMEWORK_ASSIGNMENT_STAT:
      return {
        ...state,
        loadingHomeworkAssignmentStat: true,
        homeworkAssignmentStat: null
      }

    case Type.GET_HOMEWORK_ASSIGNMENT_STAT_SUCCESS:
      return {
        ...state,
        loadingHomeworkAssignmentStat: false,
        homeworkAssignmentStat: action.payload.data
      }

    case Type.GET_HOMEWORK_ASSIGNMENT_STAT_FAILED:
      return {
        ...state,
        loadingHomeworkAssignmentStat: false,
        homeworkAssignmentStat: 'No record found'
      }

    case Type.GET_BIRTHDAY_STUDENT:
      return {
        ...state,
        loadingBirthdayStudent: true,
        birthdayStudentList: []
      }

    case Type.GET_BIRTHDAY_STUDENT_SUCCESS:
      return {
        ...state,
        loadingBirthdayStudent: false,
        birthdayStudentList: action.payload.data
      }

    case Type.GET_BIRTHDAY_STUDENT_FAILED:
      return {
        ...state,
        loadingBirthdayStudent: false
      }

    case Type.GET_SALES_STAT:
      return {
        ...state,
        loadingSalesStat: true,
        salesStat: null
      }

    case Type.GET_SALES_STAT_SUCCESS:
      return {
        ...state,
        loadingSalesStat: false,
        salesStat: action.payload.data
      }

    case Type.GET_SALES_STAT_FAILED:
      return {
        ...state,
        loadingSalesStat: false
      }

    case Type.GET_TICKETS_STAT:
      return {
        ...state,
        loadingTicketsStat: true
      }

    case Type.GET_TICKETS_STAT_SUCCESS:
      return {
        ...state,
        loadingTicketsStat: false,
        ticketsStat: action.payload.data
      }

    case Type.GET_TICKETS_STAT_FAILED:
      return {
        ...state,
        loadingTicketsStat: false,
        ticketsStat: null
      }

    default:
      return {
        ...state
      }
  }
}
