import React from 'react'

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import moment from 'moment'

const AccordionPollDetails = props => {
  const { history } = props

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} style={{ backgroundColor: '#efefef' }}>
        Expand for details
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {history.map((rowData, index) => {
            return (
              <div className="border-bottom p-2" key={index}>
                {Object.keys(rowData).map((key, index) => {
                  let content = rowData[key]
                  if (moment(content).isValid()) {
                    content = moment(content).format('lll')
                  }
                  return (
                    <div key={index}>
                      <span className="text-muted">{key}:</span> {content}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionPollDetails
