import * as Type from 'constants/actionTypes'

const initialState = {
  loadingHomeworkList: false,
  homeworkList: { data: [], count: 0 },
  pendingSubmissionStudentList: { data: [], homeworkId: '' }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_HOMEWORK_LIST:
      return {
        ...state,
        loadingHomeworkList: true
      }

    case Type.GET_HOMEWORK_LIST_SUCCESS: {
      let updatedHomeworkList = state.homeworkList
      updatedHomeworkList.count = action.payload.count
      updatedHomeworkList.data = action.payload.data

      return {
        ...state,
        loadingHomeworkList: false,
        homeworkList: updatedHomeworkList
      }
    }

    case Type.GET_HOMEWORK_LIST_FAILED:
      return {
        ...state,
        loadingHomeworkList: false
      }

    case Type.GET_PENDING_SUBMISSION_LIST:
      return {
        ...state,
        loadingPendingSubmission: true
      }

    case Type.GET_PENDING_SUBMISSION_LIST_SUCCESS:
      return {
        ...state,
        loadingPendingSubmission: false,
        pendingSubmissionStudentList: {
          data: action.payload.data,
          homeworkList: action.homeworkId
        }
      }

    default:
      return {
        ...state
      }
  }
}
