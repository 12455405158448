/* eslint-disable react/display-name */
import React from 'react'
import { useTable, useFilters, useSortBy, useBlockLayout, useRowSelect } from 'react-table'
import { useSticky } from 'react-table-sticky'
import matchSorter from 'match-sorter'
import styled from 'styled-components'
import { Button, TextField } from '@material-ui/core'
import { FindInPage as ViewIcon, Edit as EditIcon, AddCircle as AddIcon, Close as CancelIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import TicketStatusBadge from './badge-ticket-status'
import ReminderMessageWidget from './widget-reminder-message-2'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      background-color: #fff;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      position: relative;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
})

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function DateColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextField
      type="date"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default function TicketTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'student.name',
        sticky: 'left',
        enableFilter: true,
        width: 220,
        Cell: ({ row }) => {
          const rowId = row.values._id
          const studentEmail = row.values['student.email']
          return (
            <div className="d-flex align-items-center">
              <span className="pointer mr-2" onClick={props.openOrderPage(studentEmail)}>
                {row.values['student.name']}
              </span>
              <EditIcon className="pointer" color="primary" onClick={props.openUpdateStudentDialog(rowId)} />
            </div>
          )
        }
      },
      {
        Header: 'Email',
        width: 250,
        accessor: 'student.email',
        enableFilter: true
      },
      {
        Header: 'Class',
        width: 300,
        accessor: 'class.name',
        enableFilter: true
      },
      {
        Header: 'Register Date',
        width: 200,
        accessor: 'created',
        enableFilter: true,
        Filter: DateColumnFilter,
        Cell: ({ row }) => moment(row.values['created']).format('ll')
      },
      {
        Header: 'Trial Date',
        width: 200,
        accessor: 'trial_date',
        enableFilter: true,
        Filter: DateColumnFilter,
        Cell: ({ row }) => moment(row.values['trial_date']).format('lll')
      },
      {
        Header: 'Followup Date',
        width: 200,
        accessor: 'next_action_date',
        enableFilter: true,
        Filter: DateColumnFilter,
        Cell: ({ row }) => {
          const followUpDate = row.values['next_action_date']
          if (followUpDate === 'N/A') {
            return <span>N/A</span>
          } else {
            return <span>{moment(row.values['next_action_date']).format('lll')}</span>
          }
        }
      },
      {
        Header: 'Language',
        enableFilter: true,
        accessor: 'student.first_language'
      },
      {
        Header: 'Tutor Comment',
        enableFilter: true,
        accessor: 'tutor_commented'
      },
      {
        Header: 'Source',
        accessor: 'meta.lead_source',
        enableFilter: true,
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          const leadSource = row.values['meta.lead_source']
          const rowId = row.values._id
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">{leadSource}</span>
              {leadSource === 'N/A' ? (
                <AddIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'lead_source')}
                />
              ) : (
                <EditIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'lead_source')}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: 'Handled By',
        accessor: 'handled_by.name',
        enableFilter: true,
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          const handledBy = row.values['handled_by.name']
          const rowId = row.values._id
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">{handledBy}</span>
              {handledBy === 'N/A' ? (
                <AddIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'handled_by')}
                />
              ) : (
                <EditIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'handled_by')}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: 'Attended',
        accessor: 'attended',
        enableFilter: true,
        Filter: SelectColumnFilter
      },
      {
        Header: 'Status',
        accessor: 'status',
        sticky: 'right',
        width: 180,
        enableFilter: true,
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          const ticketStatus = row.values.status
          const rowId = row.values._id

          return (
            <>
              <div onClick={props.openQuickUpdateDialog(rowId, 'status')}>
                <TicketStatusBadge status={ticketStatus} />
              </div>
              {ticketStatus === 'TRIAL REGISTERED' && (
                <ReminderMessageWidget ticketId={rowId} clickDoneReminder={props.clickDoneReminder(rowId)} />
              )}
            </>
          )
        }
      },
      {
        Header: 'Action',
        accessor: '_id',
        sticky: 'right',
        enableFilter: false,
        width: 120,
        Cell: ({ row }) => {
          const rowId = row.values._id
          return (
            <>
              <ViewIcon className="pointer mr-2" color="primary" onClick={props.openViewDetailDialog(rowId)} />
              <EditIcon
                className="pointer mr-2"
                color="primary"
                onClick={props.openQuickUpdateDialog(rowId, 'status')}
              />
              <CancelIcon className="pointer text-danger" onClick={props.openQuickUpdateDialog(rowId, 'cancel')} />
            </>
          )
        }
      }
    ],
    [props]
  )

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const { data } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setAllFilters,
    setSortBy,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: false,
      autoResetFilters: false
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 40,
          sticky: 'left',
          Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
          Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        },
        ...columns
      ])
    }
  )

  return (
    <Styles>
      <div className="d-flex align-items-center mb-2">
        {data.length === rows.length ? (
          <span>Showing all {data.length} records</span>
        ) : (
          <span>
            Showing {rows.length} out of {data.length} records
          </span>
        )}
        <Button
          color="primary"
          variant="text"
          className="ml-2"
          onClick={() => {
            setAllFilters([])
            setSortBy([{ id: 'created', desc: false }])
          }}>
          Reset Table Filter
        </Button>

        <Button
          color="primary"
          variant="outlined"
          className="ml-2"
          onClick={() => {
            const ticketIds = _.map(selectedFlatRows, 'values._id')
            if (ticketIds.length > 0) {
              props.openBatchUpdateDialog(ticketIds)()
            }
          }}>
          Batch Update
        </Button>
      </div>

      <table {...getTableProps()} className="table sticky">
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>
                  <span {...column.getHeaderProps(column.getSortByToggleProps())} style={{ boxShadow: 'none' }}>
                    {column.render('Header')}
                  </span>
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  <div>{column.enableFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="body">
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  // eslint-disable-next-line react/jsx-key
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Styles>
  )
}
