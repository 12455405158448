import * as Type from 'constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_FEEDBACK:
      return {
        ...state,
        inProgress: true,
        feedbackList: null
      }
    case Type.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        inProgress: false,
        feedbackList: action.payload.data,
        feedbackCount: action.payload.count
      }
    case Type.GET_FEEDBACK_FAILED:
      return {
        ...state,
        inProgress: false,
        feedbackList: [],
        feedbackCount: 0
      }

    case Type.GET_USER_FEEDBACK_SUCCESS: {
      let userFeedbackCount = state.userFeedbackCount ? state.userFeedbackCount : 0
      let userFeedback = state.userFeedback ? state.userFeedback : {}
      let feedback = action.payload.data.length > 0 ? action.payload.data[0] : ''
      userFeedback[action.user_id] = feedback
      userFeedbackCount += 1

      return {
        ...state,
        userFeedback,
        userFeedbackCount
      }
    }

    default:
      return state
  }
}
