import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import React from 'react'
import { store, history } from 'store'

import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import App from 'pages/App'
import Login from 'pages/Login'

import 'styles/bootstrap.css'
import 'styles/app.css'
import 'styles/app-rtl.css'
import 'styles/vendors/material-design-iconic-font/css/material-design-iconic-font.css'
import 'styles/SideNav.css'

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="app-main">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={App} />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app-site')
)
