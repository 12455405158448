import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import agent from 'agent'
import { CHANGE_PASSWORD } from 'constants/actionTypes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  changingPassword: state.auth.changingPassword
})

const mapDispatchToProps = dispatch => ({
  changePassword: password => dispatch({ type: CHANGE_PASSWORD, payload: agent.Auth.changePassword({ password }) })
})

class Profile extends React.Component {
  state = {
    alertMessage: '',
    showPassword: false,
    newPassword: '',
    confirmNewPassword: '',
    formError: {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changingPassword && !this.props.changingPassword) {
      this.setState({
        alertMessage: 'Password changed successfully. Please login with new password next time.',
        showPassword: false,
        newPassword: '',
        confirmNewPassword: '',
        formError: {}
      })
    }
  }

  handleChange = e => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    let { formError } = this.state
    if (formError[fieldName]) {
      delete formError[fieldName]
    }

    this.setState({
      formError,
      [fieldName]: fieldValue
    })
  }

  togglePasswordVisibility = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  clickChangePassword = () => {
    const { newPassword, confirmNewPassword } = this.state
    let validationResult = this.validatePassword()

    if (validationResult) {
      this.setState({ formError: { newPassword: validationResult } })
      return
    }

    if (newPassword !== confirmNewPassword) {
      this.setState({ formError: { confirmNewPassword: 'Password that you have typed did not match' } })
      return
    }

    this.props.changePassword(newPassword)
  }

  validatePassword = () => {
    const { newPassword } = this.state

    // NOTE: if want to force special character
    //const strongPasswordRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    const passwordStrength = new RegExp('^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))).*$', 'g')
    let result = ''
    if (newPassword.length === 0) {
      result = 'Fill in your new password'
    } else if (!passwordStrength.test(newPassword)) {
      result = 'Password need at least 8 characters, a lowercase letter, a capital letter & a number'
    }
    return result
  }

  render() {
    const { currentUser, changingPassword } = this.props
    const { alertMessage, showPassword, newPassword, confirmNewPassword, formError } = this.state

    if (!currentUser) {
      return null
    }

    return (
      <div className="app-wrapper">
        <div className="row">
          <div className="col-7">
            <Paper className="p-3">
              <h2>Profile</h2>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{currentUser.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{currentUser.email}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
          <div className="col-5">
            <Paper className="p-3">
              <h2>Change Password</h2>
              {alertMessage && (
                <Alert severity="success" className="mb-3">
                  {alertMessage}
                </Alert>
              )}
              <FormControl className="w-100 mb-3" variant="outlined">
                <InputLabel>New Password</InputLabel>
                <OutlinedInput
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                        edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={110}
                  fullWidth={true}
                  error={!!formError['newPassword']}
                />
                {formError.newPassword && <FormHelperText error={true}>{formError.newPassword}</FormHelperText>}
              </FormControl>

              <FormControl className="w-100 mb-3" variant="outlined">
                <InputLabel>Confirm New Password</InputLabel>
                <OutlinedInput
                  name="confirmNewPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmNewPassword}
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                        edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={170}
                  fullWidth={true}
                  error={!!formError['confirmNewPassword']}
                />
                {formError.confirmNewPassword && (
                  <FormHelperText error={true}>{formError.confirmNewPassword}</FormHelperText>
                )}
              </FormControl>

              <Button
                onClick={this.clickChangePassword}
                color="primary"
                variant="contained"
                disabled={changingPassword}>
                Change Password {changingPassword && <CircularProgress size={15} color="primary" />}
              </Button>
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
