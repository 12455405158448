import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import Proptypes from 'prop-types'

import agent from 'agent'
import { UPDATE_TICKET } from 'constants/actionTypes'

const TRIAL_TICKET_STATUS = [
  'PRE TRIAL FOLLOWED UP',
  'POST TRIAL FOLLOWED UP',
  'PENDING DECISION',
  'PENDING PAYMENT',
  'CLOSED WON',
  'CLOSED LOSE',
  'CANCELED'
]

const RENEWAL_TICKET_STATUS = ['FOLLOWED UP', 'PENDING DECISION', 'PENDING PAYMENT', 'RENEWED', 'DROPPED', 'CANCELED']

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  agentList: state.user.agentList,
  updatingTicket: state.ticket.updatingTicket
})

const mapDispatchToProps = dispatch => ({
  updateTicket: (type, ticketId, reqPayload) =>
    dispatch({ type: UPDATE_TICKET, payload: agent.Ticket.updateById(type, ticketId, reqPayload), ticketId })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogUpdateTicket extends React.Component {
  state = {
    alertMessage: '',
    assignTo: '',
    leadSource: '',
    remark: '',
    status: '',
    nextActionDate: '',
    prevRemark: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { ticketData } = this.props
      if (ticketData?.handled_by) {
        this.setState({ assignTo: ticketData.handled_by._id })
      }

      if (ticketData.meta?.lead_source) {
        this.setState({ leadSource: ticketData.meta.lead_source })
      }

      this.setState({ prevRemark: ticketData.remark ?? 'N/A' })
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        alertMessage: '',
        assignTo: '',
        leadSource: '',
        remark: '',
        status: '',
        nextActionDate: '',
        prevRemark: ''
      })
    }

    if (prevProps.updatingTicket && !this.props.updatingTicket) {
      if (this.props.isOpen) {
        this.props.close()
      }
    }
  }

  handleChange = e => {
    this.setState({
      alertMessage: '',
      [e.target.name]: e.target.value
    })
  }

  updateTicket = () => {
    const { agentList, currentUser, ticketData, updateField } = this.props
    const { assignTo, leadSource, remark, status, nextActionDate, prevRemark } = this.state
    let ticketType = 'trial'
    if (ticketData?._type === 'ClassRenewal') {
      ticketType = 'renewal'
    }

    let reqPayload = {}

    if (updateField === 'handled_by') {
      if (!assignTo) {
        this.setState({ alertMessage: 'Please select a person to assign' })
        return
      }

      let agentData = _.find(agentList, o => {
        return o._id === assignTo
      })
      reqPayload['handled_by'] = assignTo
      reqPayload['action'] = `Ticket assigned to ${agentData?.name} by ${currentUser.name}`
    } else if (updateField === 'lead_source') {
      if (!leadSource) {
        this.setState({ alertMessage: 'Please select an option' })
        return
      }

      reqPayload['meta'] = {
        ...ticketData.meta,
        lead_source: leadSource
      }
      reqPayload['action'] = `Lead sourced set to ${leadSource} by ${currentUser.name}`
    } else if (updateField === 'cancel') {
      if (!remark) {
        this.setState({ alertMessage: 'Please provide reason to cancel ticket' })
        return
      }

      reqPayload['status'] = 'CANCELED'
      reqPayload['remark'] = remark
      reqPayload['prev_remark'] = prevRemark
      reqPayload['action'] = `Ticket canceled by ${currentUser.name}`
    } else if (updateField === 'status') {
      if (!remark) {
        this.setState({ alertMessage: 'Please provide remark for this ticket update' })
        return
      }

      let isFollowupDateAvailable = moment(nextActionDate).isValid()
      if (isFollowupDateAvailable) {
        reqPayload['next_action_date'] = moment(nextActionDate).toISOString()
      }
      if (status) {
        reqPayload['status'] = status
        reqPayload['action'] = `Ticket ${
          status ? `status updated to ${status}${isFollowupDateAvailable ? ' and ' : ''}` : ''
        }${isFollowupDateAvailable ? `next follow up on ${moment(nextActionDate).format('ll')}` : ''} by ${
          currentUser.name
        }`
      } else {
        reqPayload['action'] = `Ticket remark updated ${
          isFollowupDateAvailable ? `, next follow up on ${moment(nextActionDate).format('ll')}` : ''
        } by ${currentUser.name}`
      }
      reqPayload['remark'] = remark
      reqPayload['prev_remark'] = prevRemark
    }

    this.props.updateTicket(ticketType, ticketData._id, reqPayload)
  }

  render() {
    const { isOpen, close, ticketData, updateField, updatingTicket, agentList, LEAD_SOURCE } = this.props
    const { alertMessage, assignTo, leadSource, remark, status, nextActionDate } = this.state

    let dialogTitle = 'Update Ticket'
    if (updateField === 'handled_by') {
      dialogTitle = 'Assign Ticket'
    } else if (updateField === 'lead_source') {
      dialogTitle = 'Update Ticket Source'
    } else if (updateField === 'cancel') {
      dialogTitle = 'Cancel Ticket'
    }

    let TICKET_STATUS = TRIAL_TICKET_STATUS
    if (ticketData?._type === 'ClassRenewal') {
      TICKET_STATUS = RENEWAL_TICKET_STATUS
    }

    return (
      <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="sm" fullWidth>
        <DialogTitle>
          <span style={{ fontSize: '24px' }}>{dialogTitle}</span>
        </DialogTitle>
        <DialogContent>
          {ticketData && (
            <div className="row justify-content-center">
              <div className="col-12 mb-3">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Student</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {ticketData.student.name} ({ticketData.student.email ?? '---'})
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Class</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {ticketData.class.name}&nbsp;
                        {ticketData._type === 'TrialConversion' && <>- Lesson {ticketData.lesson.meta.index}</>}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="col-12">
                {updateField === 'handled_by' && (
                  <FormControl className="w-100 mb-3">
                    <InputLabel htmlFor="assignTo">Assign To</InputLabel>
                    <Select name="assignTo" value={assignTo} onChange={this.handleChange}>
                      {agentList.map(agent => {
                        if (!agent.enabled) {
                          return null
                        }

                        return (
                          <MenuItem key={agent._id} value={agent._id}>
                            {agent.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}

                {updateField === 'lead_source' && (
                  <FormControl className="w-100 mb-3">
                    <FormLabel component="legend">Lead Source</FormLabel>
                    <RadioGroup name="leadSource" value={leadSource} onChange={this.handleChange} row>
                      {LEAD_SOURCE.map((source, index) => (
                        <FormControlLabel key={index} value={source} control={<Radio />} label={source} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}

                {updateField === 'status' && (
                  <>
                    <FormControl className="w-100 mb-3">
                      <InputLabel htmlFor="next_status">Status</InputLabel>
                      <Select name="status" value={status} onChange={this.handleChange}>
                        {TICKET_STATUS.map((status, index) => (
                          <MenuItem value={status} key={index}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className="w-100 mb-3">
                      <TextField
                        name="nextActionDate"
                        label="Follow up date"
                        type="datetime-local"
                        onChange={this.handleChange}
                        value={nextActionDate}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                  </>
                )}

                {['cancel', 'status'].includes(updateField) && (
                  <FormControl className="w-100 mb-3">
                    <TextField
                      label="Remark"
                      name="remark"
                      onChange={this.handleChange}
                      value={remark}
                      variant="outlined"
                      multiline
                      rows={4}
                      error={!!alertMessage}
                      helperText={alertMessage}
                    />
                  </FormControl>
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" variant="text">
            Close
          </Button>
          <Button onClick={this.updateTicket} color="primary" variant="contained" disabled={updatingTicket}>
            Update {updatingTicket && <CircularProgress size={15} className="text-white" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUpdateTicket.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  ticketData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUpdateTicket)
