import React from 'react'

class AppModuleHeader extends React.Component {
  constructor() {
    super()
    this.state = {
      anchorEl: undefined,
      popoverOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  render() {
    const { placeholder, onSearch, onChange, value } = this.props

    return (
      <div className="module-box-header-inner">
        <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
          <form onSubmit={onSearch}>
            <div className="form-group">
              <input
                className="form-control border-0"
                type="search"
                placeholder={placeholder}
                onChange={onChange}
                value={value}
              />
              <button className="search-icon">
                <i className="zmdi zmdi-search zmdi-hc-lg" />
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default AppModuleHeader

AppModuleHeader.defaultProps = {
  styleName: '',
  value: '',
  notification: true,
  apps: true
}
