export const LOAD_APP = 'LOAD_APP'
export const LOAD_APP_SUCCESS = 'LOAD_APP_SUCCESS'
export const LOAD_APP_FAILED = 'LOAD_APP_FAILED'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`
export const CHANGE_PASSWORD_FAILED = `${CHANGE_PASSWORD}_FAILED`

export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = `${CREATE_ORDER}_SUCCESS`
export const CREATE_ORDER_FAILED = `${CREATE_ORDER}_FAILED`

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = `${UPDATE_ORDER}_SUCCESS`
export const UPDATE_ORDER_FAILED = `${UPDATE_ORDER}_FAILED`

export const CANCEL_ORDER = `CANCEL_ORDER`
export const CANCEL_ORDER_SUCCESS = `${CANCEL_ORDER}_SUCCESS`
export const CANCEL_ORDER_FAILED = `${CANCEL_ORDER}_FAILED`

export const GET_USER_ORDERS = 'GET_USER_ORDERS'
export const GET_USER_ORDERS_SUCCESS = `${GET_USER_ORDERS}_SUCCESS`
export const GET_USER_ORDERS_FAILED = `${GET_USER_ORDERS}_FAILED`

export const GET_DISCOUNT_CODES = 'GET_DISCOUNT_CODES'
export const GET_DISCOUNT_CODES_SUCCESS = 'GET_DISCOUNT_CODES_SUCCESS'
export const GET_DISCOUNT_CODES_FAILED = 'GET_DISCOUNT_CODES_FAIlED'

export const CREATE_DISCOUNT_CODE = 'CREATE_DISCOUNT_CODE'
export const CREATE_DISCOUNT_CODE_SUCCESS = 'CREATE_DISCOUNT_CODE_SUCCESS'
export const CREATE_DISCOUNT_CODE_FAILED = 'CREATE_DISCOUNT_CODE_FAILED'

export const GET_DISCOUNT_CODE_BY_ID = 'GET_DISCOUNT_CODE_BY_ID'
export const GET_DISCOUNT_CODE_BY_ID_SUCCESS = 'GET_DISCOUNT_CODE_BY_ID_SUCCESS'
export const GET_DISCOUNT_CODE_BY_ID_FAILED = 'GET_DISCOUNT_CODE_BY_ID_FAILED'

export const UPDATE_DISCOUNT_CODE = 'UPDATE_DISCOUNT_CODE'
export const UPDATE_DISCOUNT_CODE_SUCCESS = 'UPDATE_DISCOUNT_CODE_SUCCESS'
export const UPDATE_DISCOUNT_CODE_FAILED = 'UPDATE_DISCOUNT_CODE_FAILED'

export const DISABLE_DISCOUNT_CODE = 'DISABLE_DISCOUNT_CODE'
export const DISABLE_DISCOUNT_CODE_SUCCESS = 'DISABLE_DISCOUNT_CODE_SUCCESS'
export const DISABLE_DISCOUNT_CODE_FAILED = 'DISABLE_DISCOUNT_CODE_FAILED'

export const ENABLE_DISCOUNT_CODE = 'ENABLE_DISCOUNT_CODE'
export const ENABLE_DISCOUNT_CODE_SUCCESS = 'ENABLE_DISCOUNT_CODE_SUCCESS'
export const ENABLE_DISCOUNT_CODE_FAILED = 'ENABLE_DISCOUNT_CODE_FAILED'

export const LOAD_BY_PAGE = 'LOAD_BY_PAGE'
export const LOAD_BY_PAGE_SUCCESS = 'LOAD_BY_PAGE_SUCCESS'
export const LOAD_BY_PAGE_FAILED = 'LOAD_BY_PAGE_FAILED'

export const GET_CLASSES = 'GET_CLASSES'
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS'
export const GET_CLASSES_FAILED = 'GET_CLASSES_FAILED'

export const CREATE_CLASS = 'CREATE_CLASS'
export const CREATE_CLASS_SUCCESS = `${CREATE_CLASS}_SUCCESS`
export const CREATE_CLASS_FAILED = `${CREATE_CLASS}_FAILED`

export const EDIT_CLASS = 'EDIT_CLASS'
export const EDIT_CLASS_SUCCESS = `${EDIT_CLASS}_SUCCESS`
export const EDIT_CLASS_FAILED = `${EDIT_CLASS}_FAILED`

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = `${CREATE_LESSON}_SUCCESS`
export const CREATE_LESSON_FAILED = `${CREATE_LESSON}_FAILED`

export const GET_LESSONS = 'GET_LESSONS'
export const GET_LESSONS_SUCCESS = `${GET_LESSONS}_SUCCESS`
export const GET_LESSONS_FAILED = `${GET_LESSONS}_FAILED`

export const GET_LESSON_COUNT = 'GET_LESSON_COUNT'
export const GET_LESSON_COUNT_SUCCESS = `${GET_LESSON_COUNT}_SUCCESS`
export const GET_LESSON_COUNT_FAILED = `${GET_LESSON_COUNT}_FAILED`

export const GET_LESSON_BATCH = 'GET_LESSON_BATCH'
export const GET_LESSON_BATCH_SUCCESS = `${GET_LESSON_BATCH}_SUCCESS`
export const GET_LESSON_BATCH_FAILED = `${GET_LESSON_BATCH}_FAILED`

export const GET_LESSON_STUDENT_DETAIL = 'GET_LESSON_STUDENT_DETAIL'
export const GET_LESSON_STUDENT_DETAIL_SUCCESS = `${GET_LESSON_STUDENT_DETAIL}_SUCCESS`
export const GET_LESSON_STUDENT_DETAIL_FAILED = `${GET_LESSON_STUDENT_DETAIL}_FAILED`

export const GET_LESSON_BY_ID = `GET_LESSON_BY_ID`
export const GET_LESSON_BY_ID_SUCCESS = `${GET_LESSON_BY_ID}_SUCCESS`
export const GET_LESSON_BY_ID_FAILED = `${GET_LESSON_BY_ID}_FAILED`

export const EDIT_LESSON = `EDIT_LESSON`
export const EDIT_LESSON_SUCCESS = `${EDIT_LESSON}_SUCCESS`
export const EDIT_LESSON_FAILED = `${EDIT_LESSON}_FAILED`

export const DELETE_LESSON = `DELETE_LESSON`
export const DELETE_LESSON_SUCCESS = `${DELETE_LESSON}_SUCCESS`
export const DELETE_LESSON_FAILED = `${DELETE_LESSON}_FAILED`

export const MAKE_ANNOUNCEMENT = `MAKE_ANNOUNCEMENT`
export const MAKE_ANNOUNCEMENT_SUCCESS = `${MAKE_ANNOUNCEMENT}_SUCCESS`
export const MAKE_ANNOUNCEMENT_FAILED = `${MAKE_ANNOUNCEMENT}_FAILED`

export const GET_FEEDBACK = `GET_FEEDBACK`
export const GET_FEEDBACK_SUCCESS = `${GET_FEEDBACK}_SUCCESS`
export const GET_FEEDBACK_FAILED = `${GET_FEEDBACK}_FAILED`

export const GET_USER_FEEDBACK = 'GET_USER_FEEDBACK'
export const GET_USER_FEEDBACK_SUCCESS = `${GET_USER_FEEDBACK}_SUCCESS`
export const GET_USER_FEEDBACK_FAILED = `${GET_USER_FEEDBACK}_FAILED`

export const CREATE_LESSON_POLL = 'CREATE_LESSON_POLL'
export const CREATE_LESSON_POLL_SUCCESS = `${CREATE_LESSON_POLL}_SUCCESS`
export const CREATE_LESSON_POLL_FAILED = `${CREATE_LESSON_POLL}_FAILED`

export const EDIT_LESSON_POLL = 'EDIT_LESSON_POLL'
export const EDIT_LESSON_POLL_SUCCESS = `${EDIT_LESSON_POLL}_SUCCESS`
export const EDIT_LESSON_POLL_FAILED = `${EDIT_LESSON_POLL}_FAILED`

export const DELETE_LESSON_POLL = 'DELETE_LESSON_POLL'
export const DELETE_LESSON_POLL_SUCCESS = `${DELETE_LESSON_POLL}_SUCCESS`
export const DELETE_LESSON_POLL_FAILED = `${DELETE_LESSON_POLL}_FAILED`

export const REFRESH_LESSON = 'REFRESH_LESSON'
export const REFRESH_LESSON_SUCCESS = `${REFRESH_LESSON}_SUCCESS`
export const REFRESH_LESSON_FAILED = `${REFRESH_LESSON}_FAILED`

// NOTE: use on quick lesson management page, shall merge both lesson management
export const GET_LESSON_BY_ID_2 = 'GET_LESSON_BY_ID_2'
export const GET_LESSON_BY_ID_2_SUCCESS = `${GET_LESSON_BY_ID_2}_SUCCESS`
export const GET_LESSON_BY_ID_2_FAILED = `${GET_LESSON_BY_ID_2}_FAILED`

export const GET_USER_LIVE_LESSON_LINK = 'GET_USER_LIVE_LESSON_LINK'
export const GET_USER_LIVE_LESSON_LINK_SUCCESS = `${GET_USER_LIVE_LESSON_LINK}_SUCCESS`
export const GET_USER_LIVE_LESSON_LINK_FAILED = `${GET_USER_LIVE_LESSON_LINK}_FAILED`

export const GET_EVENT_LIST = 'GET_EVENT_LIST'
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS'
export const GET_EVENT_LIST_FAILED = 'GET_EVENT_LIST_FAILED'

export const CREATE_EVENT = 'CREATE_EVENT'
export const CREATE_EVENT_SUCCESS = `${CREATE_EVENT}_SUCCESS`
export const CREATE_EVENT_FAILED = `${CREATE_EVENT}_FAILED`

export const EDIT_EVENT = `EDIT_EVENT`
export const EDIT_EVENT_SUCCESS = `${EDIT_EVENT}_SUCCESS`
export const EDIT_EVENT_FAILED = `${EDIT_EVENT}_FAILED`

export const DELETE_EVENT = `DELETE_EVENT`
export const DELETE_EVENT_SUCCESS = `${DELETE_EVENT}_SUCCESS`
export const DELETE_EVENT_FAILED = `${DELETE_EVENT}_FAILED`

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_AGENT_LIST = 'GET_AGENT_LIST'
export const GET_AGENT_LIST_SUCCESS = `${GET_AGENT_LIST}_SUCCESS`

export const GET_USER_CREDIT = 'GET_USER_CREDIT'
export const GET_USER_CREDIT_SUCCESS = `${GET_USER_CREDIT}_SUCCESS`
export const GET_USER_CREDIT_FAILED = `${GET_USER_CREDIT}_FAILED`

export const CREATE_USER = `CREATE_USER`
export const CREATE_USER_SUCCESS = `${CREATE_USER}_SUCCESS`
export const CREATE_USER_FAILED = `${CREATE_USER}_FAILED`

export const UPDATE_USER = `UPDATE_USER`
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`
export const UPDATE_USER_FAILED = `${UPDATE_USER}_FAILED`

export const ADD_USER_CREDIT = 'ADD_USER_CREDIT'
export const ADD_USER_CREDIT_SUCCESS = `${ADD_USER_CREDIT}_SUCCESS`
export const ADD_USER_CREDIT_FAILED = `${ADD_USER_CREDIT}_FAILED`

export const GET_HOMEWORK_LIST = `GET_HOMEWORK_LIST`
export const GET_HOMEWORK_LIST_SUCCESS = `${GET_HOMEWORK_LIST}_SUCCESS`
export const GET_HOMEWORK_LIST_FAILED = `${GET_HOMEWORK_LIST}_FAILED`

export const GET_SUBMISSION_STAT = `GET_SUBMISSION_STAT`
export const GET_SUBMISSION_STAT_SUCCESS = `${GET_SUBMISSION_STAT}_SUCCESS`
export const GET_SUBMISSION_STAT_FAILED = `${GET_SUBMISSION_STAT}_FAILED`

export const GET_SUBMISSION_LIST = `GET_SUBMISSION_LIST`
export const GET_SUBMISSION_LIST_SUCCESS = `${GET_SUBMISSION_LIST}_SUCCESS`
export const GET_SUBMISSION_LIST_FAILED = `${GET_SUBMISSION_LIST}_FAILED`

export const GET_PENDING_SUBMISSION_LIST = `GET_PENDING_SUBMISSION_LIST`
export const GET_PENDING_SUBMISSION_LIST_SUCCESS = `${GET_PENDING_SUBMISSION_LIST}_SUCCESS`
export const GET_PENDING_SUBMISSION_LIST_FAILED = `${GET_PENDING_SUBMISSION_LIST}_FAILED`

export const GET_CLASS_ENROLMENT = 'GET_CLASS_ENROLMENT'
export const GET_CLASS_ENROLMENT_SUCCESS = `${GET_CLASS_ENROLMENT}_SUCCESS`
export const GET_CLASS_ENROLMENT_FAILED = `${GET_CLASS_ENROLMENT}_FAILED`

export const GET_TRIAL_STAT = 'GET_TRIAL_STAT'
export const GET_TRIAL_STAT_SUCCESS = `${GET_TRIAL_STAT}_SUCCESS`
export const GET_TRIAL_STAT_FAILED = `${GET_TRIAL_STAT}_FAILED`

export const GET_HOMEWORK_ASSIGNMENT_STAT = 'GET_HOMEWORK_ASSIGNMENT_STAT'
export const GET_HOMEWORK_ASSIGNMENT_STAT_SUCCESS = `${GET_HOMEWORK_ASSIGNMENT_STAT}_SUCCESS`
export const GET_HOMEWORK_ASSIGNMENT_STAT_FAILED = `${GET_HOMEWORK_ASSIGNMENT_STAT}_FAILED`

export const GET_TICKET_LIST = 'GET_TICKET_LIST'
export const GET_TICKET_LIST_SUCCESS = `${GET_TICKET_LIST}_SUCCESS`
export const GET_TICKET_LIST_FAILED = `${GET_TICKET_LIST}_FAILED`

export const GET_TICKET_LESSON_DETAIL = 'GET_TICKET_LESSON_DETAIL'
export const GET_TICKET_LESSON_DETAIL_SUCCESS = `${GET_TICKET_LESSON_DETAIL}_SUCCESS`
export const GET_TICKET_LESSON_DETAIL_FAILED = `${GET_TICKET_LESSON_DETAIL}_FAILED`

export const UPDATE_TICKET = 'UPDATE_TICKET'
export const UPDATE_TICKET_SUCCESS = `${UPDATE_TICKET}_SUCCESS`
export const UPDATE_TICKET_FAILED = `${UPDATE_TICKET}_FAILED`

export const BATCH_UPDATE_TICKET = 'BATCH_UPDATE_TICKET'
export const BATCH_UPDATE_TICKET_SUCCESS = `${BATCH_UPDATE_TICKET}_SUCCESS`
export const BATCH_UPDATE_TICKET_FAILED = `${BATCH_UPDATE_TICKET}_FAILED`

export const GET_BIRTHDAY_STUDENT = 'GET_BIRTHDAY_STUDENTS'
export const GET_BIRTHDAY_STUDENT_SUCCESS = `${GET_BIRTHDAY_STUDENT}_SUCCESS`
export const GET_BIRTHDAY_STUDENT_FAILED = `${GET_BIRTHDAY_STUDENT}_FAILED`

export const GET_SALES_STAT = 'GET_SALES_STAT'
export const GET_SALES_STAT_SUCCESS = `${GET_SALES_STAT}_SUCCESS`
export const GET_SALES_STAT_FAILED = `${GET_SALES_STAT}_FAILED`

export const GET_TICKETS_STAT = 'GET_TICKETS_STAT'
export const GET_TICKETS_STAT_SUCCESS = `${GET_TICKETS_STAT}_SUCCESS`
export const GET_TICKETS_STAT_FAILED = `${GET_TICKETS_STAT}_FAILED`

export const GET_QUESTION_LIST = 'GET_QUESTION_LIST'
export const GET_QUESTION_LIST_SUCCESS = `${GET_QUESTION_LIST}_SUCCESS`
export const GET_QUESTION_LIST_FAILED = `${GET_QUESTION_LIST}_FAILED`

export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = `${CREATE_QUESTION}_SUCCESS`
export const CREATE_QUESTION_FAILED = `${CREATE_QUESTION}_FAILED`

export const TAG_QUESTION = 'TAG_QUESTION'
export const TAG_QUESTION_SUCCESS = `${TAG_QUESTION}_SUCCESS`
export const TAG_QUESTION_FAILED = `${TAG_QUESTION}_FAILED`

export const UPLOAD_ALL_MATERIAL_HOMEWORK = 'UPLOAD_ALL_MATERIAL_HOMEWORK'
export const UPLOAD_ALL_MATERIAL_HOMEWORK_SUCCESS = `${UPLOAD_ALL_MATERIAL_HOMEWORK}_SUCCESS`
export const UPLOAD_ALL_MATERIAL_HOMEWORK_FAILED = `${UPLOAD_ALL_MATERIAL_HOMEWORK}_FAILED`

export const REDIRECT = 'REDIRECT'
export const REDIRECT_UNAUTHENTICATED_USER = 'REDIRECT_UNAUTHENTICATED_USER'
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_COLLAPSE_SIDE_NAV = 'TOGGLE_COLLAPSE_SIDE_NAV'
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH'
export const UNLOAD_LESSON = 'UNLOAD_LESSON'
export const SET_ACTIVE_LESSON = 'SET_ACTIVE_LESSON'
