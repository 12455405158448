import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Paper
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_HOMEWORK_ASSIGNMENT_STAT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingHomeworkAssignmentStat: state.report.loadingHomeworkAssignmentStat,
  homeworkAssignmentStat: state.report.homeworkAssignmentStat
})

const mapDispatchToProps = dispatch => ({
  getHomeworkAssignmentStat: reqPayload =>
    dispatch({ type: GET_HOMEWORK_ASSIGNMENT_STAT, payload: agent.Report.getHomeworkAssingment(reqPayload) })
})

class HomeworkAssignmentWidget extends React.Component {
  state = {
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    stat: null
  }

  componentDidMount() {
    this.checkHomeworkAssignmentStat()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingHomeworkAssignmentStat && !this.props.loadingHomeworkAssignmentStat) {
      const { homeworkAssignmentStat } = this.props
      if (homeworkAssignmentStat) {
        let lessonMissOutHomework = _.filter(homeworkAssignmentStat, o => {
          return o.student_without_homework_count > 0
        })
        this.setState({
          stat: lessonMissOutHomework
        })
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  checkHomeworkAssignmentStat = () => {
    const { startDate, endDate } = this.state
    this.props.getHomeworkAssignmentStat({
      date_range: {
        $gte: moment(startDate).startOf('day').toISOString(),
        $lte: moment(endDate).endOf('day').toISOString()
      }
    })
  }

  render() {
    const { loadingHomeworkAssignmentStat } = this.props
    const { startDate, endDate, stat } = this.state

    return (
      <Paper elevation={2}>
        <h2 className="pt-3 px-3">Homework Assignment Stat</h2>
        <div className="d-flex align-items-center manage-margin p-3">
          <TextField
            variant="outlined"
            type="date"
            label="From"
            name="startDate"
            value={startDate}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            type="date"
            label="To"
            name="endDate"
            value={endDate}
            onChange={this.handleChange}
          />

          <Button variant="contained" color="primary" onClick={this.checkHomeworkAssignmentStat}>
            Check
          </Button>
        </div>

        {loadingHomeworkAssignmentStat && (
          <div className="text-center">
            <CircularProgress size={30} color="primary" />
          </div>
        )}

        {!loadingHomeworkAssignmentStat && !stat && (
          <div className="text-center pb-3">All student get their own homework</div>
        )}

        {stat?.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Class</TableCell>
                <TableCell>Lesson</TableCell>
                <TableCell>Student(s) with no homework</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stat.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.class}</TableCell>
                  <TableCell>
                    {row.lesson.meta?.index && `Lesson ${row.lesson.meta?.index}`}&nbsp;
                    {row.lesson.name}
                  </TableCell>
                  <TableCell>{row.student_without_homework_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkAssignmentWidget)
