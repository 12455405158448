import React from 'react'

class Latex extends React.Component {
  constructor() {
    super()
    this.node = React.createRef()
  }

  componentDidMount() {
    this.renderMath()
  }

  componentDidUpdate() {
    this.renderMath()
  }

  renderMath() {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.node.current])
  }

  render() {
    const { children } = this.props

    return <div ref={this.node}>{children}</div>
  }
}

export default Latex
