import React from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import moment from 'moment'
import qs from 'qs'

import agent from 'agent'
import permissions, { checkActionPermitted } from 'permissions'
import { GET_AGENT_LIST, GET_ORDERS, GET_USERS } from 'constants/actionTypes'
import { ROUTE_ORDERS } from 'routes/paths'
import Pagination from 'components/Pagination/Pagination'
import DialogNewOrder from 'pages/Orders/DialogNewOrder'
import OrderItem from 'pages/Orders/OrderItem'
import DialogOrderCSV from 'pages/Orders/DialogOrderCSV'
import DialogCancelConfirmation from 'pages/Orders/DialogCancelOrder'
import DialogUpdate from 'pages/Orders/DialogQuickUpdate'

const mapStateToProps = state => ({
  orderList: state.orders.orderList,
  orderCount: state.orders.orderCount,
  orderPage: state.orders.orderPage,
  users: state.user.userList,
  agentList: state.user.agentList,
  userLoading: state.user.isLoading,
  userRole: state.auth.userRole,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  getOrders: (filter, perPage, page) =>
    dispatch({ type: GET_ORDERS, payload: agent.Orders.getAll(filter, perPage, page), page: page }),
  getUsers: (action, filter, userType, perPage) =>
    dispatch({ type: action, payload: agent.User.getUsers(filter, perPage), userType })
})

const ORDER_STATUS = ['INIT', 'PAID', 'PROCESSED', 'CANCELLED'] // NOTE: take out CANCEL_INIT 1st, cause no usage at the moment
const ORDER_TYPE = ['PAYMENT', 'TRIAL', 'PAYMENT_VIDEO']

class Orders extends React.Component {
  state = {
    perPage: 20,
    page: 1,
    type: 'ALL',
    createOrderDialogOpen: false,
    searchUserBy: 'email',
    searchTerm: '',
    autoSearchUser: false,
    selectedUser: null,
    csvParseOpen: false,
    agentFilter: 'IGNORE',
    statusFilter: ['INIT', 'PROCESSED', 'CANCELLED'],
    typeFilter: ORDER_TYPE,
    startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    paymentMode: 'ALL',
    selectedOrder: '',
    isCancelOrderDialogOpen: false,
    isUpdateOrderDialogOpen: false
  }

  componentDidMount() {
    const searchParams = qs.parse(this.props.location.search.slice(1))
    const searchTerm = searchParams.email ?? ''
    if (searchTerm) {
      this.setState({ searchTerm, autoSearchUser: true })
    } else {
      this.getOrders()
    }

    if (this.props.agentList.length === 0) {
      this.props.getUsers(GET_AGENT_LIST, { _type: 'Agent' }, 'Agent', 30)
    }

    const { userRole } = this.props
    this.canCreateOrder = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canCreateOrder')
    this.canCreateOrderByCSV = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canCreateOrderByCSV')
    this.canViewAllOrders = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canViewAllOrders')
    this.canCancelTrial = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canCancelTrial')
    this.canCancelPurchase = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canCancelPurchase')
    this.canUpdateOrder = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canUpdateOrder')
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getOrders()
    }

    if (prevState.selectedUser !== this.state.selectedUser) {
      this.getOrders(1)
    }

    if (prevState.searchTerm !== this.state.searchTerm) {
      if (this.state.autoSearchUser) {
        this.searchUsers()
      }
      this.setState({ autoSearchUser: false })
    }

    if (prevProps.userLoading && !this.props.userLoading && this.state.searchTerm) {
      const { users } = this.props
      if (!!users && users.length > 0) {
        this.setState({ selectedUser: users })
      } else {
        alert('There are no users with that email')
      }
    }
  }

  searchUsers = () => {
    const { searchUserBy, searchTerm } = this.state
    if (!!searchTerm.trim()) {
      let filter = {}
      let formattedSearchTerm = searchTerm.trim().replaceAll('+', '\\+')
      filter[searchUserBy] = {
        $regex: formattedSearchTerm,
        $options: 'i'
      }

      this.props.getUsers(GET_USERS, filter)
    }
  }

  clearSearch = () => {
    this.setState({ searchTerm: '', selectedUser: null })
  }

  getOrders = setPage => {
    const { currentUser, userRole } = this.props
    const { perPage, page, selectedUser, agentFilter, statusFilter, typeFilter, startDate, endDate, paymentMode } =
      this.state

    if (!!setPage && page !== setPage) {
      this.setState({ page: setPage })
    } else {
      let filter = {}
      // NOTE: split out user search with filter option search
      if (selectedUser) {
        filter.created_by = selectedUser.map(u => u._id).join(',')
      } else {
        filter.type = { $in: typeFilter }
        filter.status = { $in: statusFilter }
        filter.created = {
          $gte: moment(startDate).startOf('day').toISOString(),
          $lte: moment(endDate).endOf('day').toISOString()
        }

        if (agentFilter === 'ALL') {
          filter.agent = { $exists: true }
        } else if (agentFilter === 'NO_AGENT') {
          filter.agent = { $exists: false }
        } else if (agentFilter !== 'IGNORE') {
          filter.agent = agentFilter
        }

        if (paymentMode === 'online') {
          filter.handled_by = { $exists: false }
        } else if (paymentMode === 'offline') {
          filter.handled_by = { $exists: true }
        }
      }

      const canViewAllOrders = checkActionPermitted(permissions, userRole, ROUTE_ORDERS, 'canViewAllOrders')
      if (!canViewAllOrders) {
        // NOTE: temp hard code all agent id from oxkids
        if (currentUser._id === 'rwD3ZIxtTf2yaf0IaON3XQ') {
          filter.agent = {
            $in: [
              'rwD3ZIxtTf2yaf0IaON3XQ',
              'hkjAgLRWQ3q9ElvaCOGkaw',
              'pLom2QTLTUive8Vk5KY1mQ',
              'syggaHBvQOOGup7cWaoFAA',
              'BsgFWf3aScKjO17yjFJLKA',
              '5eSwrY0HTz2H2XvHGtuaIw',
              'ltMnzjUmS2uvalPZKClFSQ',
              'xg0Rqr9hRJa7JWmyiVgxsQ',
              'zSWdF3DxS4-KnLLc79Zw7g',
              'gcuP0r0AS4ekU2X8wk_4FA',
              'Mg--cIhLQfKUD9_2H8H3fg',
              '0OyECVjHTBmHfF4Uc6m6rg',
              'J9P1cpCKRTaJ-E55fTehiw',
              '48Wo1OPTS7q1WftAeL4q3A'
            ]
          }
        } else {
          filter.agent = currentUser._id
        }
      }

      this.props.getOrders(filter, perPage, page)
    }
  }

  advanceFilterSearch = () => {
    this.setState({
      searchTerm: '',
      selectedUser: null
    })
    this.getOrders(1)
  }

  onPageChange = page => {
    this.setState({ page })
  }

  onAgentFilterChange = evt => {
    this.setState({ agentFilter: evt.target.value })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggleCreateOrderDialog = () => {
    this.setState({ createOrderDialogOpen: !this.state.createOrderDialogOpen })
  }

  onSave = () => {
    this.getOrders(1)
    this.toggleCreateOrderDialog()
  }

  toggleCSVparseModal = () => {
    this.setState(prevState => ({ csvParseOpen: !prevState.csvParseOpen }))
  }

  toggleCancelOrderDialog = selectedOrder => () => {
    this.setState({
      isCancelOrderDialogOpen: !this.state.isCancelOrderDialogOpen,
      selectedOrder
    })
  }

  toggleUpdateOrderDialog = selectedOrder => () => {
    this.setState({
      isUpdateOrderDialogOpen: !this.state.isUpdateOrderDialogOpen,
      selectedOrder
    })
  }

  render() {
    const { orderList, orderCount, agentList, currentUser } = this.props
    const {
      perPage,
      page,
      createOrderDialogOpen,
      searchUserBy,
      searchTerm,
      selectedUser,
      csvParseOpen,
      agentFilter,
      statusFilter,
      typeFilter,
      startDate,
      endDate,
      paymentMode,
      selectedOrder,
      isCancelOrderDialogOpen,
      isUpdateOrderDialogOpen
    } = this.state

    return (
      <div className="app-wrapper">
        <div className="row">
          <div className="col">
            <h1>Orders</h1>
          </div>
          {this.canCreateOrder && (
            <div className="col-auto">
              {this.canCreateOrderByCSV && (
                <Button className="mr-2" variant="text" color="primary" onClick={this.toggleCSVparseModal}>
                  Add Orders with CSV
                </Button>
              )}
              {csvParseOpen && <DialogOrderCSV isOpen={csvParseOpen} toggle={this.toggleCSVparseModal} />}
              <Button variant="contained" color="primary" onClick={this.toggleCreateOrderDialog}>
                Add new order
              </Button>
              {createOrderDialogOpen && (
                <DialogNewOrder
                  isOpen={createOrderDialogOpen}
                  toggle={this.toggleCreateOrderDialog}
                  onSave={this.onSave}
                />
              )}
            </div>
          )}
        </div>

        <Paper elevation={2} className="my-2">
          <div className="app-wrapper py-2">
            <div className="row align-items-center">
              <div className="col row align-items-center">
                <div className="font-weight-semibold">
                  {!!selectedUser ? `Orders for ${selectedUser.map(u => u.email).join(', ')}` : 'Quick Search'}
                  {!!selectedUser && (
                    <Button variant="text" color="default" onClick={this.clearSearch}>
                      Clear
                    </Button>
                  )}
                </div>
              </div>
              <div className="col-auto row align-items-center">
                <Select name="searchUserBy" value={searchUserBy} onChange={this.handleChange} label="Search By">
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </Select>
                <TextField name="searchTerm" value={searchTerm} onChange={this.handleChange} />
                <Button color="secondary" variant="text" onClick={this.searchUsers}>
                  Search
                </Button>
              </div>
            </div>
          </div>
        </Paper>

        <Accordion className="mb-2">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <span className="font-weight-semibold">More Filter Options</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-auto mb-2">
                <FormControl variant="outlined">
                  <InputLabel>Order Type</InputLabel>
                  <Select
                    multiple
                    name="typeFilter"
                    value={typeFilter}
                    onChange={this.handleChange}
                    label="Order Type"
                    renderValue={selected => selected.join(', ')}>
                    {ORDER_TYPE.map(otype => (
                      <MenuItem key={otype} value={otype}>
                        <Checkbox checked={typeFilter.indexOf(otype) > -1} />
                        <ListItemText primary={otype} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-auto mb-2">
                <FormControl variant="outlined">
                  <InputLabel>Order Status</InputLabel>
                  <Select
                    multiple
                    name="statusFilter"
                    value={statusFilter}
                    onChange={this.handleChange}
                    label="Order Status"
                    renderValue={selected => selected.join(', ')}>
                    {ORDER_STATUS.map(status => (
                      <MenuItem key={status} value={status}>
                        <Checkbox checked={statusFilter.indexOf(status) > -1} />
                        <ListItemText primary={status} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-auto mb-2">
                <FormControl variant="outlined">
                  <InputLabel>Payment Mode</InputLabel>
                  <Select name="paymentMode" value={paymentMode} onChange={this.handleChange} label="Payment Mode">
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="online">Pay Online</MenuItem>
                    <MenuItem value="offline">Bank Transfer</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {this.canViewAllOrders && (
                <div className="col-auto mb-2">
                  <FormControl variant="outlined">
                    <InputLabel>Agent</InputLabel>
                    <Select name="agentFilter" value={agentFilter} onChange={this.handleChange} label="Agent">
                      <MenuItem value="IGNORE">---</MenuItem>
                      <MenuItem value="NO_AGENT">No Agent</MenuItem>
                      <MenuItem value="ALL">All Agents</MenuItem>
                      {agentList.map((agent, index) => (
                        <MenuItem value={agent._id} key={index}>
                          {agent.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className="col-auto mb-2">
                <TextField
                  variant="outlined"
                  type="date"
                  label="Start date"
                  name="startDate"
                  value={startDate}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-auto mb-2">
                <TextField
                  variant="outlined"
                  type="date"
                  label="End date"
                  name="endDate"
                  value={endDate}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 text-right">
                <Button variant="contained" color="secondary" className="text-right" onClick={this.advanceFilterSearch}>
                  Search
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {!!orderList ? (
          orderList.length > 0 ? (
            <>
              <div className="text-right text-muted">
                Showing&nbsp;
                {orderCount < perPage ? 1 : (page - 1) * perPage + 1}&nbsp; to&nbsp;
                {orderCount < perPage ? orderCount : page * perPage}&nbsp; out of{' '}
                <span className="font-weight-semibold">{orderCount}</span> orders
              </div>
              {orderList.map(order => (
                <OrderItem
                  key={order._id}
                  order={order}
                  triggerCancelOrder={this.toggleCancelOrderDialog(order)}
                  triggerUpdateOrder={this.toggleUpdateOrderDialog(order)}
                  canCancelTrial={this.canCancelTrial}
                  canCancelPurchase={this.canCancelPurchase}
                  canUpdateOrder={this.canUpdateOrder}
                  currentUser={currentUser}
                />
              ))}
            </>
          ) : (
            <div className="font-weight-semibold text-center my-5">There are no orders to display</div>
          )
        ) : (
          <div className="py-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}
        <div className="text-center">
          <Pagination
            itemCount={orderCount ? orderCount : 0}
            onChangePage={this.onPageChange}
            pageSize={perPage}
            currentPage={page}
            className="my-3"
          />
        </div>

        <DialogCancelConfirmation
          isOpen={isCancelOrderDialogOpen}
          close={this.toggleCancelOrderDialog()}
          orderData={selectedOrder}
        />

        <DialogUpdate
          isOpen={isUpdateOrderDialogOpen}
          close={this.toggleUpdateOrderDialog()}
          orderData={selectedOrder}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
