import * as Type from 'constants/actionTypes'
import $ from 'jquery'

const defaultState = {
  loadingApp: false,
  token: null,
  currentUser: null,
  sideNavOpened: false,
  windowWidth: $(window).width()
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.LOAD_APP: {
      return {
        ...state,
        loadingApp: true
      }
    }

    case Type.LOAD_APP_SUCCESS:
      return {
        ...state,
        loadingApp: false,
        token: action.token || null,
        currentUser: action.payload[0].data
      }

    case Type.LOAD_APP_FAILED: {
      window.localStorage.removeItem('jwt')
      return {
        ...state,
        loadingApp: false,
        redirectTo: '/login',
        token: null,
        currentUser: null
      }
    }

    case Type.LOGIN_SUCCESS:
      return {
        ...state,
        redirectTo: '/dashboard',
        token: action.payload.token,
        currentUser: action.payload.data
      }

    case Type.TOGGLE_COLLAPSE_SIDE_NAV:
      return {
        ...state,
        sideNavOpened: action.opened
      }

    case Type.UPDATE_WINDOW_WIDTH:
      return {
        ...state,
        windowWidth: action.width
      }

    case Type.REDIRECT_UNAUTHENTICATED_USER:
      return {
        ...state,
        redirectTo: '/login'
      }

    case Type.REDIRECT:
      return { ...state, redirectTo: null }

    case Type.LOGOUT:
      return {
        redirectTo: '/login',
        token: null,
        currentUser: null
      }

    default:
      return state
  }
}
