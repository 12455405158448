import agent from 'agent'
import * as Type from 'constants/actionTypes'

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    const { payload, ...noPayload } = action // having payload extracts payload from the object so the spread operator only takes the remaining fields in the action object
    store.dispatch(noPayload)
    action.payload.then(
      res => {
        action.type += '_SUCCESS'
        action.payload = res
        store.dispatch(action)
      },
      error => {
        action.error = true
        /*if (error.status === 401) {
          //NOTE: logout user if invalid token
          store.dispatch({ type: Type.LOGOUT });
        } else if (error && error.status === 403) {
          action.type = 'UNAUTHORIZED_ACCESS';
          action.errorCode = 403;
          action.errorMessage = 'Authorized Use Only!';
          store.dispatch({ type: Type.LOGOUT });
        } else {*/
        action.type += '_FAILED'
        //}
        //Temp keep this, need standardize errors message handling
        action.payload = error.message.errors
        store.dispatch(action)
      }
    )

    return
  }

  next(action)
}

const localStorageMiddleware = store => next => action => {
  if (action.error && action.payload && action.payload[0] && action.payload[0].isExpired) {
    store.dispatch({ type: Type.LOGOUT })
    return
  }
  if (action.type === Type.LOGIN_SUCCESS) {
    if (!action.error) {
      if (['Student', 'TA'].includes(action.payload?.data._type)) {
        action.type = Type.LOGIN_FAILED
        action.error = true
        action.payload = {
          code: 'Error',
          errors: [{ type: 'invalid', message: 'Invalid user type' }]
        }
      } else {
        window.localStorage.setItem('jwt', action.payload.token)
        agent.setToken(action.payload.token)
      }
    }
  } else if (action.type === Type.LOGOUT) {
    window.localStorage.setItem('jwt', '')
    agent.setToken(null)
  }

  next(action)
}

function isPromise(v) {
  return v && typeof v.then === 'function'
}

export { promiseMiddleware, localStorageMiddleware }
