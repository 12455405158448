import React from 'react'
import { connect } from 'react-redux'

import {
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  TextField,
  Button
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_HOMEWORK_LIST, GET_SUBMISSION_STAT, GET_CLASSES } from 'constants/actionTypes'
import SubmissionStat from './submission-stat'
import DetailSubmissionStatDialog from './dialog-detail-submission-stat'

const mapStateToProps = state => ({
  loadingHomeworkList: state.homework.loadingHomeworkList,
  homeworkList: state.homework.homeworkList,
  isLoadingClasses: state.classroom.isLoadingClasses,
  classList: state.classroom.classList
})

const mapDispatchToProps = dispatch => ({
  getHomeworkList: (filter, perPage, page) =>
    dispatch({ type: GET_HOMEWORK_LIST, payload: agent.Homework.getList(filter, perPage, page) }),
  getSubmissionStat: homeworkId =>
    dispatch({ type: GET_SUBMISSION_STAT, payload: agent.Submission.getStat({ homework: homeworkId }), homeworkId }),
  getClasses: filter => dispatch({ type: GET_CLASSES, payload: agent.Classroom.getClasses(filter, 20) })
})

class HomeworkTab extends React.Component {
  state = {
    method: 'date',
    fromDate: moment().startOf('week').format('YYYY-MM-DD'),
    toDate: moment().endOf('week').format('YYYY-MM-DD'),
    dateFilter: 'due_on',
    classKeyword: '',
    classMap: [],
    selectedClassId: '',
    isDetailSubmissionStatDialogOpen: false,
    selectedHomeworkId: '',
    selectedStatus: '',
    pageCount: 0,
    currentPage: 1,
    perPage: 10,
    refreshPagination: false
  }

  componentDidMount() {
    if (this.props.activeTab === 'homework-stat') {
      this.search()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingHomeworkList && !this.props.loadingHomeworkList && this.state.refreshPagination) {
      let { perPage } = this.state
      let { homeworkList } = this.props
      let pageCount = Math.ceil(homeworkList.count / perPage)

      this.setState({
        refreshPagination: false,
        pageCount,
        currentPage: 1
      })
    }

    if (prevProps.isLoadingClasses && !this.props.isLoadingClasses) {
      this.setState({
        classMap: this.props.classList
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  search = () => {
    let { classList } = this.props
    let { method, selectedClassId, fromDate, toDate, dateFilter, perPage } = this.state
    let filter = {
      [dateFilter]: {
        $gte: moment(fromDate).startOf('d').toISOString(),
        $lte: moment(toDate).endOf('d').toISOString()
      }
    }

    if (method === 'class') {
      let selectedClass = _.find(classList, o => {
        return o._id === selectedClassId
      })
      let lessonIds = _.map(selectedClass.lesson, '_id')
      filter['lesson'] = {
        $in: lessonIds
      }
    }

    this.setState({ refreshPagination: true })
    this.props.getHomeworkList(filter, perPage, 1)
  }

  toggleDetailSubmissionStatDialog = (homeworkId, status) => () => {
    this.setState({
      isDetailSubmissionStatDialogOpen: !this.state.isDetailSubmissionStatDialogOpen,
      selectedHomeworkId: homeworkId,
      selectedStatus: status
    })
  }

  changePage = (event, page) => {
    let { fromDate, toDate, dateFilter, perPage } = this.state
    let filter = {
      [dateFilter]: {
        $gte: moment(fromDate).startOf('d').toISOString(),
        $lte: moment(toDate).endOf('d').toISOString()
      }
    }
    this.props.getHomeworkList(filter, perPage, page)
    this.setState({ currentPage: page })
  }

  searchClass = () => {
    this.setState({ classMap: [] })
    let { classKeyword } = this.state
    let formattedKeyword = classKeyword.replaceAll('(', '\\(').replaceAll(')', '\\)')
    this.props.getClasses({ name: { $regex: formattedKeyword, $options: 'i' } })
  }

  handleDateRangeSelect = option => () => {
    let fromDate, toDate
    if (option === 1) {
      fromDate = moment().startOf('week').format('YYYY-MM-DD')
      toDate = moment().endOf('week').format('YYYY-MM-DD')
    } else if (option === 2) {
      fromDate = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
      toDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
    } else if (option === 3) {
      fromDate = moment().startOf('month').format('YYYY-MM-DD')
      toDate = moment().endOf('month').format('YYYY-MM-DD')
    } else if (option === 4) {
      fromDate = moment().startOf('year').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')
    }

    this.setState({ fromDate, toDate })
  }

  render() {
    let { activeTab, loadingHomeworkList, homeworkList, isLoadingClasses, classList } = this.props
    let {
      method,
      fromDate,
      toDate,
      dateFilter,
      classKeyword,
      selectedClassId,
      isDetailSubmissionStatDialogOpen,
      selectedHomeworkId,
      selectedStatus,
      pageCount,
      currentPage,
      perPage
    } = this.state

    return (
      <div className="app-wrapper" hidden={activeTab !== 'homework-stat'}>
        <div className="d-flex align-items-center manage-margin mb-3">
          <h3>Filter Method: </h3>
          <RadioGroup name="method" value={method} onChange={this.handleChange} row>
            <FormControlLabel value="date" control={<Radio />} label="Date" />
            <FormControlLabel value="class" control={<Radio />} label="Class" />
          </RadioGroup>
        </div>

        {method === 'class' && (
          <>
            <div className="d-flex align-items-center manage-margin mb-3">
              <TextField
                variant="outlined"
                type="text"
                label="Class Title Keyword"
                name="classKeyword"
                value={classKeyword}
                onChange={this.handleChange}
                style={{ minWidth: '300px' }}
              />

              <Button variant="outlined" color="primary" onClick={this.searchClass}>
                Lookup Class
              </Button>
            </div>

            {isLoadingClasses && <CircularProgress color={'primary'} size={25} />}

            {classList.length === 0 && !isLoadingClasses ? (
              <div className="mb-3 text-muted">No class data yet</div>
            ) : (
              <div className="mb-3">
                <FormControl variant="outlined">
                  <InputLabel>Select a Class</InputLabel>
                  <Select
                    value={selectedClassId}
                    onChange={this.handleChange}
                    name="selectedClassId"
                    label="Select Class"
                    style={{ minWidth: '300px' }}>
                    {classList.map((classData, index) => (
                      <MenuItem value={classData._id} key={index}>
                        {classData.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        )}

        <div className="d-flex align-items-center manage-margin mb-1">
          <TextField
            variant="outlined"
            type="date"
            label="From"
            name="fromDate"
            value={fromDate}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            type="date"
            label="To"
            name="toDate"
            value={toDate}
            onChange={this.handleChange}
          />

          <FormControl variant="outlined">
            <InputLabel>Date Filter</InputLabel>
            <Select value={dateFilter} onChange={this.handleChange} name="dateFilter" label="Date Filter">
              <MenuItem value="released_on">Release Date</MenuItem>
              <MenuItem value="due_on">Due Date</MenuItem>
              <MenuItem value="return_on">Return Date</MenuItem>
            </Select>
          </FormControl>

          <Button variant="contained" color="primary" onClick={this.search}>
            Search
          </Button>
        </div>

        <div className="d-flex">
          <Chip
            size="small"
            label="this week"
            className="bg-blue-gray px-1 mr-1"
            onClick={this.handleDateRangeSelect(1)}
          />

          <Chip
            size="small"
            label="2 weeks"
            className="bg-blue-gray px-1 mr-1"
            onClick={this.handleDateRangeSelect(2)}
          />

          <Chip
            size="small"
            label="this month"
            className="bg-blue-gray px-1 mr-1"
            onClick={this.handleDateRangeSelect(3)}
          />

          <Chip
            size="small"
            label="this year"
            className="bg-blue-gray px-1 mr-1"
            onClick={this.handleDateRangeSelect(4)}
          />
        </div>

        {loadingHomeworkList && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        <div className="text-right text-muted">
          {pageCount > 1 && homeworkList.count > 0 ? (
            <>
              Showing {perPage * (currentPage - 1) + 1} - {perPage * (currentPage - 1) + perPage} out of{' '}
              {homeworkList.count} records
            </>
          ) : (
            <>Showing all {homeworkList.count} records</>
          )}
        </div>

        {!loadingHomeworkList && homeworkList.count === 0 && <div className="my-5 text-center">No homework found!</div>}

        {homeworkList.data.map(homework => {
          let tutorNames = _.map(homework.lesson.tutor, 'name').join(', ')

          let overDue = moment() > moment(homework.due_on)
          let overReturn = moment() > moment(homework.return_on)

          return (
            <Card className="mb-3" key={homework._id}>
              <div className="p-3">
                <h3 className="mb-0">{homework.lesson.class.name}</h3>
              </div>
              <Divider />
              <div className="row p-3">
                <div className="col-6">
                  <span className="text-muted">Lesson: </span>
                  <span>
                    ({homework.lesson.meta.term ? `Term ${homework.lesson.meta.term}, ` : ''}
                    Lesson {homework.lesson.meta.index}) {homework.lesson.name}
                  </span>
                  <br />
                  <span className="text-muted">Homework: </span>
                  <span>
                    <a href={homework.attachment[0].url} target="_blank" rel="noopener noreferrer" download>
                      {homework.name}
                    </a>
                  </span>
                  <br />
                  <span className="text-muted">Tutor: </span>
                  <span>{tutorNames}</span>
                  <br />
                  <span className="text-muted">Assigned to: </span>
                  <span>{homework.student.length} students</span>
                  <br />
                  <span className="text-muted">Student Count: </span>
                  <span>
                    Paid ({homework.lesson.student.length}) Trial ({homework.lesson.trial_student.length})
                  </span>
                </div>
                <div className="col-6">
                  <span className="text-muted">Release Date: </span>
                  <span>{moment(homework.released_on).format('lll')}</span>
                  <br />
                  <span className="text-muted">Due Date: </span>
                  <span className={overDue ? 'text-danger' : ''}>{moment(homework.due_on).format('lll')}</span>
                  <br />
                  <span className="text-muted">Return Date: </span>
                  <span className={overReturn ? 'text-danger' : ''}>{moment(homework.return_on).format('lll')}</span>
                  <br />
                  <SubmissionStat
                    homeworkData={homework}
                    overDue={overDue}
                    overReturn={overReturn}
                    showDetail={this.toggleDetailSubmissionStatDialog}
                  />
                </div>
              </div>
            </Card>
          )
        })}

        {pageCount > 1 && (
          <Pagination color="primary" count={pageCount} page={currentPage} onChange={this.changePage} />
        )}

        <DetailSubmissionStatDialog
          isOpen={isDetailSubmissionStatDialogOpen}
          toggle={this.toggleDetailSubmissionStatDialog}
          homeworkId={selectedHomeworkId}
          status={selectedStatus}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkTab)
