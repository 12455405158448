import React from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from '@material-ui/core'
import { Check as DoneIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  ticketList: state.ticket.list
})

const WidgetReminderMessage = props => {
  const { ticketId, currentUser, ticketList } = props

  const ticketData = _.find(ticketList.data, o => {
    return o._id === ticketId
  })

  if (!ticketData) {
    return null
  }

  const handlePersonName = currentUser.name
  const studentName = ticketData.student.name
  const classTitle = ticketData.class.name
  const lessonTime = ticketData.lesson.scheduled_on

  let timeDescriptionEn = 'today'
  let timeDescriptionZh = '今天'
  let lessonMoment = moment(lessonTime)
  if (!moment().isSame(lessonMoment, 'day')) {
    let dayNumber = lessonMoment.format('ddd')
    if (dayNumber === 'Mon') {
      timeDescriptionEn = 'on Monday'
      timeDescriptionZh = '星期一'
    } else if (dayNumber === 'Tue') {
      timeDescriptionEn = 'on Tuesday'
      timeDescriptionZh = '星期二'
    } else if (dayNumber === 'Wed') {
      timeDescriptionEn = 'on Wednesday'
      timeDescriptionZh = '星期三'
    } else if (dayNumber === 'Thu') {
      timeDescriptionEn = 'on Thursday'
      timeDescriptionZh = '星期四'
    } else if (dayNumber === 'Fri') {
      timeDescriptionEn = 'on Friday'
      timeDescriptionZh = '星期五'
    } else if (dayNumber === 'Sat') {
      timeDescriptionEn = 'on Saturday'
      timeDescriptionZh = '星期六'
    } else if (dayNumber === 'Sun') {
      timeDescriptionEn = 'on Sunday'
      timeDescriptionZh = '星期天'
    }
  }

  const reminderMessageEn = `Good Day, \nParents of ${studentName}, this is ${handlePersonName} from Tenopy, please be reminded that your child has a trial lesson ${timeDescriptionEn} at ${lessonMoment.format(
    'h:mma'
  )} for ${classTitle}. See you there.`
  const reminderMessageZh = `${studentName}家长您好，\n我是Tenopy的${handlePersonName}，提醒您${studentName}${timeDescriptionZh}${lessonMoment.format(
    'h:mma'
  )}有${classTitle}的试听课。希望${studentName}享受在Tenopy的学习旅程！`

  return (
    <div className="d-flex align-items-center">
      <CopyToClipboard text={reminderMessageEn} onCopy={() => {}}>
        <Button color="primary" variant="text" size="small" className="mx-1" style={{ minWidth: '40px' }}>
          EN
        </Button>
      </CopyToClipboard>
      |
      <CopyToClipboard text={reminderMessageZh} onCopy={() => {}}>
        <Button color="primary" variant="text" size="small" className="mx-1" style={{ minWidth: '40px' }}>
          中文
        </Button>
      </CopyToClipboard>
      |
      <DoneIcon className="pointer mx-1 text-success" onClick={props.clickDoneReminder} />
    </div>
  )
}

export default connect(mapStateToProps)(WidgetReminderMessage)
