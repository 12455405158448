/* eslint-disable react/display-name */
import React from 'react'
import { useTable, useFilters, useSortBy, useResizeColumns } from 'react-table'
import matchSorter from 'match-sorter'
import styled from 'styled-components'
import { Button, TextField } from '@material-ui/core'
import moment from 'moment'
import { FindInPage as ViewIcon, Edit as EditIcon, AddCircle as AddIcon, Close as CancelIcon } from '@material-ui/icons'

import TicketStatusBadge from './badge-ticket-status'

const Styles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: #d3d3d3;
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: red;
        }
      }
    }
  }
`

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function DateColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextField
      type="date"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default function TicketTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'student.name',
        Cell: ({ row }) => {
          const studentEmail = row.values['student.email']
          return (
            <span className="pointer mr-2" onClick={props.openOrderPage(studentEmail)}>
              {row.values['student.name']}
            </span>
          )
        }
      },
      {
        Header: 'Email',
        accessor: 'student.email'
      },
      {
        Header: 'Phone',
        accessor: 'student.phone'
      },
      {
        Header: 'Class',
        accessor: 'class.name'
      },
      {
        Header: 'Sub End Date',
        accessor: 'subscription_end_date',
        Filter: DateColumnFilter,
        Cell: ({ row }) => moment(row.values['subscription_end_date']).format('ll')
      },
      {
        Header: 'Followup Date',
        accessor: 'next_action_date',
        Filter: DateColumnFilter,
        Cell: ({ row }) => {
          const followUpDate = row.values['next_action_date']
          if (followUpDate === 'N/A') {
            return <span>N/A</span>
          } else {
            return <span>{moment(row.values['next_action_date']).format('lll')}</span>
          }
        }
      },
      {
        Header: 'Language',
        accessor: 'student.first_language'
      },
      {
        Header: 'Handled By',
        accessor: 'handled_by.name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          const handledBy = row.values['handled_by.name']
          const rowId = row.values._id
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">{handledBy}</span>
              {handledBy === 'N/A' ? (
                <AddIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'handled_by')}
                />
              ) : (
                <EditIcon
                  className="pointer"
                  color="primary"
                  onClick={props.openQuickUpdateDialog(rowId, 'handled_by')}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          const ticketStatus = row.values.status
          const rowId = row.values._id

          return (
            <div onClick={props.openQuickUpdateDialog(rowId, 'status')}>
              <TicketStatusBadge status={ticketStatus} />
            </div>
          )
        }
      },
      {
        Header: 'Action',
        accessor: '_id',
        disableFilter: true,
        width: 120,
        Cell: ({ row }) => {
          const rowId = row.values._id
          return (
            <>
              <ViewIcon className="pointer mr-2" color="primary" onClick={props.openViewDetailDialog(rowId)} />
              <EditIcon
                className="pointer mr-2"
                color="primary"
                onClick={props.openQuickUpdateDialog(rowId, 'status')}
              />
              <CancelIcon className="pointer text-danger" onClick={props.openQuickUpdateDialog(rowId, 'cancel')} />
            </>
          )
        }
      }
    ],
    [props]
  )

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 70,
      width: 150,
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const { data } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setAllFilters, setSortBy } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: false,
      autoResetFilters: false
    },
    useFilters,
    useSortBy,
    useResizeColumns
  )

  return (
    <Styles>
      <div className="d-flex align-items-center">
        {data.length === rows.length ? (
          <span>Showing all {data.length} records</span>
        ) : (
          <span>
            Showing {rows.length} out of {data.length} records
          </span>
        )}
        <Button
          color="primary"
          variant="text"
          className="ml-2"
          onClick={() => {
            setAllFilters([])
            setSortBy([{ id: 'created', desc: false }])
          }}>
          Reset Table Filter
        </Button>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  {/* Render the columns filter UI */}
                  <div>{column.disableFilter ? null : column.render('Filter')}</div>
                  <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  // eslint-disable-next-line react/jsx-key
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Styles>
  )
}
