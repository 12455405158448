import React from 'react'
import { connect } from 'react-redux'
import { Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import { GET_TICKET_LIST, UPDATE_TICKET, GET_TICKET_LESSON_DETAIL, GET_USERS } from 'constants/actionTypes'
import { ROUTE_ORDERS } from 'routes/paths'
import ViewDetailDialog from './dialog-view-trial-detail'
import QuickUpdateDialog from './dialog-quick-update'
import BatchUpdateDialog from './dialog-batch-update'
import UpdateStudentDialog from './dialog-update-student'
import TicketDataTable from './table-trial-tickets'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  agentList: state.user.agentList,
  loadingTicketList: state.ticket.loadingList,
  ticketList: state.ticket.list,
  updatingTicket: state.ticket.updatingTicket,
  lessonDetail: state.ticket.lessonDetail
})

const mapDispatchToProps = dispatch => ({
  getTickets: (type, filter) => dispatch({ type: GET_TICKET_LIST, payload: agent.Ticket.getList(type, filter) }),
  getUsers: (filter, offset, userType) => dispatch({ type: GET_USERS, payload: agent.User.getUsers(filter), userType }),
  updateTicket: (type, ticketId, reqPayload) =>
    dispatch({ type: UPDATE_TICKET, payload: agent.Ticket.updateById(type, ticketId, reqPayload), ticketId }),
  getLessonDetails: lessonId =>
    dispatch({ type: GET_TICKET_LESSON_DETAIL, payload: agent.Lesson.getLesson(lessonId), lessonId })
})

const LEAD_SOURCE = ['FB', 'Google', 'Wechat', 'SGHM', 'Ex', 'Call', 'Call TLS', 'Referral', 'LLP', 'LX', 'Organic']

class Tickets extends React.Component {
  state = {
    fromDate: moment().startOf('day').format('YYYY-MM-DD'),
    toDate: moment().endOf('day').format('YYYY-MM-DD'),
    dateFilter: 'trial_date',
    emptyResult: false,
    tableData: [],
    selectedTicket: null,
    selectedTickets: [],
    selectedField: null,
    selectedStudent: null,
    isViewDetailDialogOpen: false,
    isQuickUpdateDialogOpen: false,
    isUpdateStudentDialogOpen: false,
    isBatchUpdateDialogOpen: false
  }

  componentDidMount() {
    if (this.props.agentList.length === 0) {
      this.props.getUsers({ _type: 'Agent' }, 0, 'Agent')
    }
    this.search()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingTicketList && !this.props.loadingTicketList) {
      if (this.props.ticketList.data.length === 0) {
        this.setState({ emptyResult: true, tableData: [] })
      } else {
        this.updateTableData()
      }
    }

    if (prevProps.updatingTicket && !this.props.updatingTicket) {
      this.updateTableData()
    }
  }

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDateRangeSelect = option => () => {
    let fromDate, toDate
    switch (option) {
      case 0:
        fromDate = moment().startOf('day').format('YYYY-MM-DD')
        toDate = moment().endOf('day').format('YYYY-MM-DD')
        break
      case 1:
        fromDate = moment().startOf('week').format('YYYY-MM-DD')
        toDate = moment().endOf('week').format('YYYY-MM-DD')
        break
      case 2:
        fromDate = moment().subtract(2, 'week').startOf('week').format('YYYY-MM-DD')
        toDate = moment().endOf('week').format('YYYY-MM-DD')
        break
      case 3:
        fromDate = moment().startOf('month').format('YYYY-MM-DD')
        toDate = moment().endOf('month').format('YYYY-MM-DD')
        break
      case 4:
        fromDate = moment().startOf('year').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
        break

      default:
        break
    }

    this.setState({ fromDate, toDate })
  }

  search = () => {
    const { dateFilter, fromDate, toDate } = this.state

    this.setState({ emptyResult: false })

    let filterOpt = {
      [dateFilter]: {
        $gte: moment(fromDate).startOf('day').toISOString(),
        $lte: moment(toDate).endOf('day').toISOString()
      },
      max: 50,
      offset: 0
    }

    this.props.getTickets('trial', filterOpt)
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'

    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  updateTableData = () => {
    const { ticketList } = this.props

    for (let i = 0; i < ticketList.data.length; i++) {
      if (!ticketList.data[i].student.name) {
        ticketList.data[i].student['name'] = 'N/A'
      }
      if (!ticketList.data[i].student.first_language) {
        ticketList.data[i].student['first_language'] = 'N/A'
      }

      if (!ticketList.data[i].meta?.lead_source) {
        if (ticketList.data[i].meta) {
          ticketList.data[i]['meta']['lead_source'] = 'N/A'
        } else {
          ticketList.data[i]['meta'] = {
            lead_source: 'N/A'
          }
        }
      }
      if (!ticketList.data[i].handled_by) {
        ticketList.data[i]['handled_by'] = {
          name: 'N/A'
        }
      }

      //ticketList.data[i].trial_date = moment(ticketList.data[i].trial_date).format('lll')
      //ticketList.data[i].created = moment(ticketList.data[i].created).format('ll')

      if (!ticketList.data[i].next_action_date) {
        ticketList.data[i]['next_action_date'] = 'N/A'
      } /*else {
        ticketList.data[i].next_action_date = moment(ticketList.data[i].next_action_date).format('lll')
      }*/

      ticketList.data[i]['tutor_commented'] = 'No'
      if (ticketList.data[i].tutor_comment) {
        ticketList.data[i]['tutor_commented'] = 'Yes'
      }

      ticketList.data[i]['attended'] = 'No'
      if (ticketList.data[i].attendance?.length > 0) {
        ticketList.data[i]['attended'] = 'Yes'
      }
    }
    let tableData = _.orderBy(ticketList.data, ['trial_date'], ['desc'])
    this.setState({ tableData })
  }

  toggleViewDetailDialog = ticketId => () => {
    const { lessonDetail } = this.props
    const { tableData } = this.state
    const selectedTicket = _.find(tableData, o => {
      return o._id === ticketId
    })

    if (selectedTicket && !lessonDetail[selectedTicket.lesson._id]) {
      this.props.getLessonDetails(selectedTicket.lesson._id)
    }

    this.setState({
      selectedTicket,
      isViewDetailDialogOpen: !this.state.isViewDetailDialogOpen
    })
  }

  toggleQuickUpdateDialog = (ticketId, selectedField) => () => {
    const { tableData } = this.state
    const selectedTicket = _.find(tableData, o => {
      return o._id === ticketId
    })

    this.setState({
      selectedTicket,
      selectedField,
      isQuickUpdateDialogOpen: !this.state.isQuickUpdateDialogOpen
    })
  }

  toggleBatchUpdateDialog = ticketIds => () => {
    this.setState({
      selectedTickets: ticketIds ?? [],
      isBatchUpdateDialogOpen: !this.state.isBatchUpdateDialogOpen
    })
  }

  toggleUpdateStudentDialog = ticketId => () => {
    const { tableData } = this.state
    const selectedTicket = _.find(tableData, o => {
      return o._id === ticketId
    })

    this.setState({
      selectedStudent: selectedTicket?.student ?? null,
      isUpdateStudentDialogOpen: !this.state.isUpdateStudentDialogOpen
    })
  }

  clickDoneReminder = ticketId => () => {
    const { currentUser } = this.props
    let reqPayload = {
      status: 'PRE TRIAL FOLLOWED UP',
      action: `Reminder sent by ${currentUser.name}`
    }
    this.props.updateTicket('trial', ticketId, reqPayload)
  }

  openOrderPage = studentEmail => () => {
    window.open(`${process.env.REACT_APP_HOSTNAME}${ROUTE_ORDERS}?email=${studentEmail}`, '_blank')
  }

  render() {
    const { loadingTicketList, currentUser, lessonDetail, ticketList } = this.props
    const {
      fromDate,
      toDate,
      dateFilter,
      emptyResult,
      tableData,
      selectedTicket,
      selectedTickets,
      selectedField,
      selectedStudent,
      isViewDetailDialogOpen,
      isQuickUpdateDialogOpen,
      isBatchUpdateDialogOpen,
      isUpdateStudentDialogOpen
    } = this.state

    return (
      <div className="app-wrapper">
        <div className="row align-items-center">
          <div className="col-12 mb-3">
            <div className="d-flex align-items-center manage-margin mb-1">
              <TextField
                variant="outlined"
                type="date"
                label="From"
                name="fromDate"
                value={fromDate}
                onChange={this.handleChange}
              />

              <TextField
                variant="outlined"
                type="date"
                label="To"
                name="toDate"
                value={toDate}
                onChange={this.handleChange}
              />

              <FormControl variant="outlined">
                <InputLabel>Date Filter</InputLabel>
                <Select value={dateFilter} onChange={this.handleChange} name="dateFilter" label="Date Filter">
                  <MenuItem value="trial_date">Trial Date</MenuItem>
                  <MenuItem value="created">Registration Date</MenuItem>
                  <MenuItem value="next_action_date">Followup Date</MenuItem>
                </Select>
              </FormControl>

              <Button variant="contained" color="primary" onClick={this.search}>
                Search
              </Button>
            </div>
            <div className="d-flex">
              <Chip
                size="small"
                label="today"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(0)}
              />

              <Chip
                size="small"
                label="this week"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(1)}
              />

              <Chip
                size="small"
                label="2 weeks"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(2)}
              />

              <Chip
                size="small"
                label="this month"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(3)}
              />

              <Chip
                size="small"
                label="this year"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(4)}
              />
            </div>
          </div>
        </div>

        {loadingTicketList && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {emptyResult && <div className="font-weight-semibold my-5 text-center">No ticket found.</div>}

        <TicketDataTable
          totalRecords={ticketList.length}
          data={tableData}
          openViewDetailDialog={this.toggleViewDetailDialog}
          openQuickUpdateDialog={this.toggleQuickUpdateDialog}
          openUpdateStudentDialog={this.toggleUpdateStudentDialog}
          openOrderPage={this.openOrderPage}
          openBatchUpdateDialog={this.toggleBatchUpdateDialog}
          currentUser={currentUser}
          clickDoneReminder={this.clickDoneReminder}
        />

        <ViewDetailDialog
          isOpen={isViewDetailDialogOpen}
          close={this.toggleViewDetailDialog()}
          ticketData={selectedTicket}
          lessonData={lessonDetail[selectedTicket?.lesson?._id] ?? null}
        />

        <QuickUpdateDialog
          isOpen={isQuickUpdateDialogOpen}
          close={this.toggleQuickUpdateDialog()}
          ticketData={selectedTicket}
          updateField={selectedField}
          LEAD_SOURCE={LEAD_SOURCE}
        />

        <BatchUpdateDialog
          isOpen={isBatchUpdateDialogOpen}
          close={this.toggleBatchUpdateDialog()}
          ticketIds={selectedTickets}
          updateField={selectedField}
          LEAD_SOURCE={LEAD_SOURCE}
        />

        <UpdateStudentDialog
          isOpen={isUpdateStudentDialogOpen}
          close={this.toggleUpdateStudentDialog()}
          studentData={selectedStudent}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
