import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import PropTypes from 'prop-types'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({
  students: state.user.userList
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset) =>
    dispatch({ type: Type.GET_USERS, payload: agent.User.getUsers({ ...filter, _type: 'Student' }, offset) })
})

class DialogAddStudent extends React.Component {
  constructor(props) {
    super()

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.state = {
      emails: '',
      existing: '',
      notFound: '',
      found: [],
      initiated: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.students, this.props.students) && !!this.props.students && this.state.initiated) {
      const { emails } = this.state
      let studentArr = this.props.students
      let emailArr = _.map(studentArr, 'email')
      let currentEmailArr = emails.split('\n')

      let notFound = _.difference(currentEmailArr, emailArr)
      let found = _.map(studentArr, '_id')

      this.setState({ notFound: notFound.join('\n'), emails: '', found })
    }
  }

  findStudents = () => {
    const { emails } = this.state
    const { lesson } = this.props

    let emailArr = emails.trim().split('\n')
    emailArr = _.remove(emailArr, e => {
      return e.trim() !== ''
    })
    emailArr = _.uniq(emailArr)
    emailArr = _.map(emailArr, _.trim)

    let currentStudents = lesson.student
    let currentEmails = _.map(currentStudents, 'email')

    let notInClass = _.difference(emailArr, currentEmails)
    let alreadyInClass = _.intersection(emailArr, currentEmails)

    if (emailArr.length === 0) {
      this.setState({ emails: '' })
    } else if (notInClass.length === 0) {
      this.setState({ initiated: true, emails: notInClass.join('\n'), existing: alreadyInClass.join('\n') })
      this.props.getUsers({ email: 'getemptylist' })
    } else {
      this.setState({ initiated: true, emails: notInClass.join('\n'), existing: alreadyInClass.join('\n') })
      this.props.getUsers({ email: { $in: notInClass } })
    }
  }

  addStudents = () => {
    this.props.onSave(this.state.found)
    this.props.toggle()
  }

  render() {
    let { isOpen, toggle, lesson, students } = this.props
    let { emails, existing, notFound, initiated, found } = this.state

    return (
      <Dialog disableBackdropClick disableEscapeKeyDown open={isOpen} onClose={toggle} fullWidth={true}>
        <DialogTitle>Add students to {lesson.name}</DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col">
              {!!notFound && (
                <TextField
                  className={'mb-3'}
                  label={'Not found'}
                  multiline={true}
                  disabled={true}
                  value={notFound}
                  fullWidth
                />
              )}
              {!!existing && (
                <TextField
                  className={'mb-3'}
                  label={'Already in class'}
                  multiline={true}
                  disabled={true}
                  value={existing}
                  fullWidth
                />
              )}
              {!initiated && (
                <FormControl fullWidth>
                  <div className={'mb-3'}>
                    Copy and paste a list from Excel or add multiple emails, each on a new line (10 students max)
                  </div>
                  <TextField
                    label={'Email(s)'}
                    placeholder={'email_1@mail.com\nemail_2@mail.com'}
                    multiline={true}
                    name={'emails'}
                    value={emails}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>
              )}
            </div>
            <div className="col d-flex align-items-center">
              {initiated ? (
                students ? (
                  <div>
                    <h2>Student accounts found</h2>
                    <Paper elevation={2}>
                      <List disablePadding={true}>
                        {students.length > 0 ? (
                          students.map(s => {
                            return (
                              <ListItem key={s._id} divider={true}>
                                <ListItemText>
                                  <span className="font-weight-semibold">{!!s.name ? s.name : 'No Name'}</span>
                                  <div className="mb-3 text-muted">
                                    <small>{s.email}</small>
                                  </div>
                                </ListItemText>
                              </ListItem>
                            )
                          })
                        ) : (
                          <ListItem divider={true}>
                            <ListItemText>No student accounts were found</ListItemText>
                          </ListItem>
                        )}
                      </List>
                    </Paper>
                  </div>
                ) : (
                  <div className="py-5 text-center">
                    <CircularProgress size={30} color={'primary'} />
                  </div>
                )
              ) : (
                <Button
                  disabled={!emails}
                  variant={'contained'}
                  color={'primary'}
                  onClick={this.findStudents}
                  fullWidth>
                  Find Student Accounts
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} color={'default'} className={'mr-auto'} onClick={toggle}>
            Cancel
          </Button>
          <Button disabled={!initiated || found.length === 0} variant="text" color="primary" onClick={this.addStudents}>
            Add Students
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogAddStudent.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  lesson: PropTypes.object,
  onSave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddStudent)
