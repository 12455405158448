import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CKEditor from 'react-ckeditor-component'
import moment from 'moment'
import Pluralize from 'react-pluralize'
import AnnouncementTemplate from 'components/Announcements/AnnouncementTemplate'
import { MAKE_ANNOUNCEMENT } from 'constants/actionTypes'
import SMSPreview from 'components/Announcements/SMSPreview'

const mapStateToProps = state => ({
  ...state.announcements
})

const mapDispatchToProps = dispatch => ({
  sendAnnouncement: (scope_type, scope_id, announcement_type, target_type, subject, content) => {
    let promiseArr = []
    for (let i = 0; i < target_type.length; i++) {
      promiseArr.push(
        agent.Announcement.makeAnnouncement(scope_type, scope_id, announcement_type, target_type[i], subject, content)
      )
    }
    return dispatch({ type: MAKE_ANNOUNCEMENT, payload: Promise.all(promiseArr) })
  }
})

class CreateAnnouncement extends React.Component {
  constructor(props) {
    super()

    this.toggleConfirmAnnouncement = () => {
      this.setState({ confirmAnnouncementDialogOpen: !this.state.confirmAnnouncementDialogOpen })
    }

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.handleCheckboxChange = (e, checked) => {
      this.setState({ [e.target.name]: checked })
    }

    this.handleCKEditorChange = evt => {
      let newContent = evt.editor.getData()
      this.setState({ content: newContent })
    }

    const { changes, lesson } = props
    let subject = `Re: [${lesson.name}] on ${moment(lesson.scheduled_on).format('dddd Do MMM YYYY, h:mma')}`
    let content = ``
    let changeList = []

    if (!!changes) {
      subject = `Changes to [${lesson.name}] on ${moment(lesson.scheduled_on).format('dddd Do MMM YYYY, h:mma')}`
      content = `<p>There have been changes to the ${lesson.class.name} lesson [${lesson.name}]</p>`

      for (let i = 0; i < changes.length; i++) {
        let currentChange = changes[i]
        let upperChange = currentChange.field.replace(/^\w/, c => c.toUpperCase())
        let singleChangePhrase = `<li>${upperChange}</b> changed to <b>${currentChange.value}</b> from ${currentChange.original}</li>`

        changeList.push(singleChangePhrase)
      }
    }

    content = content.concat(changeList.join(''))

    this.state = {
      announcementType: 'email',
      smsContent: '',
      smsCharCount: 459,
      subject: subject,
      content: content,
      confirmAnnouncementDialogOpen: false,
      selectedUser: null,
      announcementSentModalOpen: false,
      hasCTA: false,
      ctaText: 'View Class',
      ctaLink: `https://sg.tenopy.com/app/class/${lesson.class._id}/`,
      fullContent: content,
      toStudent: false,
      toTrial: false,
      toVideo: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.announcementSent && this.props.announcementSent) {
      this.setState({ announcementSentModalOpen: true, confirmAnnouncementDialogOpen: false })
    }

    if (
      prevState.content !== this.state.content ||
      prevState.hasCTA !== this.state.hasCTA ||
      prevState.ctaText !== this.state.ctaText ||
      prevState.ctaLink !== this.state.ctaLink
    ) {
      if (this.state.hasCTA) {
        const { content, ctaText, ctaLink } = this.state
        let CTA = `<a style='text-align: center; width: fit-content; padding: 8px 16px; color: white; background-color: #673ab7; border: none; border-radius: 3px; min-width: 150px; display: block; margin: 2rem auto; text-decoration: none;' href='${ctaLink}' target='_blank'>${ctaText}</a>`
        let fullContent = content + CTA

        this.setState({ fullContent: fullContent })
      } else {
        this.setState({ fullContent: this.state.content })
      }
    }
  }

  sendAnnouncement = () => {
    const { lesson } = this.props
    const { announcementType, smsContent, subject, fullContent, toStudent, toTrial, toVideo } = this.state

    let targetType = []

    if (toStudent) {
      targetType.push('student')
    }
    if (toTrial) {
      targetType.push('trial_student')
    }
    if (toVideo) {
      targetType.push('video_student')
    }

    let content = ''
    if (announcementType === 'email') {
      content = fullContent
    } else if (announcementType === 'sms') {
      content = smsContent
    }

    this.props.sendAnnouncement('lesson', lesson._id, announcementType, targetType, subject, content)
  }

  changeAnnouncementType = selectedType => () => {
    this.setState({ announcementType: selectedType })
  }

  render() {
    const {
      confirmAnnouncementDialogOpen,
      announcementSentModalOpen,
      announcementType,
      smsContent,
      smsCharCount,
      subject,
      content,
      hasCTA,
      ctaText,
      ctaLink,
      fullContent,
      selectedUser,
      toStudent,
      toTrial,
      toVideo
    } = this.state
    const { lesson, inProgress, onSent } = this.props

    let sendCount = 0

    if (toStudent) {
      sendCount += lesson.student.length
    }
    if (toTrial) {
      sendCount += lesson.trial_student.length
    }
    if (toVideo) {
      sendCount += lesson.video_student.length
    }

    const toolbarGroups = [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['Link', 'Unlink', 'Anchor'] },
      '/',
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ]

    const removeButtons =
      'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Undo,Redo,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Anchor,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,ShowBlocks,Maximize,About'

    const CkConfig = {
      toolbarGroups,
      removeButtons
    }

    let disabled = true

    if (announcementType === 'email') {
      disabled = !content
    } else if (announcementType === 'sms') {
      disabled = !smsContent
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="py-2 mb-2 border-bottom">
              <h4 className="font-weight-semibold">Announcement Method</h4>
              <Button
                variant={'outlined'}
                color={announcementType === 'email' ? 'primary' : 'default'}
                onClick={this.changeAnnouncementType('email')}>
                Email
              </Button>
              <Button
                className={'ml-2'}
                variant={'outlined'}
                color={announcementType === 'sms' ? 'primary' : 'default'}
                onClick={this.changeAnnouncementType('sms')}>
                SMS
              </Button>
            </div>

            {announcementType === 'email' && (
              <React.Fragment>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="Subject"
                    name={'subject'}
                    onChange={this.handleChange}
                    defaultValue={subject}
                    margin="none"
                  />
                </FormControl>

                <FormControl className={'w-100 mt-3'}>
                  <FormLabel className={'mb-2'}>Announcement Content</FormLabel>
                  <CKEditor
                    id={'announcement_content'}
                    name={'content'}
                    activeClass="p10"
                    content={content}
                    config={CkConfig}
                    events={{
                      change: this.handleCKEditorChange
                    }}
                  />
                </FormControl>
                <FormControl className={'w-100 mt-3'}>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" name={'hasCTA'} checked={hasCTA} onChange={this.handleCheckboxChange} />
                    }
                    label="Add CTA Button"
                  />
                </FormControl>
                {hasCTA && (
                  <FormControl className={'w-100 mt-2'}>
                    <div className="row">
                      <div className="col">
                        <TextField
                          label={'Button Text'}
                          name={'ctaText'}
                          value={ctaText}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                      <div className="col">
                        <TextField
                          label={'Button Link'}
                          name={'ctaLink'}
                          value={ctaLink}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                    </div>
                  </FormControl>
                )}
              </React.Fragment>
            )}
            {announcementType === 'sms' && (
              <React.Fragment>
                <FormControl className="w-100">
                  <TextField
                    required
                    label="SMS"
                    name={'smsContent'}
                    onChange={this.handleChange}
                    defaultValue={smsContent}
                    multiline
                    rows={5}
                    maxLength={smsCharCount}
                  />
                  <div className="text-right">
                    <small className="text-muted">{smsCharCount - smsContent.length} characters left</small>
                  </div>
                </FormControl>
              </React.Fragment>
            )}

            <div className={'text-right mt-3'}>
              <Button disabled={disabled} variant="contained" color="primary" onClick={this.toggleConfirmAnnouncement}>
                Send announcement
              </Button>
            </div>

            <Dialog open={confirmAnnouncementDialogOpen} onClose={this.toggleConfirmAnnouncement}>
              <DialogTitle>Which group(s) do you want to send the announcement to in [{lesson.name}]?</DialogTitle>
              <DialogContent>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'toStudent'}
                        checked={toStudent}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Students"
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'toTrial'}
                        checked={toTrial}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Trial Students"
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'toVideo'}
                        checked={toVideo}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Video Only"
                  />
                </FormControl>
                <DialogContentText>You will not be able to reverse this decision.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.toggleConfirmAnnouncement} color="default">
                  Cancel
                </Button>
                <Button
                  color={'primary'}
                  disabled={inProgress || (!toStudent && !toTrial && !toVideo)}
                  onClick={this.sendAnnouncement}>
                  Confirm Send {inProgress && <CircularProgress color={'primary'} size={20} />}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={announcementSentModalOpen} disableEscapeKeyDown disableBackdropClick>
              <DialogTitle>Announcement Emails Sent</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The email has been sent to <Pluralize count={sendCount} singular={'student'} />!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color={'primary'} onClick={onSent}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="col">
            <Paper elevation={2} className={'app-wrapper mb-3'}>
              {announcementType.charAt(0).toUpperCase() + announcementType.slice(1)} Preview
            </Paper>
            {announcementType === 'email' && (
              <AnnouncementTemplate
                user={!!selectedUser ? selectedUser : null}
                subject={subject}
                content={fullContent}
              />
            )}
            {announcementType === 'sms' && <SMSPreview content={smsContent} />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

CreateAnnouncement.propTypes = {
  lesson: PropTypes.object.isRequired,
  changes: PropTypes.array,
  onSent: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnnouncement)
