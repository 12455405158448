import React from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AddClass from 'pages/CourseManagement/AddClass'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import moment from 'moment'

import { ROUTE_COURSES } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  userRole: state.auth.userRole
})

class ClassCell extends React.Component {
  onClassOptionSelect = event => {
    this.setState({ menuState: true, anchorEl: event.currentTarget })
  }
  handleRequestClose = () => {
    this.setState({ menuState: false })
  }
  onClassClose = () => {
    this.setState({ addClassState: false })
  }
  onDeleteClass = tClass => {
    this.setState({ addContactState: false })
    this.props.onDeleteClass(tClass)
  }
  onEditClass = () => {
    this.setState({ menuState: false, addClassState: true })
  }

  constructor() {
    super()
    this.state = {
      anchorEl: undefined,
      menuState: false,
      addClassState: false
    }
  }

  render() {
    const { tClass, onSaveClass, userRole } = this.props
    const { menuState, anchorEl, addClassState } = this.state
    const { name, subject, lesson, schedule, rate } = tClass

    return (
      <div className="contact-item">
        <div className="col">
          <p className="mb-0">
            <span className="text-truncate contact-name">{name}</span>
            {tClass.is_active ? (
              <Badge color={'success'} className={'ml-3'}>
                Active
              </Badge>
            ) : (
              <Badge color={'danger'} className={'ml-3'}>
                Inactive
              </Badge>
            )}
            {tClass.is_listed ? <Badge color={'success'}>Listed</Badge> : <Badge color={'danger'}>Un-listed</Badge>}
            {tClass.is_trial ? <Badge color={'success'}>Trial</Badge> : <Badge color={'danger'}>X Trial</Badge>}
          </p>
          <div className="text-muted">
            <span className="email d-inline-block">{subject}</span>
            <span className="d-inline-block mx-2">|</span>
            {!!schedule ? (
              <span className="d-inline-block">
                {schedule.date_of_week}, {moment(schedule.start_time).format('hh:mm a')} to{' '}
                {moment(schedule.end_time).format('hh:mm a')}
              </span>
            ) : (
              <span className="d-inline-block">
                <strong>This class has no schedule</strong>
              </span>
            )}
          </div>
          <div className="text-muted">
            <span className="email d-inline-block mr-2">
              Number of lessons: {lesson.length} - <strong>${rate}</strong> per lesson
            </span>
          </div>
        </div>

        <div className="col-auto px-1 actions">
          <IconButton className="size-30" onClick={this.onClassOptionSelect}>
            <i className="zmdi zmdi-more-vert" />
          </IconButton>

          <Menu id="long-menu" anchorEl={anchorEl} open={menuState} onClose={this.handleRequestClose}>
            <MenuItem
              component={props => {
                return <Link {...props} to={`/course/${tClass._id}`} />
              }}>
              View Lessons
            </MenuItem>
            {checkActionPermitted(permissions, userRole, ROUTE_COURSES, 'canManage') && (
              <MenuItem onClick={this.onEditClass}>Edit Class</MenuItem>
            )}
            {checkActionPermitted(permissions, userRole, ROUTE_COURSES, 'canManage') && (
              <MenuItem
                onClick={() => {
                  this.handleRequestClose()
                  this.onDeleteClass(tClass)
                }}>
                {tClass.is_active ? 'Deactivate' : 'Activate'}
              </MenuItem>
            )}
          </Menu>
          {addClassState && (
            <AddClass
              open={addClassState}
              tClass={tClass}
              onSaveClass={onSaveClass}
              onClassClose={this.onClassClose}
              onDeleteClass={this.onDeleteClass}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ClassCell)
