import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const initialState = {
  loadingList: false,
  list: { data: [], count: 0 },
  loadingLessonDetail: false,
  lessonDetail: {},
  updatingTicket: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_TICKET_LIST:
      return {
        ...state,
        loadingList: true
      }

    case Type.GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        list: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_TICKET_LIST_FAILED:
      return {
        ...state,
        loadingList: false
      }

    case Type.GET_TICKET_LESSON_DETAIL:
      return {
        ...state,
        loadingLessonDetail: true
      }

    case Type.GET_TICKET_LESSON_DETAIL_SUCCESS:
      return {
        ...state,
        loadingLessonDetail: false,
        lessonDetail: {
          ...state.lessonDetail,
          [action.lessonId]: {
            poll: action.payload.data.poll,
            poll_setup: action.payload.data.poll_setup,
            poll_stat: action.payload.data.poll_stat
          }
        }
      }

    case Type.GET_TICKET_LESSON_DETAIL_FAILED:
      return {
        ...state,
        loadingLessonDetail: false
      }

    case Type.UPDATE_TICKET:
      return {
        ...state,
        updatingTicket: true
      }

    case Type.UPDATE_TICKET_SUCCESS: {
      let updatedList = state.list
      let updatedTicketIndex = _.findIndex(updatedList.data, o => {
        return o._id === action.ticketId
      })

      if (updatedTicketIndex > -1) {
        updatedList.data[updatedTicketIndex] = action.payload.data
      }

      return {
        ...state,
        updatingTicket: false,
        list: updatedList
      }
    }

    case Type.UPDATE_TICKET_FAILED:
    case Type.BATCH_UPDATE_TICKET_FAILED:
      return {
        ...state,
        updatingTicket: false
      }

    case Type.BATCH_UPDATE_TICKET:
      return {
        ...state,
        updatingTicket: true
      }

    case Type.BATCH_UPDATE_TICKET_SUCCESS: {
      let updatedList = state.list
      for (let i = 0; i < action.payload.data.length; i++) {
        const updatedTicket = action.payload.data[i]

        let ticketIndex = _.findIndex(updatedList.data, o => {
          return o._id === updatedTicket._id
        })

        if (ticketIndex > -1) {
          updatedList.data[ticketIndex] = updatedTicket
        }
      }

      return {
        ...state,
        updatingTicket: false,
        list: updatedList
      }
    }

    case Type.UPDATE_USER_SUCCESS: {
      let updatedList = state.list
      if (action.studentId) {
        for (let i = 0; i < updatedList.data.length; i++) {
          if (updatedList.data[i].student._id === action.studentId) {
            updatedList.data[i].student['gender'] = action.payload.data.gender
            updatedList.data[i].student['school'] = action.payload.data.school
            updatedList.data[i].student['first_language'] = action.payload.data.first_language
            updatedList.data[i].student['meta'] = action.payload.data.meta
          }
        }
      }

      return {
        ...state,
        list: updatedList
      }
    }

    default:
      return {
        ...state
      }
  }
}
