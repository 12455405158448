import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import { Badge } from 'reactstrap'
import moment from 'moment'
import _ from 'lodash'
import * as Type from 'constants/actionTypes'
import DialogAddLesson from 'pages/CourseManagement/LessonManagement/DialogAddLesson'
import DialogAddLessonBatch from 'pages/CourseManagement/LessonManagement/DialogAddLessonBatch'

import { ROUTE_LESSONS } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  ...state.lessons,
  classList: state.classroom.classList,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getClasses: filter => dispatch({ type: Type.GET_CLASSES, payload: agent.Classroom.getClasses(filter, 0) }),
  createLesson: lessonData => dispatch({ type: Type.CREATE_LESSON, payload: agent.Lesson.createLesson(lessonData) }),
  editLesson: lessonData => dispatch({ type: Type.EDIT_LESSON, payload: agent.Lesson.editLesson(lessonData) })
})

class LessonManagement extends React.Component {
  constructor(props) {
    super()

    props.getClasses({ _id: props.match.params.classId })

    this.toggleAddLessonDialog = () => {
      this.setState({ addLessonDialogOpen: !this.state.addLessonDialogOpen })
    }

    this.toggleAddLessonBatchDialog = () => {
      this.setState({ addLessonBatchDialogOpen: !this.state.addLessonBatchDialogOpen })
    }

    this.saveLesson = lessonData => {
      this.props.createLesson(lessonData)
    }

    this.state = {
      addLessonDialogOpen: false,
      addLessonBatchDialogOpen: false,
      toEdit: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.lessonCreated && this.props.lessonCreated) ||
      (!prevProps.lessonEdited && this.props.lessonEdited)
    ) {
      if (!this.state.addLessonBatchDialogOpen) {
        this.props.getClasses({ _id: this.props.match.params.classId })
      }
    }

    if (!this.state.addLessonBatchDialogOpen && prevState.addLessonBatchDialogOpen) {
      this.props.getClasses({ _id: this.props.match.params.classId })
    }
  }

  toggleLessonStatus = (lessonData, name) => () => {
    let toModify = {
      _id: lessonData._id,
      [name]: !lessonData[name]
    }

    this.props.editLesson(toModify)
  }

  render() {
    const { classList, userRole } = this.props
    const { addLessonDialogOpen, toEdit, addLessonBatchDialogOpen } = this.state

    const canManage = checkActionPermitted(permissions, userRole, ROUTE_LESSONS, 'canManage')

    if (classList && classList.length > 0) {
      const activeClass = classList[0]
      const lessonList = _.orderBy(activeClass.lesson, ['scheduled_on'], ['asc'])

      return (
        <div>
          <Paper elevation={2} className={'app-wrapper sticky-top'}>
            <div className="mb-2">
              <Link to={'/course'}>Back to class list</Link>
            </div>
            <h1 className={'mb-1'}>{activeClass.name}</h1>
            <h3 className={'text-muted mb-0'}>Lessons</h3>
          </Paper>
          <div className="app-wrapper">
            <Paper elevation={2} className={'mb-3'}>
              <div className="app-wrapper py-3 border-bottom">
                <h3 className="font-weight-semibold mb-0">Class Overview</h3>
              </div>
              <div className="app-wrapper">
                <div className={'font-weight-semibold mb-3'}>
                  {activeClass.schedule.date_of_week}, {moment(activeClass.schedule.start_time).format('hh:mm a')} to{' '}
                  {moment(activeClass.schedule.end_time).format('hh:mm a')}
                </div>
                {!!activeClass.desc && <div dangerouslySetInnerHTML={{ __html: activeClass.desc }}></div>}
              </div>
            </Paper>

            <Paper elevation={2}>
              {lessonList.length > 0 ? (
                <List disablePadding={true}>
                  {canManage && (
                    <ListItem button={true} divider={true} onClick={this.toggleAddLessonDialog}>
                      <ListItemText>
                        <i className="zmdi zmdi-plus mr-2"></i>Add a new lesson
                      </ListItemText>
                    </ListItem>
                  )}
                  {lessonList.map(item => {
                    return (
                      <ListItem
                        key={item._id}
                        button={true}
                        divider={true}
                        component={props => <Link {...props} to={`/course/${activeClass._id}/lesson/${item._id}`} />}>
                        <ListItemText>
                          <div>
                            <div>
                              <span className="text-muted text-small">
                                {item.meta && item.meta.term && `Term ${item.meta.term}`}
                              </span>
                              <br />
                              <span className={'font-weight-semibold'}>
                                {item.meta && `${item.meta.index}. `} {item.name}
                              </span>
                            </div>
                            {item.is_completed && (
                              <Badge color={'primary'} className={'ml-3'}>
                                Completed
                              </Badge>
                            )}
                            {item.is_active ? (
                              <Badge color={'success'} className={'ml-3'}>
                                Active
                              </Badge>
                            ) : (
                              <Badge color={'danger'} className={'ml-3'}>
                                Inactive
                              </Badge>
                            )}
                            {item.is_released ? (
                              <Badge color={'success'} className={'ml-3'}>
                                Release
                              </Badge>
                            ) : (
                              <Badge color={'danger'} className={'ml-3'}>
                                Un-Release
                              </Badge>
                            )}
                          </div>
                          <div>
                            <small className="text-muted">
                              {moment(item.scheduled_on).format('dddd, Do MMM YYYY, h:mma')}
                            </small>
                          </div>
                          <div>
                            <small className="text-muted">{item.student.length} students</small>
                          </div>
                        </ListItemText>
                        {canManage && (
                          <ListItemSecondaryAction>
                            {item.is_active ? (
                              <Button
                                variant={'text'}
                                className={'text-danger border mr-3'}
                                onClick={this.toggleLessonStatus(item, 'is_active')}>
                                Deactivate
                              </Button>
                            ) : (
                              <Button
                                variant={'text'}
                                className={'text-success border mr-3'}
                                onClick={this.toggleLessonStatus(item, 'is_active')}>
                                Activate
                              </Button>
                            )}
                            {item.is_released ? (
                              <Button
                                variant={'text'}
                                className={'text-danger border mr-3'}
                                onClick={this.toggleLessonStatus(item, 'is_released')}>
                                Un-Release
                              </Button>
                            ) : (
                              <Button
                                variant={'text'}
                                className={'text-success border mr-3'}
                                onClick={this.toggleLessonStatus(item, 'is_released')}>
                                Release
                              </Button>
                            )}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )
                  })}
                  {lessonList.length > 1 && canManage && (
                    <ListItem button={true} divider={true} onClick={this.toggleAddLessonDialog}>
                      <ListItemText>
                        <i className="zmdi zmdi-plus mr-2"></i>Add a new lesson
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              ) : (
                <div className="py-5 text-center">
                  <h2>This class currently has no lessons</h2>
                  {canManage && (
                    <>
                      <Button variant={'contained'} color={'primary'} onClick={this.toggleAddLessonDialog}>
                        Add a lesson
                      </Button>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        className="ml-2"
                        onClick={this.toggleAddLessonBatchDialog}>
                        Add Batch Lessons
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Paper>
          </div>
          {addLessonDialogOpen && (
            <DialogAddLesson
              toggle={this.toggleAddLessonDialog}
              isOpen={addLessonDialogOpen}
              lesson={toEdit}
              targetClass={activeClass}
              onSave={this.saveLesson}
            />
          )}

          {addLessonBatchDialogOpen && (
            <DialogAddLessonBatch
              toggle={this.toggleAddLessonBatchDialog}
              isOpen={addLessonBatchDialogOpen}
              targetClass={activeClass}
              onSave={this.saveLesson}
            />
          )}
        </div>
      )
    } else {
      return (
        <div className="loader-view-block h-100">
          <div className="loader-view">
            <CircularProgress />
          </div>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonManagement)
