import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import agent from 'agent'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({
  tutors: state.user.tutorList,
  TAs: state.user.taList
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset, userType) =>
    dispatch({ type: Type.GET_USERS, payload: agent.User.getUsers({ ...filter, _type: userType }, offset), userType })
})

class DialogAddLesson extends React.Component {
  constructor(props) {
    super()

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.handleDateChange = fieldName => date => {
      this.setState({ [fieldName]: date })
    }

    this.handleCheckboxChange = (e, checked) => {
      this.setState({ [e.target.name]: checked })
    }

    let { lesson, targetClass } = props
    if (!lesson) {
      lesson = {}
    }

    // NOTE: review the cloneDeep part, do we really need a immutable clone
    this.state = {
      lesson: _.cloneDeep(lesson),
      ...lesson,
      hosting_platform: lesson.hosting_platform ? lesson.hosting_platform : 'Tenopy Classroom',
      hosting_type: lesson.hosting_type ? lesson.hosting_type : '',
      scheduled_on: !!lesson.scheduled_on
        ? moment(lesson.scheduled_on).format('YYYY-MM-DDTHH:mm')
        : moment(props.targetClass.schedule.start_time).format('YYYY-MM-DDTHH:mm'),
      ended_on: !!lesson.ended_on
        ? moment(lesson.ended_on).format('HH:mm')
        : moment(props.targetClass.schedule.end_time).format('HH:mm'),
      class: targetClass._id,
      tutor: lesson.tutor ? lesson.tutor : targetClass.tutor,
      ta: lesson.ta ? lesson.ta : [],
      desc: !!lesson.desc ? lesson.desc : '',
      index: lesson.meta && lesson.meta.index ? lesson.meta.index : '',
      term: lesson.meta && lesson.meta.term ? lesson.meta.term : '',
      searchTerm: '',
      searchField: 'name',
      tutorSelectModalOpen: false,
      taSelectModalOpen: false,
      is_released: typeof lesson.is_released === 'boolean' ? lesson.is_released : true,
      is_active: typeof lesson.is_active === 'boolean' ? lesson.is_active : true
    }
  }

  toggleTutorSelectModal = index => () => {
    this.setState({
      tutorSelectModalOpen: !this.state.tutorSelectModalOpen,
      targetIndex: index
    })
  }

  toggleTASelectModal = index => () => {
    this.setState({
      taSelectModalOpen: !this.state.taSelectModalOpen,
      targetIndex: index
    })
  }

  searchUser = e => {
    e.preventDefault()
    const { searchTerm, searchField } = this.state

    let filter = { [searchField]: { $regex: `${searchTerm}`, $options: 'i' } }

    this.props.getUsers(filter, 0, e.target.user_type.value)
  }

  selectTutor = selectedTutor => () => {
    let { tutor, targetIndex } = this.state
    let tutorCopy = [...tutor]

    if (typeof targetIndex === 'undefined') {
      tutorCopy.push(selectedTutor)
    } else {
      tutorCopy[targetIndex] = selectedTutor
    }

    this.setState({ tutor: tutorCopy })
    this.toggleTutorSelectModal()
  }

  removeTutor = index => () => {
    let { tutor } = this.state

    let tutorCopy = [...tutor]

    tutorCopy.splice(index, 1)

    this.setState({ tutor: tutorCopy })
  }

  selectTA = selectedTA => () => {
    let { ta, targetIndex } = this.state
    let taCopy = [...ta]

    if (typeof targetIndex === 'undefined') {
      taCopy.push(selectedTA)
    } else {
      taCopy[targetIndex] = selectedTA
    }

    this.setState({ ta: taCopy })
    this.toggleTASelectModal()
  }

  removeTA = index => () => {
    let { ta } = this.state

    let taCopy = [...ta]

    taCopy.splice(index, 1)

    this.setState({ ta: taCopy })
  }

  saveLesson = () => {
    const {
      _id,
      name,
      scheduled_on,
      ended_on,
      hosting_platform,
      hosting_type,
      tutor,
      ta,
      student,
      is_active,
      is_released,
      desc,
      index,
      term
    } = this.state

    let endedOnMoment = moment(ended_on, 'HH:mm')
    let correctedEndedOn = moment(scheduled_on, 'YYYY-MM-DDTHH:mm')
      .hour(endedOnMoment.hour())
      .minute(endedOnMoment.minute())
    let scheduledOnMoment = moment(scheduled_on, 'YYYY-MM-DDTHH:mm')

    let lessonData = {
      _id,
      name,
      hosting_platform,
      hosting_type,
      scheduled_on: scheduledOnMoment.toISOString(),
      ended_on: correctedEndedOn.toISOString(),
      class: this.state.class,
      tutor: tutor.map(t => {
        return t._id
      }),
      ta: _.map(ta, '_id'),
      student: !student ? [] : _.map(student, '_id'),
      desc,
      meta: { index, term },
      is_active,
      is_released
    }
    this.props.onSave(lessonData)
    this.props.toggle()
  }

  render() {
    let { isOpen, toggle, tutors, TAs } = this.props
    const {
      lesson,
      name,
      scheduled_on,
      ended_on,
      hosting_platform,
      hosting_type,
      tutor,
      tutorSelectModalOpen,
      ta,
      taSelectModalOpen,
      searchTerm,
      searchField,
      is_released,
      is_active,
      desc,
      index,
      term
    } = this.state

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className={'text-white'}>
              <CloseIcon />
            </IconButton>
            <Typography
              type="title"
              color="inherit"
              style={{
                flex: 1
              }}>
              {!lesson._id ? 'Create Lesson' : 'Edit Lesson'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Lesson Details</h2>
                <FormControl className="w-100 mb-3">
                  <TextField
                    required
                    label="Lesson Name"
                    name={'name'}
                    onChange={this.handleChange}
                    defaultValue={name}
                    margin="none"
                  />
                </FormControl>
                <FormControl className="w-100 mb-3">
                  <TextField
                    label="Lesson Description"
                    name={'desc'}
                    onChange={this.handleChange}
                    defaultValue={desc}
                    multiline
                    margin="none"
                  />
                </FormControl>
                <div className="row mb-3">
                  <div className="col">
                    <TextField
                      label={'Lesson Start Time & Date'}
                      type={'datetime-local'}
                      name={'scheduled_on'}
                      fullWidth
                      value={scheduled_on}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      label={'Lesson End Time'}
                      type={'time'}
                      name={'ended_on'}
                      fullWidth
                      value={ended_on}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <TextField
                      label="Lesson Order"
                      name="index"
                      onChange={this.handleChange}
                      defaultValue={index}
                      margin="none"
                      fullWidth
                    />
                  </div>
                  <div className="col">
                    <TextField
                      label="Lesson Term"
                      name="term"
                      onChange={this.handleChange}
                      defaultValue={term}
                      margin="none"
                      fullWidth
                    />
                  </div>
                </div>
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Tutor currently assigned</h2>
                {tutor.length > 0 &&
                  tutor.map((t, index) => {
                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTutorSelectModal(index)}>
                            Change teacher
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTutor(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTutorSelectModal()}>
                  Add Teacher
                </Button>

                {/*Select Tutor Dialog*/}
                <Dialog onClose={this.toggleTutorSelectModal()} open={tutorSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a teacher</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="Tutor" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            name={'searchTerm'}
                            value={searchTerm}
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!tutors && tutors.length > 0 ? (
                      <List disablePadding={true}>
                        {tutors.map(user => (
                          <ListItem button onClick={this.selectTutor(user)} key={user._id}>
                            <ListItemText primary={user.name} secondary={user.email} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <div className="app-wrapper">
                        <h4 className={'font-weight-semibold'}>No tutors found</h4>
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>

              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">TA assigned</h2>
                {ta.length > 0 &&
                  ta.map((t, index) => {
                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTASelectModal(index)}>
                            Change TA
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTA(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTASelectModal()}>
                  Add TA
                </Button>

                {/*Select TA Dialog*/}
                <Dialog onClose={this.toggleTASelectModal()} open={taSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a TA</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="TA" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            name={'searchTerm'}
                            value={searchTerm}
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!TAs && TAs.length > 0 ? (
                      <List disablePadding={true}>
                        {TAs.map(user => (
                          <ListItem button onClick={this.selectTA(user)} key={user._id}>
                            <ListItemText primary={user.name} secondary={user.email} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <div className="app-wrapper">
                        <h4 className={'font-weight-semibold'}>No ta found</h4>
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>
            </div>
            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Lesson Hosting</h2>
                <FormControl className="w-100 mb-3">
                  <RadioGroup
                    className="d-flex flex-row"
                    aria-label="hosting_platform"
                    name="hosting_platform"
                    value={hosting_platform}
                    onChange={this.handleChange}>
                    <FormControlLabel
                      value="Tenopy Classroom"
                      control={<Radio color="primary" />}
                      label="Tenopy Classroom"
                    />
                    <FormControlLabel value="Zoom" control={<Radio color="primary" />} label="Zoom" />
                    <FormControlLabel value="ClassIn" control={<Radio color="primary" />} label="ClassIn" />
                  </RadioGroup>
                </FormControl>
                {hosting_platform === 'Zoom' && (
                  <FormControl className="w-100 mb-3">
                    <RadioGroup
                      className="d-flex flex-row"
                      aria-label="hosting_type"
                      name="hosting_type"
                      value={hosting_type}
                      onChange={this.handleChange}>
                      <FormControlLabel value="meeting" control={<Radio color="primary" />} label="Meeting" />
                      <FormControlLabel value="webinar" control={<Radio color="primary" />} label="Webinar" />
                      <FormControlLabel value="1-to-1" control={<Radio color="primary" />} label="1 to 1" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Lesson Status</h2>
                <FormControl className={''}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'is_active'}
                        checked={is_active}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'is_released'}
                        checked={is_released}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Released"
                  />
                </FormControl>
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={!name} variant="contained" color="primary" onClick={this.saveLesson}>
            Save Lesson
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogAddLesson.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  lesson: PropTypes.object,
  targetClass: PropTypes.object.isRequired,
  onSave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddLesson)
