import React from 'react'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'
import agent from 'agent'
import _ from 'lodash'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { ArrowBackIos as BackIcon } from '@material-ui/icons'

const mapStateToProps = state => ({
  discountCode: state.discountCode.discountCode,
  isLoading: state.discountCode.isLoading,
  codeCreated: state.discountCode.codeCreated,
  codeError: state.discountCode.error,
  classList: state.classroom.classList,
  classCount: state.classroom.classCount,
  isLoadingClasses: state.classroom.isLoadingClasses,
  loadingAgentList: state.user.isLoading,
  agentList: state.user.agentList
})

const mapDispatchToProps = dispatch => ({
  createDiscountCode: (code_type, reqBody) =>
    dispatch({
      type: Type.CREATE_DISCOUNT_CODE,
      payload: agent.DiscountCode.createNew(code_type, reqBody)
    }),
  getClasses: filter => dispatch({ type: Type.GET_CLASSES, payload: agent.Classroom.getClasses(filter, 0) }),
  getUsers: (filter, userType) =>
    dispatch({ type: Type.GET_AGENT_LIST, payload: agent.User.getUsers(filter, 30), userType })
})

class DiscountCodeForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = e => {
      let value = e.target.value
      let name = e.target.name

      if (name === 'code') {
        value = value.toUpperCase()
      }

      this.setState({ [name]: value })
    }

    this.changeSelectionType = type => () => {
      this.setState({ selectClassDialogOpen: true, classSelectionType: type })
    }

    this.closeClassSelectionDialog = () => {
      this.setState({ selectClassDialogOpen: false })
    }

    this.addClassToList = classObj => () => {
      let toModify = _.cloneDeep(this.state.classSelection)
      toModify.push(classObj)
      let uniqToModify = _.uniqBy(toModify, '_id')

      this.setState({ classSelection: uniqToModify })
    }

    this.removeClassFromList = classObj => () => {
      let toModify = _.cloneDeep(this.state.classSelection)
      _.remove(toModify, c => {
        return c._id === classObj._id
      })

      this.setState({ classSelection: toModify })
    }

    this.toggleConfirmationDialog = e => {
      e.preventDefault()
      this.setState({ confirmCreateDialogOpen: !this.state.confirmCreateDialogOpen })
    }

    this.toggleSuccessDialog = () => {
      this.setState({ successDialogOpen: !this.state.successDialogOpen })
    }

    this.initialState = {
      code: '',
      desc: '',
      discount_type: 'Percent',
      discount: 0,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(1, 'month').format('YYYY-MM-DD'),
      usage_limit: 0,
      agent: '',
      block_trial: false,
      selectClassDialogOpen: false,
      classSelectionType: '',
      classSelection: [],
      searchTerm: '',
      confirmCreateDialogOpen: false,
      successDialogOpen: false
    }

    this.state = {
      ...this.initialState
    }
  }

  componentDidMount() {
    this.props.getUsers({ _type: 'Agent' }, 'Agent')
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.classList, this.props.classList)) {
      const { classSelectionType } = this.state

      if (classSelectionType !== 'select' && classSelectionType !== '') {
        this.setState({ classSelection: this.props.classList })
      }
    }

    if (!prevProps.codeCreated && this.props.codeCreated) {
      this.setState({ ...this.initialState, successDialogOpen: true })
    }

    if (!prevProps.codeError && this.props.codeError) {
      this.setState({ confirmCreateDialogOpen: false })
    }
  }

  getClasses = selection => e => {
    e.preventDefault()
    const { classSelectionType, searchTerm } = this.state

    let filter = { is_active: true, end_date: { $gt: moment().toISOString() } }

    switch (classSelectionType) {
      case 'select':
        filter.name = { $regex: `${searchTerm}`, $options: 'i' }
        break
      case 'subject':
        if (selection === 'Mathematics') {
          filter.subject = { $in: ['Mathematics', 'A Maths', 'E Maths'] }
        } else {
          filter.subject = selection
        }
        break
      case 'level':
        if (selection === 'lower-primary') {
          filter.level = { $in: ['primary-1', 'primary-2', 'primary-3'] }
        } else if (selection === 'upper-primary') {
          filter.level = { $in: ['primary-4', 'primary-5', 'primary-6'] }
        } else if (selection === 'lower-secondary') {
          filter.level = { $in: ['secondary-1', 'secondary-2'] }
        } else if (selection === 'upper-secondary') {
          filter.level = { $in: ['secondary-3', 'secondary-4', 'secondary-5'] }
        } else if (selection === 'jc') {
          filter.level = { $in: ['junior-college-1', 'junior-college-2'] }
        } else if (selection === 'ip') {
          filter.level = { $in: ['integrated-programme-4', 'integrated-programme-5'] }
        } else if (selection === 'ibdp') {
          filter.level = {
            $in: ['international-baccalaureate-diploma-programme-1', 'international-baccalaureate-diploma-programme-2']
          }
        } else {
          filter.level = { $in: [selection] }
        }
        break
      // no default
    }

    this.props.getClasses(filter)
  }

  isFormComplete() {
    const { code, discount, start_date, end_date, usage_limit } = this.state

    if (!code || !discount || !start_date || !end_date || usage_limit === '') {
      return false
    }
    if (
      moment(start_date, 'YYYY-MM-DD') < moment().startOf('day') ||
      moment(end_date, 'YYYY-MM-DD') < moment(start_date, 'YYYY-MM-DD')
    ) {
      return false
    }

    return true
  }

  createDiscountCode = () => {
    const {
      code,
      desc,
      discount_type,
      discount,
      start_date,
      end_date,
      usage_limit,
      agent,
      block_trial,
      classSelection
    } = this.state

    let merchandise_id = classSelection.map(c => c._id)

    let request = {
      code,
      desc,
      discount_type,
      discount,
      start_date,
      end_date,
      usage_limit,
      agent,
      block_trial,
      merchandise_id
    }

    this.props.createDiscountCode('general', request)
  }

  render() {
    const {
      code,
      desc,
      discount_type,
      discount,
      start_date,
      end_date,
      usage_limit,
      agent,
      selectClassDialogOpen,
      classSelectionType,
      classSelection,
      searchTerm,
      confirmCreateDialogOpen,
      successDialogOpen
    } = this.state
    const { isLoading, isLoadingClasses, classList, codeError, loadingAgentList, agentList } = this.props

    let discountTypes = [
      {
        value: 'Percent',
        text: 'Percentage Discount'
      },
      {
        value: 'Absolute',
        text: 'Fixed amount discount'
      } /*,
      {
        value: 'Each Element Dollar Off',
        text: 'Fixed dollar discount per lesson'
      },
      {
        value: 'Number of Element Free',
        text: 'Number of lessons free'
      }//*/
    ]

    let prefix = null
    let suffix = null

    switch (discount_type) {
      case 'Percent':
        suffix = <InputAdornment position="end">%</InputAdornment>
        break
      case 'Absolute':
        prefix = <InputAdornment position="start">$</InputAdornment>
        break
      case 'Each Element Dollar Off':
        prefix = <InputAdornment position="start">$</InputAdornment>
        suffix = <InputAdornment position="end">per lesson</InputAdornment>
        break
      case 'Number of Element Free':
        suffix = <InputAdornment position="end">lesson(s) free</InputAdornment>
        break
      default:
        prefix = null
        suffix = null
    }

    return (
      <main role="main" className="app-wrapper">
        <div className="d-flex align-items-center mb-3">
          <BackIcon
            className="pointer mr-2"
            onClick={() => {
              this.props.history.goBack()
            }}
          />
          <h1 className="mb-0">New Discount Codes</h1>
        </div>

        {this.props.isLoading ? (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        ) : (
          <form onSubmit={this.toggleConfirmationDialog}>
            <div className="row">
              <div className="col">
                <Paper elevation={2} className={'app-wrapper'}>
                  <div className="form-group">
                    <TextField
                      type="text"
                      label={'Discount Code'}
                      name={'code'}
                      value={code}
                      onChange={this.handleChange}
                      fullWidth
                      error={codeError}
                      FormHelperTextProps={{ error: codeError }}
                      helperText={codeError ? 'This code already exists, try a different one' : ''}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="text"
                      label={'Description'}
                      name={'desc'}
                      value={desc}
                      onChange={this.handleChange}
                      multiline
                      fullWidth
                    />
                  </div>
                </Paper>

                <Paper elevation={2} className={'app-wrapper mt-3'}>
                  <div className="form-group">
                    <FormControl className="w-100 mb-2">
                      <InputLabel htmlFor="discount_type">Type of discount</InputLabel>
                      <Select
                        name={'discount_type'}
                        value={discount_type}
                        onChange={this.handleChange}
                        input={<Input id="discount_type" />}>
                        {discountTypes.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <FormControl>
                          <InputLabel>Discount Value</InputLabel>
                          <Input
                            type={'number'}
                            inputProps={{ min: 0 }}
                            name={'discount'}
                            value={discount}
                            onChange={this.handleChange}
                            startAdornment={prefix}
                            endAdornment={suffix}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="col">
                <Paper elevation={2} className={'app-wrapper mb-3'}>
                  <div className="form-group">
                    <TextField
                      type={'date'}
                      label={'Start Date'}
                      name={'start_date'}
                      value={start_date}
                      onChange={this.handleChange}
                      fullWidth
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type={'date'}
                      label={'End Date'}
                      name={'end_date'}
                      value={end_date}
                      onChange={this.handleChange}
                      fullWidth
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="number"
                      label={'Usage Limit'}
                      name={'usage_limit'}
                      value={usage_limit}
                      onChange={this.handleChange}
                      helperText={'*Leave at 0 for unlimited use'}
                      inputProps={{ min: 0 }}
                      fullWidth
                    />
                  </div>

                  <div className="form-group">
                    {loadingAgentList ? (
                      <CircularProgress size={25} color="primary" />
                    ) : (
                      <TextField
                        select
                        label="Agent"
                        name="agent"
                        value={agent}
                        onChange={this.handleChange}
                        style={{ minWidth: '200px' }}>
                        {agentList.map(agentData => {
                          if (!agentData.enabled) {
                            return null
                          }

                          return (
                            <MenuItem key={agentData._id} value={agentData._id}>
                              {agentData.name}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    )}
                  </div>
                </Paper>
                <Paper elevation={2} className={'app-wrapper mb-3'}>
                  <h3 className={'font-weight-semibold text-center mb-1'}>Limit discount code usage</h3>
                  <h3 className="font-muted text-center">
                    <small>{classSelection.length} classes selected</small>
                  </h3>
                  <div className="text-center">
                    <div className={'mb-3'}>
                      <Button variant={'contained'} color={'secondary'} onClick={this.changeSelectionType('select')}>
                        Specific classes
                      </Button>
                    </div>
                    <div className={'mb-3'}>
                      <Button variant={'contained'} color={'secondary'} onClick={this.changeSelectionType('subject')}>
                        All classes of subject
                      </Button>
                    </div>
                    <div>
                      <Button variant={'contained'} color={'secondary'} onClick={this.changeSelectionType('level')}>
                        All classes of level
                      </Button>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
            <div className="form-group text-right">
              <Button disabled={!this.isFormComplete()} color={'primary'} type={'submit'} variant={'contained'}>
                Create Discount Code
              </Button>
            </div>
          </form>
        )}
        {selectClassDialogOpen && (
          <Dialog
            open={selectClassDialogOpen}
            onClose={this.closeClassSelectionDialog}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={'lg'}
            fullWidth>
            <DialogTitle>Limit discount code to which classes?</DialogTitle>
            <DialogContent>
              {classSelectionType === 'select' && (
                <div>
                  <DialogContentText>Select individual classes</DialogContentText>
                  <form onSubmit={this.getClasses('')}>
                    <div className="row">
                      <div className="col">
                        <TextField
                          name={'searchTerm'}
                          value={searchTerm}
                          onChange={this.handleChange}
                          label={'Class Name'}
                          fullWidth
                        />
                      </div>
                      <div className="col-auto">
                        <Button variant={'text'} color={'secondary'} type={'submit'}>
                          Search
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {classSelectionType === 'subject' && (
                <div>
                  <DialogContentText>Select a subject</DialogContentText>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('English')}>
                    English
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('Mathematics')}>
                    Mathematics
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('Physics')}>
                    Physics
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('Chemistry')}>
                    Chemistry
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('Biology')}>
                    Biology
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('Chinese')}>
                    Chinese
                  </Button>
                </div>
              )}
              {classSelectionType === 'level' && (
                <div>
                  <DialogContentText>Select a level</DialogContentText>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('lower-primary')}>
                    Lower Pri
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('upper-primary')}>
                    Upper Pri
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('lower-secondary')}>
                    Lower Sec
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('upper-secondary')}>
                    Upper Sec
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('jc')}>
                    Junior College
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('ip')}>
                    IP
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2 mb-2'}
                    onClick={this.getClasses('ibdp')}>
                    IBDP
                  </Button>
                </div>
              )}
              <div className="row mt-3">
                {classSelectionType === 'select' &&
                  (!!classList && classList.length > 0 ? (
                    <div className="col">
                      <h3 className="font-weight-semibold">Classes</h3>
                      <List>
                        {classList.map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText>{item.name}</ListItemText>
                              <ListItemSecondaryAction>
                                <Button variant={'text'} color={'secondary'} onClick={this.addClassToList(item)}>
                                  Add to list
                                </Button>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        })}
                      </List>
                    </div>
                  ) : (
                    <div className="col">
                      <h4 className="font-weight-semibold my-3">No classes found</h4>
                    </div>
                  ))}
                {classSelection.length > 0 ? (
                  <div className="col">
                    <h4 className="font-weight-semibold">Selected Classes</h4>
                    <List>
                      {classSelection.map(item => {
                        return (
                          <ListItem key={item._id} className={'w-100'}>
                            <ListItemText>{item.name}</ListItemText>
                            <ListItemSecondaryAction>
                              <Button
                                variant={'text'}
                                className={'text-danger'}
                                onClick={this.removeClassFromList(item)}>
                                Remove
                              </Button>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </List>
                  </div>
                ) : (
                  <div className="col">
                    <h4 className="font-weight-semibold">No classes found</h4>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoadingClasses} onClick={this.closeClassSelectionDialog} color="primary">
                Done {isLoadingClasses && <CircularProgress color={'primary'} size={20} />}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {confirmCreateDialogOpen && (
          <Dialog
            open={confirmCreateDialogOpen}
            onClose={this.toggleConfirmationDialog}
            disableEscapeKeyDown={isLoading}
            disableBackdropClick={isLoading}
            fullWidth>
            <DialogTitle>Confirm creation of discount code?</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col">
                  <Paper elevation={2} className={'app-wrapper mb-3'}>
                    <div className="mb-3">
                      <h6 className={'mb-1'}>Discount Code</h6>
                      <div className="font-weight-semibold">{code}</div>
                    </div>
                    <div>
                      <h6 className={'mb-1'}>Description</h6>
                      <div className="font-weight-semibold">{!!desc ? desc : '-'}</div>
                    </div>
                  </Paper>
                  <Paper elevation={2} className={'app-wrapper mb-3'}>
                    <div className="mb-3">
                      <h6 className={'mb-1'}>Type of discount</h6>
                      <div className="font-weight-semibold">{discount_type}</div>
                    </div>
                    <div>
                      <h6 className={'mb-1'}>Discount Value</h6>
                      <div className="font-weight-semibold">
                        <FormControl>
                          <Input disabled value={discount} startAdornment={prefix} endAdornment={suffix} />
                        </FormControl>
                      </div>
                    </div>
                  </Paper>
                </div>
                <div className="col">
                  <Paper elevation={2} className={'app-wrapper mb-3'}>
                    <div className="mb-3">
                      <h6 className={'mb-1'}>Start Date</h6>
                      <div className="font-weight-semibold">
                        {moment(start_date, 'YYYY-MM-DD').format('dddd, Do MMM YYYY')}
                      </div>
                    </div>
                    <div className={'mb-3'}>
                      <h6 className={'mb-1'}>End Date</h6>
                      <div className="font-weight-semibold">
                        {moment(end_date, 'YYYY-MM-DD').format('dddd, Do MMM YYYY')}
                      </div>
                    </div>
                    <div>
                      <h6 className={'mb-1'}>Usage Limit</h6>
                      <div className="font-weight-semibold">
                        {usage_limit === 0 ? 'Unlimited' : `${usage_limit} times`}
                      </div>
                    </div>
                  </Paper>
                  {classSelection.length > 0 && (
                    <Paper elevation={2}>
                      <h6 className="app-wrapper m-0 pb-0">Applicable Classes</h6>
                      <List>
                        {classSelection.map(item => {
                          return (
                            <ListItem key={`confirm_${item._id}`}>
                              <ListItemText>{item.name}</ListItemText>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Paper>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoading} onClick={this.toggleConfirmationDialog} color="default">
                Cancel
              </Button>
              <Button disabled={isLoading} onClick={this.createDiscountCode} color="primary">
                Done {isLoading && <CircularProgress color={'primary'} size={20} />}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {successDialogOpen && (
          <Dialog open={successDialogOpen} onClose={this.toggleSuccessDialog} fullWidth>
            <DialogTitle>Your discount code has been created</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You may view your code in the <Link to={'/discountcode'}>discount code list</Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleSuccessDialog} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </main>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountCodeForm))
