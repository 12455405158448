import React from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, CircularProgress, FormLabel, Paper, Radio, TextField, Tab, Tabs } from '@material-ui/core'

import agent from 'agent'
import { GET_USERS, GET_USER_CREDIT, ADD_USER_CREDIT } from 'constants/actionTypes'
import permissions, { checkActionPermitted } from 'permissions'
import { ROUTE_USER_MANAGEMENT } from 'routes/paths'
import Pagination from 'components/Pagination/Pagination'
import DialogUserEdit from 'pages/UserManagement/DialogUserEdit'
import DialogUserDetail from 'pages/UserManagement/DialogUserDetail'

const mapStateToProps = state => ({
  userList: state.user.userList,
  isLoading: state.user.isLoading,
  userPage: state.user.userPage,
  userCount: state.user.userCount,
  loadingUserCredit: state.user.loadingCredit,
  addingCredit: state.user.addingCredit,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, perPage, page) =>
    dispatch({ type: GET_USERS, payload: agent.User.getUsers(filter, perPage, page) }),
  getUserCredit: (filter, userId) => dispatch({ type: GET_USER_CREDIT, payload: agent.User.getCredit(filter), userId }),
  addUserCredit: reqPayload => dispatch({ type: ADD_USER_CREDIT, payload: agent.User.addCredit(reqPayload) })
})

class UserManagement extends React.Component {
  constructor(props) {
    super(props)

    this.onPageChange = page => {
      this.setState({ currentPage: page })
    }

    this.changeTab = (e, value) => {
      this.setState({ tab: value })
    }

    this.toggleUserDialog = user => () => {
      this.setState({ userDialogOpen: !this.state.userDialogOpen, selectedUser: user })
    }

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.setSearch = () => {
      const { searchText } = this.state

      if (!!searchText.trim()) {
        this.setState({ searchTerm: searchText })
      }
    }

    this.clearSearch = () => {
      this.setState({ searchTerm: '', searchText: '' })
    }

    this.selectTarget = (e, checked) => {
      this.setState({ searchTarget: e.target.value })
    }

    this.state = {
      tab: 'all',
      currentPage: 1,
      perPage: 20,
      userDialogOpen: false,
      userDetailDialogOpen: false,
      selectedUser: null,
      searchText: '',
      searchTerm: '',
      searchTarget: 'email'
    }
  }

  componentDidMount() {
    this.searchUsers()
    this.canManageUser = checkActionPermitted(permissions, this.props.userRole, ROUTE_USER_MANAGEMENT, 'canManage')
    this.canManageCredit = checkActionPermitted(
      permissions,
      this.props.userRole,
      ROUTE_USER_MANAGEMENT,
      'canManageCredit'
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.searchUsers()
    }

    if (prevState.tab !== this.state.tab) {
      this.searchUsers()
    }

    if (prevState.searchTerm !== this.state.searchTerm) {
      this.searchUsers()
    }
  }

  searchUsers = () => {
    const { tab, currentPage, perPage, searchTerm, searchTarget } = this.state
    let userTypeFilter = []

    if (tab !== 'all') {
      userTypeFilter.push(tab)
    } else {
      userTypeFilter = ['Tutor', 'Student']
    }

    let filter = { _type: userTypeFilter.join() }

    const restricton = checkActionPermitted(permissions, this.props.userRole, ROUTE_USER_MANAGEMENT, 'showOnly')
    if (restricton === 'Oxkids') {
      filter['lead_source.source'] = restricton
    }

    if (!!searchTerm) {
      let formattedSearchTerm = searchTerm.replaceAll('+', '\\+')
      filter[searchTarget] = {
        $regex: formattedSearchTerm,
        $options: 'i'
      }
    }

    this.props.getUsers(filter, perPage, currentPage)
  }

  onSave = () => {
    this.toggleUserDialog(null)()
    this.searchUsers()
  }

  getReferralLink = referralCode => () => {
    return `https://sg.tenopy.com/signup?referral=${referralCode}`
  }

  toggleUserDetailDialog = selectedUser => () => {
    this.setState({
      selectedUser,
      userDetailDialogOpen: !this.state.userDetailDialogOpen
    })
  }

  getUserCredit = () => {
    const { selectedUser } = this.state
    if (selectedUser) {
      this.props.getUserCredit({ created_by: selectedUser._id }, selectedUser._id)
    }
  }

  addUserCredit = (qty, reason) => () => {
    const { selectedUser } = this.state
    if (selectedUser && qty > 0 && reason) {
      this.props.addUserCredit({
        qty,
        remark: reason.trim(),
        target_id: selectedUser._id
      })
    }
  }

  render() {
    const { userList, userCount, addingCredit } = this.props
    const {
      tab,
      currentPage,
      perPage,
      userDialogOpen,
      selectedUser,
      searchText,
      searchTerm,
      searchTarget,
      userDetailDialogOpen
    } = this.state

    return (
      <div className="app-wrapper">
        <h1>User Management</h1>
        <div className="row align-items-center my-3">
          <div className="col">
            <Tabs value={tab} onChange={this.changeTab}>
              <Tab className="tab" label="All" value={'all'} />
              <Tab className="tab" label="Student" value={'Student'} />
              <Tab className="tab" label="Tutor" value={'Tutor'} />
            </Tabs>
          </div>
          {this.canManageUser ? (
            <div className="col text-right">
              <Button color={'primary'} variant={'contained'} onClick={this.toggleUserDialog()}>
                Create New Tutor
              </Button>
              {userDialogOpen && (
                <DialogUserEdit
                  toggle={this.toggleUserDialog()}
                  isOpen={userDialogOpen}
                  userData={selectedUser}
                  onSave={this.onSave}
                />
              )}
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col">
            {!!searchTerm && (
              <div>
                Showing results for '<strong>{searchTerm}</strong>'
                <Button variant={'text'} color={'default'} onClick={this.clearSearch} className={'ml-3'}>
                  Clear
                </Button>
              </div>
            )}
          </div>
          <div className="col-auto">
            <TextField label={searchTarget} name={'searchText'} value={searchText} onChange={this.handleChange} />
            <Button variant={'contained'} color={'primary'} className={'ml-2'} onClick={this.setSearch}>
              Search
            </Button>
            <FormLabel>
              <Radio
                value={'email'}
                checked={searchTarget === 'email'}
                onChange={this.selectTarget}
                name={'searchTarget'}
              />
              Email
            </FormLabel>
            <FormLabel>
              <Radio
                value={'phone'}
                checked={searchTarget === 'phone'}
                onChange={this.selectTarget}
                name={'searchTarget'}
              />
              Phone
            </FormLabel>
            <FormLabel>
              <Radio
                value={'name'}
                checked={searchTarget === 'name'}
                onChange={this.selectTarget}
                name={'searchTarget'}
              />
              Name
            </FormLabel>
          </div>
        </div>

        {this.props.isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {userList ? (
          <Paper elevation={2}>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Type</th>
                  <th scope="col">Email Verified</th>
                  {this.canManageUser && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {userList.map(p => (
                  <tr key={p._id}>
                    <td className="clickable" onClick={this.toggleUserDetailDialog(p)}>
                      {p.email}
                    </td>
                    <td>{p.name}</td>
                    <td>
                      (+{p.phone_country_code}) {p.phone}
                    </td>
                    <td>{p._type}</td>
                    <td>
                      {p.e_verified ? (
                        <span className="text-success">Verified</span>
                      ) : (
                        <span className="text-danger">Not Verified</span>
                      )}
                    </td>
                    {this.canManageUser && (
                      <td>
                        <CopyToClipboard text={`https://sg.tenopy.com/signup?referral=${p.referral_code}`}>
                          <Button color="primary" variant="outlined" className="mr-2">
                            Copy Referral Link
                          </Button>
                        </CopyToClipboard>
                        <Button variant={'contained'} color={'primary'} onClick={this.toggleUserDialog(p)}>
                          Edit
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        ) : (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        <Pagination
          itemCount={!!userCount ? userCount : 0}
          onChangePage={this.onPageChange}
          pageSize={perPage}
          currentPage={currentPage}
          className={'mt-3'}
        />
        <DialogUserDetail
          toggleDialog={this.toggleUserDetailDialog}
          isOpen={userDetailDialogOpen}
          userDetail={selectedUser}
          getCredit={this.getUserCredit}
          addCredit={this.addUserCredit}
          addingCredit={addingCredit}
          canManageCredit={this.canManageCredit}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
