import React from 'react'
import moment from 'moment'

const AccordionPollDetails = props => {
  const { homeworkData, submissionData } = props

  let lessonHomework = 'Not assigned',
    lessonSubmission = ''
  if (homeworkData) {
    let dueDate = moment(homeworkData.due_on).format('lll')
    let closeDate = moment(homeworkData.close_on).format('lll')
    lessonHomework = `Homework: Assigned (due on ${dueDate}, close on ${closeDate})`

    if (submissionData) {
      lessonSubmission = `Submission status: ${submissionData.status.replace('_', ' ')}`
    } else {
      lessonSubmission = 'Submission status: PENDING SUBMISSION'
    }
  }

  return (
    <div>
      {lessonHomework}
      {lessonSubmission && (
        <span>
          <br />
          {lessonSubmission}
        </span>
      )}
    </div>
  )
}

export default AccordionPollDetails
