import React from 'react'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'
import Drawer from '@material-ui/core/Drawer'
import SideNavContent from 'components/shared/_SideNavContent'
import UserInfo from 'components/shared/_UserInfo'
import $ from 'jquery'

const mapStateToProps = state => ({
  sideNavOpened: state.common.sideNavOpened,
  windowWidth: state.common.windowWidth
})

const mapDispatchToProps = dispatch => ({
  toggleCollapsedSideNav: opened => dispatch({ type: Type.TOGGLE_COLLAPSE_SIDE_NAV, opened }),
  updateWindowWidth: width => dispatch({ type: Type.UPDATE_WINDOW_WIDTH, width })
})

class SideNav extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth($(window).width())
    })
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.sideNavOpened
    this.props.toggleCollapsedSideNav(val)
  }

  render() {
    let type = 'permanent'
    if (this.props.windowWidth < 1200) {
      type = 'temporary'
    }

    return (
      <div className="app-sidebar d-none d-xl-flex">
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? this.props.sideNavOpened : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav'
          }}>
          <UserInfo />
          <SideNavContent />
        </Drawer>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
