import React from 'react'
import { connect } from 'react-redux'

import { Avatar, Button, Card, Chip, CircularProgress, Divider, TextField } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_EVENT_LIST } from 'constants/actionTypes'
import StudentListDialog from './dialog-student-list'

const mapStateToProps = state => ({
  loadingEventList: state.event.loadingEventList,
  eventList: state.event.eventList
})

const mapDispatchToProps = dispatch => ({
  getEventList: (filter, perPage, page) =>
    dispatch({
      type: GET_EVENT_LIST,
      payload: agent.Event.getList(filter, perPage, page)
    })
})

const AttendanceStat = props => {
  let { attendance, registered, viewDetail } = props
  let attended = [],
    absent = []

  registered.forEach(student => {
    let attendanceData = _.find(attendance, o => {
      return o.user_email === student.email
    })
    if (attendanceData) {
      attended.push(student)
    } else {
      absent.push(student)
    }
  })

  return (
    <div className="row">
      <div className="col-12">
        <h4>Total Registered: {registered.length}</h4>
        <div className="d-flex manage-margin px-2">
          <Chip
            variant="default"
            label="Attended"
            className={`${attended.length > 0 ? 'bg-success' : 'bg-danger blink'} text-white clickable`}
            avatar={<Avatar className="bg-white">{attended.length}</Avatar>}
            onClick={viewDetail(attended)}
          />

          {absent.length > 0 && (
            <Chip
              variant="default"
              label="Absent"
              className="bg-danger text-white clickable"
              avatar={<Avatar className="bg-white">{absent.length}</Avatar>}
              onClick={viewDetail(absent)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

class TLSAttendanceTab extends React.Component {
  state = {
    fromDate: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    listData: [],
    isStudentListDialogOpen: false,
    pageCount: 0,
    currentPage: 1,
    perPage: 15,
    refreshPagination: false
  }

  componentDidMount() {
    if (this.props.activeTab === 'tls-attendance') {
      this.search()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'tls-attendance') {
      if (!this.props.loadingEventList) {
        this.search()
      }
    }

    if (prevProps.eventList && !this.props.eventList && this.state.refreshPagination) {
      let { perPage } = this.state
      let { eventList } = this.props
      let pageCount = Math.ceil(eventList.count / perPage)

      this.setState({
        refreshPagination: false,
        pageCount,
        currentPage: 1
      })
    }
  }

  search = () => {
    let { fromDate, toDate, perPage } = this.state
    let filter = {
      scheduled_on: {
        $gte: moment(fromDate).startOf('d').toISOString(),
        $lte: moment(toDate).endOf('d').toISOString()
      }
    }

    this.setState({ refreshPagination: true })
    this.props.getEventList(filter, perPage, 1)
  }

  changePage = (event, page) => {
    let { fromDate, toDate, perPage } = this.state

    let filter = {
      scheduled_on: {
        $gte: moment(fromDate).startOf('d').toISOString(),
        $lte: moment(toDate).endOf('d').toISOString()
      }
    }

    this.setState({ currentPage: page })
    this.props.getEventList(filter, perPage, page)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  toggleStudentListDialog = listData => () => {
    this.setState({
      listData,
      isStudentListDialogOpen: !this.state.isStudentListDialogOpen
    })
  }

  render() {
    let { activeTab, loadingEventList, eventList } = this.props
    let { fromDate, toDate, listData, isStudentListDialogOpen, pageCount, currentPage, perPage } = this.state

    return (
      <div className="app-wrapper" hidden={activeTab !== 'tls-attendance'}>
        <div className="d-flex align-items-center manage-margin mb-3">
          <TextField
            variant="outlined"
            type="date"
            label="From"
            name="fromDate"
            value={fromDate}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            type="date"
            label="To"
            name="toDate"
            value={toDate}
            onChange={this.handleChange}
          />

          <Button variant="contained" color="primary" onClick={this.search}>
            Search
          </Button>
        </div>

        {loadingEventList && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        <div className="text-right text-muted">
          {pageCount > 1 && eventList.count > 0 ? (
            <>
              Showing {perPage * (currentPage - 1) + 1} - {perPage * (currentPage - 1) + perPage} out of{' '}
              {eventList.count} records
            </>
          ) : (
            <>Showing all {eventList.count} records</>
          )}
        </div>

        {eventList.data.map(event => {
          return (
            <Card className="mb-3" key={event._id}>
              <div className="p-3">
                <h3 className="mb-0">{event.name}</h3>
              </div>
              <Divider />
              <div className="row p-3">
                <div className="col-5">
                  <span className="text-muted">Level: </span>
                  <span>{event.meta.level}</span>
                  <br />
                  <span className="text-muted">Subject: </span>
                  <span>{event.meta.subject}</span>
                  <br />
                  <span className="text-muted">Keywords: </span>
                  <span>{event.meta.keywords.join(', ')}</span>
                  <br />
                  <span className="text-muted">Time: </span>
                  <span>{moment(event.scheduled_on).format('lll')}</span>
                  <br />
                  <span className="text-muted">Tutor: </span>
                  <span>{event.host_by.name}</span>
                </div>
                <div className="col-7">
                  <div className="row">
                    <AttendanceStat
                      attendance={event.attendance}
                      registered={event.attendee}
                      viewDetail={this.toggleStudentListDialog}
                    />
                  </div>
                </div>
              </div>
            </Card>
          )
        })}

        {pageCount > 1 && (
          <Pagination color="primary" count={pageCount} page={currentPage} onChange={this.changePage} />
        )}

        <StudentListDialog
          isOpen={isStudentListDialogOpen}
          toggle={this.toggleStudentListDialog}
          listData={listData}
          showUserPurchaseStatus={true}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TLSAttendanceTab)
