import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: Type.LOGOUT })
})

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { currentUser } = this.props

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar alt="..." src="http://via.placeholder.com/150x150" className="user-avatar " />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {currentUser ? currentUser.name : '---'}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        {/*<Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  width: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
            <MenuItem onClick={() => {
              this.handleRequestClose();
              this.props.logout() }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>Logout
            </MenuItem>
            </Menu>*/}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)
