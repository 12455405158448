import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Type from 'constants/actionTypes'
import agent from 'agent'
import moment from 'moment'
import Pagination from 'components/Pagination'
import { Button } from '@material-ui/core'
import { ROUTE_DISCOUNT_CODE, ROUTE_DISCOUNT_CODE_FORM } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  discountCodes: state.discountCode.discountCodes,
  isLoading: state.discountCode.isLoading,
  currentPage: state.discountCode.currentPage,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getDiscountCodes: code_type =>
    dispatch({ type: Type.GET_DISCOUNT_CODES, payload: agent.DiscountCode.getAllOfType(code_type, 0) }),
  disableDiscountCode: id =>
    dispatch({ type: Type.DISABLE_DISCOUNT_CODE, payload: agent.DiscountCode.disable(id), code_id: id }),
  enableDiscountCode: id =>
    dispatch({ type: Type.ENABLE_DISCOUNT_CODE, payload: agent.DiscountCode.enable(id), code_id: id })
})

const DiscountCodeRow = props => {
  if (props.code) {
    let discountCodeValid = false
    let now = new Date()
    let startDate = new Date(props.code.start_date)
    let endDate = new Date(props.code.end_date)
    let usageLimitExceed = false
    if (props.code.usage_limit !== 0 && props.code.total_usage >= props.code.usage_limit) {
      usageLimitExceed = true
    }

    if (props.code.is_active && startDate < now && endDate > now && !usageLimitExceed) {
      discountCodeValid = true
    }

    let discountType = props.code.discount_type
    let discount = 'EXPIRED'

    switch (discountType) {
      case 'Percent':
        discount = `${props.code.discount}%`
        break
      case 'Absolute':
        discount = `$${props.code.discount}`
        break
      case 'Each Element Dollar Off':
        discount = `$${props.code.discount} per lesson`
        break
      case 'Number of Element Free':
        discount = `${props.code.discount} lessons free`
        break
      // no default
    }

    return (
      <tr>
        <td>
          {discountCodeValid ? (
            <span className="badge badge-success">Active</span>
          ) : (
            <span className="badge badge-danger">Inactive</span>
          )}
        </td>
        <td>{props.code.code}</td>
        <td>{props.code.desc}</td>
        <td>{discount}</td>
        <td>{moment(props.code.start_date).format('ddd, DD MMM YYYY')}</td>
        <td>{moment(props.code.end_date).format('ddd, DD MMM YYYY')}</td>
        <td>
          {props.code.total_usage ? props.code.total_usage : '-'}/
          {props.code.usage_limit === 0 ? String.fromCharCode(8734) : props.code.usage_limit}
        </td>
        <td>{props.code.agent_email}</td>
        <td>
          {props.code.is_active ? (
            <button type="button" className="btn btn-danger ml-2" onClick={props.disableDiscountCode}>
              Disable
            </button>
          ) : (
            <button type="button" className="btn btn-success ml-2" onClick={props.enableDiscountCode}>
              Enable
            </button>
          )}
        </td>
      </tr>
    )
  }

  return null
}

class DiscountCode extends React.Component {
  constructor(props) {
    super(props)

    this.changeTab = (e, value) => {
      this.setState({ code_type: value })
    }

    this.disableDiscountCode = id => {
      return event => {
        event.preventDefault()
        this.props.disableDiscountCode(id)
      }
    }

    this.enableDiscountCode = id => {
      return event => {
        event.preventDefault()
        this.props.enableDiscountCode(id)
      }
    }

    this.state = {
      code_type: 'general'
    }
  }

  componentDidMount() {
    this.props.getDiscountCodes(this.state.code_type)
    this.canCreateDiscount = checkActionPermitted(permissions, this.props.userRole, ROUTE_DISCOUNT_CODE, 'canCreate')
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.code_type !== this.state.code_type) {
      this.props.getDiscountCodes(this.state.code_type)
    }
  }

  render() {
    let discountCodesCount = 0
    if (this.props.discountCodes) {
      discountCodesCount = this.props.discountCodes.count
    }

    return (
      <div className={'app-wrapper'}>
        <h1>Discount Codes</h1>
        <div className="row mb-3">
          {this.canCreateDiscount ? (
            <div className="col-12 text-right">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.props.history.push(ROUTE_DISCOUNT_CODE_FORM.replace(':discountId', 'new'))
                }}>
                Create New Discount Code
              </Button>
            </div>
          ) : null}
        </div>

        {this.props.isLoading && (
          <div className="row">
            <div className="col text-center">
              <i className="fa fa-circle-o-notch fa-spin fa-3x" />
            </div>
          </div>
        )}

        {this.props.discountCodes && (
          <div className="row">
            <div className="col">
              <table className="table table-responsive-md">
                <caption>List of discount/promo codes</caption>
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Discount Code</th>
                    <th scope="col">Description</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Code Usage</th>
                    <th scope="col">Agent Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.discountCodes.data.map(code => (
                    <DiscountCodeRow
                      key={code._id}
                      code={code}
                      disableDiscountCode={this.disableDiscountCode(code._id)}
                      enableDiscountCode={this.enableDiscountCode(code._id)}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {discountCodesCount > 0 && (
          <div className="row mb-4 justify-content-center">
            <div className="col-md-auto">
              <Pagination
                itemType={'DiscountCodes'}
                itemsCount={this.props.discountCodes.count}
                currentPage={this.props.currentPage}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountCode))
